<script setup lang="ts">
import * as numberInput from '@zag-js/number-input'
import { normalizeProps, useMachine } from '@zag-js/vue'
import { computed, watch, type InputHTMLAttributes } from 'vue'
import IconButton from './IconButton.vue'
import InlineTextField from './InlineTextField.vue'

const props = withDefaults(
  defineProps<{
    value: number
    min?: number
    max?: number
    /** Name attribute for the html input - must be unique to the page */
    name: string
    /**
     * Required for a11y. Either the ID of an element that contains the label, or the label itself.
     */
    label: { ariaLabelledBy: string } | { ariaLabel: string }
    size: 'xs' | 'sm'
    variant?: 'centered' | 'side'
    inputAttrs?: InputHTMLAttributes
    disabled?: boolean
  }>(),
  {
    min: 0,
    max: 100,
    variant: 'centered',
    inputAttrs: undefined,
    disabled: false,
  },
)

const emit = defineEmits<{
  (e: 'change', value: number): void
}>()

const [state, send] = useMachine(
  numberInput.machine({
    id: props.name,
    name: props.name,
    allowMouseWheel: true,
    min: props.min,
    max: props.max,
    value: String(props.value),
    onValueChange: ({ valueAsNumber }) => emit('change', valueAsNumber),
  }),
  { context: computed(() => ({ disabled: props.disabled })) },
)
const api = computed(() => numberInput.connect(state.value, send, normalizeProps))

watch(
  () => props.value,
  (newValue) => {
    api.value.setValue(newValue)
  },
  {
    immediate: true,
  },
)

const decrementProps = computed(() => api.value.getDecrementTriggerProps())
const incrementProps = computed(() => api.value.getIncrementTriggerProps())

const inputProps = computed(() => {
  return {
    ...api.value.getInputProps(),
    size: String(props.max).length.toString(),
    class: 'text-center',
    'aria-label': 'ariaLabel' in props.label ? props.label.ariaLabel : undefined,
    'aria-labelledby': 'ariaLabelledBy' in props.label ? props.label.ariaLabelledBy : undefined,
    ...props.inputAttrs,
  }
})
</script>

<template>
  <div
    class="inline-flex items-center"
    :class="[
      size === 'sm' ? 'gap-0.5 rounded-corner-8 p-0.5' : 'rounded-corner-6',
      variant === 'side' && 'bg-background-gray-subtlest',
    ]"
    v-bind="api.getRootProps()"
  >
    <template v-if="variant === 'centered'">
      <IconButton
        v-bind="decrementProps"
        icon="minus"
        size="md"
        variant="transparent"
        :disabled="!!decrementProps.disabled"
      />
      <slot
        name="input"
        :input-props="inputProps"
      >
        <InlineTextField
          :input-attrs="inputProps"
          size="xs"
        />
      </slot>
      <IconButton
        v-bind="incrementProps"
        icon="plus"
        size="md"
        variant="transparent"
        :disabled="!!incrementProps.disabled"
      />
    </template>
    <template v-else>
      <InlineTextField
        :input-attrs="inputProps"
        size="xs"
      />
      <div
        class="flex gap-0.5 bg-surface-primary p-0.5"
        :class="[size === 'xs' ? 'my-0.5 mr-0.5 rounded-corner-4' : 'rounded-corner-6']"
      >
        <IconButton
          v-bind="decrementProps"
          icon="minus"
          :size="size === 'xs' ? 'xs' : 'sm'"
          variant="transparent"
          :disabled="!!decrementProps.disabled"
        />
        <IconButton
          v-bind="incrementProps"
          icon="plus"
          :size="size === 'xs' ? 'xs' : 'sm'"
          variant="transparent"
          :disabled="!!incrementProps.disabled"
        />
      </div>
    </template>
  </div>
</template>
