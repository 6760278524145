<script setup lang="ts">
import DarwinButton from '@/uiKit/DarwinButton.vue'
import DividerLine from '@/uiKit/DividerLine.vue'
import IconButton from '@/uiKit/IconButton.vue'
import ModalDialog from '@/uiKit/ModalDialog.vue'
import Select from '@/uiKit/Select/Select.vue'
import TextField from '@/uiKit/TextField.vue'
import { useCloned } from '@vueuse/core'
import { computed, ref, useId, watch } from 'vue'
import type { NumberValueFilter } from './types'
import { buildNumberFilter, getNumberFilterValue } from './utils'

/**
 * This component renders a modal dialog that is used to update a number value filter
 */

type NumberMatcherName = NumberValueFilter['matcher']['name']

const props = defineProps<{
  filter: NumberValueFilter
  isOpen: boolean
  propertyName: string
  teleportTo?: string
}>()

const emit = defineEmits<{
  (e: 'close', event: Event): void
  (e: 'update', data: NumberValueFilter): void
}>()

const dialogLabelId = useId()

const matcherLabelMap: Record<NumberMatcherName, string> = {
  property_any_of: '=',
  property_greater_than: '>',
  property_greater_than_or_equal_to: '≥',
  property_less_than: '<',
  property_less_than_or_equal_to: '≤',
  property_none_of: '≠',
}
const matcherSelectOptions = Object.entries(matcherLabelMap).map(([value, label]) => ({
  value: value as NumberMatcherName,
  label,
}))

const savedFilter = computed(() => props.filter)
const { cloned: localFilter } = useCloned(savedFilter)

const searchValue = ref<string>(getNumberFilterValue(props.filter) || '')

watch(searchValue, (newValue) => {
  /**
   * Depending on the active matcher name, the search value might need to go in either
   * the `value` or `values` property of the matcher object.
   */
  localFilter.value = buildNumberFilter({
    matcherName: localFilter.value.matcher.name,
    propertyId: localFilter.value.matcher.property_id,
    value: newValue,
  })
})

const onChangeMatcherName = (newName: NumberMatcherName) => {
  localFilter.value = buildNumberFilter({
    matcherName: newName,
    propertyId: localFilter.value.matcher.property_id,
    value: searchValue.value,
  })
}

const onSave = (event: Event) => {
  emit('update', localFilter.value)
  emit('close', event)
}
</script>

<template>
  <ModalDialog
    :open="isOpen"
    :to="teleportTo"
    :aria-labelledby="dialogLabelId"
    @close="emit('close', $event)"
  >
    <div class="flex w-[480px] flex-col">
      <!-- Header -->
      <div class="flex items-center gap-2 p-2 pl-11 text-center">
        <h2
          :id="dialogLabelId"
          class="grow text-sm-12px-default text-text-subtle"
        >
          {{ propertyName }}
        </h2>
        <IconButton
          icon="close"
          variant="transparent"
          size="md"
          @click="emit('close', $event)"
        />
      </div>
      <DividerLine
        color="subtle"
        direction="horizontal"
      />
      <!-- Body -->
      <form
        class="flex gap-2 p-5"
        @submit.prevent="onSave"
      >
        <Select
          class="min-w-20"
          :value="filter.matcher.name"
          :items="matcherSelectOptions"
          disable-teleport
          @change="onChangeMatcherName"
        />
        <TextField
          autofocus
          class="grow"
          size="md"
          aria-label="Value"
          placeholder="Enter number"
          :value="searchValue"
          @input="searchValue = $event"
        />
      </form>
      <DividerLine
        color="subtle"
        direction="horizontal"
      />
      <div class="flex items-center justify-end gap-2 p-2">
        <DarwinButton
          variant="neutral"
          size="md"
          @click="emit('close', $event)"
        >
          Cancel
        </DarwinButton>
        <DarwinButton
          variant="black"
          size="md"
          @click="onSave"
        >
          Save
        </DarwinButton>
      </div>
    </div>
  </ModalDialog>
</template>
