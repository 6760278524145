import type { PropertyType } from '@/backend/types'
import type { Field } from '@/modules/Project/Fields/types'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { useWorkspaces } from '../Workspaces/useWorkspaces'
import { useWorkspaceMembers } from '../WorkspaceSettings/useWorkspaceMembers'
import type { FieldPreview } from './types/fieldPreview'
import { useProject } from './useProject'
import { useResolveProjectRoute } from './useResolveProjectRoute'

type Serializer<Type extends PropertyType> = (field: Field<Type> | FieldPreview<Type>) => string

/**
 * Returns a function that serializes any field into a string representing
 * its value. Is used in clipboard operations and any other situation where
 * we need to display a single text value to represent a given field.
 */
export const useSerializeFieldToText = () => {
  const workspaceMemberStore = useWorkspaceMembers()
  const userSerializer: Serializer<'user_select'> = (field) => {
    let userIds = field.manualValue
    if (!userIds || userIds.length === 0) {
      userIds = field.toolValue
    }

    if (!userIds || userIds.length === 0) {
      return ''
    }

    const users = userIds.reduce<string[]>((acc, userId) => {
      const user = workspaceMemberStore.workspaceMembers.find((member) => member.id === userId)
      if (!user) {
        return acc
      }

      const name = user.fullName || user.email || user.id

      return [...acc, name]
    }, [])

    return users.join(', ')
  }

  const projectStore = useProject()
  const workspacesStore = useWorkspaces()
  const resolveSubProjectRoute = useResolveProjectRoute()
  /**
   * Collections should be serialized to a URL that will open the collection.
   */
  const collectionSerializer: Serializer<'collection'> = (field) => {
    assertIsNotNullOrUndefined(workspacesStore.currentWorkspace, 'No current workspace')
    assertIsNotNullOrUndefined(projectStore.projectId, 'No current project')

    if ('manualName' in field && field.manualName) {
      return field.manualName
    }

    const property = projectStore.propertiesById[field.propertyId]
    if (!property) {
      return ''
    }

    if (property.type !== field.type) {
      throw new Error('Property type mismatch')
    }

    const subprojectId = property.config.subprojectConfig.child_project_id
    const route = resolveSubProjectRoute({
      parentEntityId: field.entityId,
      parentProjectId: projectStore.projectId,
      projectId: subprojectId,
      workspaceId: workspacesStore.currentWorkspace.id,
      parentPropertyId: property.id,
    })

    return `${window.location.origin}${route.fullPath}`
  }

  const fileCollectionSerializer: Serializer<'file_collection'> = (field) => {
    assertIsNotNullOrUndefined(workspacesStore.currentWorkspace, 'No current workspace')
    assertIsNotNullOrUndefined(projectStore.projectId, 'No current project')
    const property = projectStore.propertiesById[field.propertyId]
    if (!property) {
      return ''
    }

    if (property.type !== field.type) {
      throw new Error('Property type mismatch')
    }

    const subprojectId = property.config.subprojectConfig.child_project_id

    const route = resolveSubProjectRoute({
      parentEntityId: field.entityId,
      parentProjectId: projectStore.projectId,
      projectId: subprojectId,
      workspaceId: workspacesStore.currentWorkspace.id,
    })

    return `${window.location.origin}${route.fullPath}`
  }

  const numberSerializer: Serializer<'number'> = (field) => {
    /**
     * TODO: This will become more interesting as we get more information about
     * how numbers are stored and displayed. We will probably end up passing
     * property config to `Intl.NumberFormat`.
     */
    if (field.manualValue !== null) {
      return field.manualValue.toString()
    }

    if (field.toolValue !== null) {
      return field.toolValue.toString()
    }

    return ''
  }

  /**
   * Maps each field type to its serializer function. Is typed in such a way
   * that will cause a TS error if a new field type is added and not handled
   * here.
   */
  const fieldClipboardSerializers: { [T in Field['type']]: Serializer<T> } = {
    text: textSerializer,
    file: fileSerializer,
    json: jsonSerializer,
    multi_select: multiSelectSerializer,
    pdf: pdfSerializer,
    single_select: singleSelectSerializer,
    url: urlSerializer,
    user_select: userSerializer,
    collection: collectionSerializer,
    file_collection: fileCollectionSerializer,
    reference: referenceSerializer,
    number: numberSerializer,
  }

  const serializeField = <Type extends Field['type']>(field: Field<Type> | FieldPreview<Type>) => {
    const serializer = fieldClipboardSerializers[field.type]
    return serializer(field)
  }

  return serializeField
}

/**
 * TODO: GO-2985 - serialize to text once we have entity previews in the field response
 */
function referenceSerializer(field: Pick<Field<'reference'>, 'manualEntityIds' | 'toolEntityIds'>) {
  if (field.manualEntityIds && field.manualEntityIds.length > 0) {
    return field.manualEntityIds.join(', ')
  }

  if (field.toolEntityIds && field.toolEntityIds.length > 0) {
    return field.toolEntityIds.join(', ')
  }

  return ''
}

function textSerializer(field: Pick<Field<'text'>, 'manualValue' | 'toolValue'>) {
  return field.manualValue || field.toolValue || ''
}

function fileSerializer(field: Pick<Field<'file'>, 'manualFilename' | 'toolFilename'>) {
  return field.manualFilename || field.toolFilename || ''
}

function singleSelectSerializer(field: Pick<Field<'single_select'>, 'manualValue' | 'toolValue'>) {
  return field.manualValue?.at(0) || field.toolValue?.at(0) || ''
}

function multiSelectSerializer(field: Pick<Field<'multi_select'>, 'manualValue' | 'toolValue'>) {
  if (field.manualValue) {
    return field.manualValue.join(', ')
  }

  if (field.toolValue) {
    return field.toolValue.join(', ')
  }

  return ''
}

function jsonSerializer(field: Pick<Field<'json'>, 'manualValue' | 'toolValue'>) {
  return field.manualValue || field.toolValue || ''
}

function pdfSerializer(field: Pick<Field<'pdf'>, 'toolFilename' | 'manualFilename'>) {
  return field.manualFilename || field.toolFilename || ''
}

function urlSerializer(field: Pick<Field<'url'>, 'manualValue' | 'toolValue'>) {
  return field.manualValue || field.toolValue || ''
}
