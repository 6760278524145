import type { LibraryItemResponse } from '@/backend/types'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import type { Ref } from 'vue'
import { serializeField } from '../Project/useProject'
import { useProjectChannel, type ProjectChannelHandlers } from '../Project/useProjectChannel'
import { useLibraryStore, type LibraryItem } from './libraryStore'
import { serializeLibraryItem } from './serializers'

/**
 * Handle the websocket events for the library channel.
 */
export const useLibraryWebsocket = (libraryId: Ref<LibraryItem['id'] | undefined>) => {
  const libraryStore = useLibraryStore()

  const getSlugToUse = (entity: LibraryItemResponse) => {
    assertIsNotNullOrUndefined(libraryStore.library, 'Library is not loaded')

    const fileField = entity.fields[libraryStore.library.fileProperty.slug]
    assertIsNotNullOrUndefined(fileField, 'File field is not defined')

    const slugToUse = fileField.manual_value.value
      ? libraryStore.library.fileProperty.slug
      : libraryStore.library.textProperty.slug

    return slugToUse
  }

  const handlers: ProjectChannelHandlers = {
    onCreateEntity: (entity) => {
      if (!('library_attributes' in entity)) {
        return
      }

      const libraryItem = serializeLibraryItem(entity, getSlugToUse(entity))

      libraryStore.setItem(libraryItem)
    },
    onDeleteEntities: (ids) => {
      libraryStore.deleteItem(...ids)
    },
    onUpdateEntity: (entity) => {
      if (!('library_attributes' in entity)) {
        return
      }

      const libraryItem = serializeLibraryItem(entity, getSlugToUse(entity))
      libraryStore.setItem(libraryItem)
    },
    onDeleteProperty: () => {},
    onUpdateProperty: () => {},
    onUpdateField: (data) => {
      const field = serializeField(data)
      const libraryItem = libraryStore.getItem(field.entityId)

      if (field.type === 'file') {
        if (!field.manualFilename) {
          // If there is no filename, then the file has been deleted so we shouldn't use
          // this library item.
          libraryStore.libraryItems = libraryStore.libraryItems.filter(
            (item) => item.id !== field.entityId,
          )
          return
        }

        const item: LibraryItem = {
          id: field.entityId,
          filename: field.manualFilename,
          fileUrl: field.manualValue,
          name: libraryItem?.name || field.manualFilename,
          updatedAt: field.updatedAt ?? new Date().toISOString(),
          status: field.status,
          type: 'file',
          inputReference: libraryItem.inputReference,
          createdAt: libraryItem.createdAt,
          updatedBy: field.manualValueUpdatedBy || libraryItem.updatedBy,
        }
        libraryStore.setItem(item)
      } else if (field.type === 'text') {
        const item: LibraryItem = {
          id: field.entityId,
          name: libraryItem?.name || (field.manualValue ?? '').slice(0, 10),
          content: field.manualValue ?? '',
          updatedAt: field.updatedAt ?? new Date().toISOString(),
          status: field.status,
          type: 'text',
          inputReference: libraryItem.inputReference,
          createdAt: libraryItem.createdAt,
          updatedBy: field.manualValueUpdatedBy || libraryItem.updatedBy,
        }
        libraryStore.setItem(item)
      }
    },
    onDeleteView: () => {},
    onDeleteActiveView: () => {},
    onUpdateView: () => {},
    onSetStale: () => {},
  }

  useProjectChannel(libraryId, handlers)
}
