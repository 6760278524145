<script setup lang="ts">
import KnowledgeHubLinks from '@/modules/Workspaces/KnowledgeHub/Layout/KnowledgeHubLinks.vue'
import NavBarSidebarToggle from '@/modules/Workspaces/KnowledgeHub/Layout/NavBarSidebarToggle.vue'
import SupportButton from '@/sharedComponents/SupportButton.vue'
import { useHasIntercomChat } from '@/sharedComposables/useHasIntercomChat'
defineProps<{ hideLinks?: boolean }>()

const hasIntercomChat = useHasIntercomChat()
</script>

<template>
  <div class="flex h-11 items-center justify-between bg-surface-secondary p-2">
    <!-- Page title -->
    <div class="flex basis-1/3 items-center px-2">
      <NavBarSidebarToggle>
        <span class="text-sm-12px-default">Knowledge hub</span>
      </NavBarSidebarToggle>
    </div>

    <!-- Links -->
    <div
      v-if="!hideLinks"
      class="flex basis-1/3 justify-center gap-2"
    >
      <KnowledgeHubLinks />
    </div>

    <!-- Actions -->
    <div class="flex basis-1/3 justify-end">
      <SupportButton
        v-if="hasIntercomChat"
        compact
      />
    </div>
  </div>
</template>
