import { SUPPORTED_MIME_TYPES } from '@/backend/types'
import { computed } from 'vue'

/**
 * Returns all MIME types that are supported by the application.
 *
 * This composable can sometimes seem kind of useless, because it might
 * return the same array everywhere, so it could simply be a constant.
 *
 * However, we often add MIME types behind feature flags. When
 * we do so it's useful to only need to update one place (this composable).
 */
export const useSupportedMimeTypes = () => {
  return computed<string[]>(() => {
    return [
      ...SUPPORTED_MIME_TYPES,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ]
  })
}
