import type { FieldStatus, PropertyType } from '@/backend/types'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'

type BaseLibraryItem = {
  id: string
  name: string
  createdAt: string
  updatedAt: string
  updatedBy: string
  status: FieldStatus
  /**
   * Data on where this library item is used as an input.
   */
  inputReference: Array<{ projectId: string; propertyId: string }>
}

export type LibraryFile = BaseLibraryItem & {
  type: Extract<PropertyType, 'file'>
  filename: string
  fileUrl: string | null
}

export type LibraryDocument = BaseLibraryItem & {
  type: Extract<PropertyType, 'text'>
  content: string
}

export type LibraryItem = LibraryFile | LibraryDocument

type Library = {
  id: string
  fileProperty: { id: string; slug: string }
  textProperty: { id: string; slug: string }
}

/**
 * The library store provides 'library items' which are used as inputs to tools.
 * When selected as an input to a tool, the tool will use the library item as
 * an input for every entity in the project.
 */
export const useLibraryStore = defineStore('library', () => {
  const library = ref<Library | null>(null)
  const libraryId = computed(() => library.value?.id ?? null)
  const isLoaded = ref(false)

  const dialogIsOpen = ref(false)

  const { captureAnalyticsEvent } = useAnalytics()
  watch(dialogIsOpen, (isOpen) => {
    if (!isOpen) return
    captureAnalyticsEvent(ANALYTICS_EVENT.LIBRARY_OPENED)
  })

  /**
   * Maps uploading library items to their upload progress. This is kept separate
   * from the library items themselves so that the progress isn't lost when we
   * receive websocket updates. It's held in the store (i.e. is global) because
   * we want the progress to persist when the user navigates away from the library.
   */
  const uploadProgressMap = ref<Partial<Record<LibraryItem['id'], number>>>({})

  /**
   * Library items are just entities in the library project. This means that
   * when uploading a file to the library, we are actually uploading a file
   * to a property field on an entity in the library project. This ref holds
   * the IDs of the entities that have been created for the library items, but
   * have not yet had a file uploaded to them.
   */
  const emptyItemIds = ref<string[]>([])

  const libraryItems = ref<LibraryItem[]>([])

  const getItemAndIndexFromId = (itemId: string): [LibraryItem | null, number] => {
    const itemIndex = libraryItems.value.findIndex((i) => i.id === itemId)
    const item = itemIndex === -1 ? null : { ...libraryItems.value[itemIndex] }
    return [item, itemIndex]
  }

  /**
   * Adds a library item, overwriting any existing item with the same ID.
   */
  const setItem = (item: LibraryItem) => {
    // Get the original item and its index in case we need to revert
    const [oldItem, index] = getItemAndIndexFromId(item.id)
    if (index === -1) {
      libraryItems.value.push(item)
    } else {
      libraryItems.value[index] = item
    }

    return oldItem
      ? () => {
          setItem(oldItem)
        }
      : () => deleteItem(item.id)
  }

  const deleteItem = (...itemIds: string[]) => {
    const previousItems = libraryItems.value.slice()
    const revert = () => {
      libraryItems.value = previousItems
    }

    libraryItems.value = libraryItems.value.filter((item) => !itemIds.includes(item.id))

    return revert
  }

  const reset = () => {
    library.value = null
    libraryItems.value = []
    dialogIsOpen.value = false
    emptyItemIds.value = []
    isLoaded.value = false
  }

  const getItem = (id: string): LibraryItem => {
    const match = libraryItems.value.find((item) => item.id === id)
    assertIsNotNullOrUndefined(match, 'No library item found matching the ID given')

    return match
  }

  const getItemType = (id: string): LibraryItem['type'] => getItem(id).type

  /**
   * Adds an input reference to a library item. This is used when a library item has
   * been added as an input to a property in a project.
   */
  const addInputReference = ({
    itemId,
    projectId,
    propertyId,
  }: {
    itemId: string
    projectId: string
    propertyId: string
  }) => {
    const item = getItem(itemId)
    item.inputReference.push({ projectId, propertyId })
    setItem(item)
  }

  /**
   * Deletes an input reference from a library item. This is used when a library item
   * has been removed as an input from a property in a project.
   */
  const deleteInputReference = ({
    itemId,
    projectId,
    propertyId,
  }: {
    itemId: string
    projectId: string
    propertyId: string
  }) => {
    const item = getItem(itemId)
    item.inputReference = item.inputReference.filter(
      (ref) => ref.projectId !== projectId || ref.propertyId !== propertyId,
    )
    setItem(item)
  }

  return {
    dialogIsOpen,

    emptyItemIds,

    libraryItems,
    getItem,
    getItemType,
    uploadProgressMap,

    addInputReference,
    deleteInputReference,

    setItem,
    deleteItem,

    library,
    libraryId,

    reset,
    isLoaded,
  }
})
