<script setup lang="ts">
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import LibraryDocument from './LibraryDocument.vue'
import { useLibraryDocument } from './useLibraryDocument'
import { useUploadLibraryFile } from './useUploadLibraryFile'
defineProps<{
  workspaceId: string
}>()

const { onClickUpload } = useUploadLibraryFile()
const { isEditingLibraryDocument, onClickCreateDocument, onSaveDocument, activeItem, onClose } =
  useLibraryDocument()
</script>

<template>
  <div class="flex size-full flex-col items-center justify-center p-4">
    <h2 class="mb-2 text-sm-12px-bold text-text">Library is empty</h2>
    <p class="mb-6 max-w-[353px] text-center text-sm-12px-light text-text-subtle">
      Upload or create universal files (PDFs, CSVs, text) that provide guidelines for AI models.
      Reference these across all of your projects.
    </p>
    <div class="flex items-stretch gap-2">
      <DarwinButton
        variant="neutral"
        size="sm"
        @click="onClickCreateDocument"
      >
        <template #leading-icon>
          <IconSprite icon="plus" />
        </template>
        Create document
      </DarwinButton>
      <DarwinButton
        variant="black"
        size="sm"
        @click="onClickUpload"
      >
        <template #leading-icon>
          <IconSprite
            icon="upload"
            class="text-icon-inverted"
          />
        </template>
        Upload file
      </DarwinButton>
    </div>
  </div>
  <LibraryDocument
    aria-label="Create new library document"
    :open="isEditingLibraryDocument"
    :initial-title="activeItem?.name"
    :initial-content="activeItem?.content"
    @close="onClose"
    @save="onSaveDocument({ content: $event.content, title: $event.title, workspaceId })"
  />
</template>
