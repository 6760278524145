<script setup lang="ts">
import type { LibraryItem } from '@/modules/Library/libraryStore'
import { getFileExtension } from '@/modules/Workspaces/KnowledgeHub/Files/library-item'
import { computed, defineAsyncComponent } from 'vue'

const props = defineProps<{ item: LibraryItem }>()

const document = Symbol('libraryDocument')

const placeholderComponent = computed(() => {
  const type = props.item.type === 'text' ? document : getFileExtension(props.item)

  switch (type) {
    case 'doc':
    case 'docx':
    case 'md':
    case 'pdf':
    case 'txt':
    case document:
      return defineAsyncComponent(() => import('@/illustrations/library-pdf.svg'))
    case 'csv':
      return defineAsyncComponent(() => import('@/illustrations/library-csv.svg'))
    default:
      return defineAsyncComponent(() => import('@/illustrations/library-image.svg'))
  }
})
</script>

<template>
  <component :is="placeholderComponent" />
</template>
