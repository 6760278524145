<script setup lang="ts">
import type { LibraryItem } from '@/modules/Library/libraryStore'
import { useFileTableContext } from '@/modules/Workspaces/KnowledgeHub/Files/context'
import FileNameCell from '@/modules/Workspaces/KnowledgeHub/Files/FileNameCell.vue'
import FileRowMenu from '@/modules/Workspaces/KnowledgeHub/Files/FileRowMenu.vue'
import FileTableRow from '@/modules/Workspaces/KnowledgeHub/Files/FileTableRow.vue'
import FileTableRowGrid from '@/modules/Workspaces/KnowledgeHub/Files/FileTableRowGrid.vue'
import { getFileTypeName } from '@/modules/Workspaces/KnowledgeHub/Files/library-item'
import { useProfileImage } from '@/modules/WorkspaceSettings/useProfileImage'
import { useWorkspaceMembers } from '@/modules/WorkspaceSettings/useWorkspaceMembers'
import AvatarIcon from '@/uiKit/AvatarIcon.vue'
import CheckBox from '@/uiKit/CheckBox.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import { useTimeAgo } from '@vueuse/core'
import { computed, ref } from 'vue'

const props = defineProps<{ item: LibraryItem }>()

const members = useWorkspaceMembers()
const selection = useFileTableContext()

const isSelected = computed({
  get() {
    return selection.isSelected(props.item.id)
  },
  set(value) {
    selection.setSelected(props.item.id, value)
  },
})

const isRenaming = ref(false)

/** Menu state is used to make sure that row stays highlighted and menu trigger stays visible */
const isMenuOpen = ref(false)

const user = computed(() => members.workspaceMembers.find((u) => u.id === props.item.updatedBy))
const userId = computed(() => user.value?.id || '')
const profileImageUrl = useProfileImage(userId)
const isIncomplete = computed(() => props.item.status !== 'complete')
</script>

<template>
  <FileTableRow :class="{ selected: isSelected, 'text-text-disabled': isIncomplete }">
    <template #checkbox>
      <CheckBox
        :disabled="isIncomplete"
        :checked="isSelected"
        :class="{ '!opacity-100': isSelected }"
        class="ml-0.5 opacity-0 focus-within:opacity-100 group-hover:opacity-100"
        @change="(ev) => (isSelected = ev)"
      />
      <!-- 👆 left margin so that the checkbox focus ring isn't clipped -->
    </template>

    <template #grid>
      <FileTableRowGrid
        class="rounded-lg transition-[border-radius] duration-200"
        data-selection-boundary
        :class="[
          isSelected
            ? 'bg-background-selected group-hover:bg-background-selected-hovered'
            : 'group-hover:bg-background-transparent-hovered',
          isMenuOpen &&
            (isSelected ? '!bg-background-selected-hovered' : '!bg-background-transparent-hovered'),
        ]"
      >
        <template #filename>
          <FileNameCell
            v-model:is-renaming="isRenaming"
            :item="item"
          />
        </template>

        <template #fileType>
          <span>
            {{ getFileTypeName(item) }}
          </span>
        </template>

        <template #createdAt>
          <span class="capitalize">
            {{ useTimeAgo(item.createdAt) }}
          </span>
        </template>

        <template #updatedBy>
          <div class="flex gap-1.5">
            <AvatarIcon
              :class="{ 'opacity-20': isIncomplete }"
              shape="circle"
              size="xs"
              :url="profileImageUrl"
              :full-text="user?.fullName"
            />
            {{ user?.fullName }}
          </div>
        </template>

        <template #visibility>
          <div class="flex items-center gap-1.5">
            <IconSprite icon="annotators"></IconSprite>
            Shared
          </div>
        </template>

        <template #actions>
          <div
            class="opacity-0 focus-within:opacity-100 group-hover:opacity-100"
            :class="{ '!opacity-100': isMenuOpen }"
          >
            <FileRowMenu
              v-if="!isIncomplete"
              :item="item"
              @rename="isRenaming = true"
              @menu-open="isMenuOpen = $event"
            />
          </div>
        </template>
      </FileTableRowGrid>
    </template>
  </FileTableRow>
</template>

<style scoped>
.selected:has(+ .selected) [data-selection-boundary] {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.selected + .selected [data-selection-boundary] {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
