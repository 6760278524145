<script setup lang="ts">
import type { SupportedMimeType } from '@/backend/types'
import CsvIllustration from '@/illustrations/library-csv.svg'
import ImageIllustration from '@/illustrations/library-image.svg'
import PdfIllustration from '@/illustrations/library-pdf.svg'
import { pluralize } from '@/shared/utils/string'
import { computed, ref, watch } from 'vue'

const props = defineProps<{
  show: boolean
  files: { type: SupportedMimeType }[]
}>()
const emit = defineEmits<{ close: [] }>()
const showPanel = ref(false)
const showIllustrations = ref(false)

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

const illustrationStack = computed(() => {
  return props.files.slice(0, 4).map(({ type }) => {
    switch (type) {
      // CSV and structured data
      case 'text/csv':
      case 'application/json':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return CsvIllustration

      // Image formats
      case 'image/bmp':
      case 'image/gif':
      case 'image/jpeg':
      case 'image/png':
      case 'image/tiff':
      case 'image/webp':
        return ImageIllustration

      default:
        return PdfIllustration
    }
  })
})

watch(
  () => props.show,
  async (show) => {
    if (show) {
      showPanel.value = true
      await wait(50) // Stagger the animation for a little bit
      showIllustrations.value = true
    } else {
      showPanel.value = false
    }
  },
  { immediate: true },
)
</script>

<template>
  <!-- eslint-disable tailwindcss/no-custom-classname -->
  <div
    class="pointer-events-none flex size-full flex-col items-center justify-end"
    @click="emit('close')"
  >
    <Transition
      enter-active-class="transition duration-300 ease-in-out"
      enter-from-class="opacity-0 translate-y-4"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition duration-300 ease-in-out"
      leave-from-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 translate-y-4"
    >
      <div
        v-if="showPanel && files.length"
        class="mb-32 flex flex-col items-center justify-center gap-6 rounded-3xl bg-background-gray-subtlest px-10 py-8 text-md-13px-default text-text-subtle shadow-md backdrop-blur-md"
      >
        <div class="relative flex size-32 items-center justify-center">
          <template v-if="showIllustrations">
            <!-- eslint-disable-next-line tailwindcss/no-custom-classname -->
            <div class="illustration-container absolute size-full opacity-0">
              <component
                :is="previewComponent"
                v-for="(previewComponent, i) in illustrationStack"
                :key="i"
                data-illustration
                class="illustration absolute size-32"
              />
            </div>
          </template>
        </div>
        <div>Drop to import {{ files.length }} {{ pluralize(files.length, 'file', 'files') }}</div>
      </div>
    </Transition>
  </div>
</template>

<style scoped>
.illustration-container {
  opacity: 0;
  animation: fadeIn 150ms ease-in-out forwards;
}

.illustration:nth-last-child(4) {
  animation: transformUp1 500ms 0s ease-in-out forwards;
}
.illustration:nth-last-child(3) {
  animation: transformUp2 400ms 0ms ease-in-out forwards;
}
.illustration:nth-last-child(2) {
  animation: transformUp3 300ms 0ms ease-in-out forwards;
}
.illustration:nth-last-child(1) {
  animation: transformUp4 100ms 0ms ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes transformUp1 {
  from {
    transform: translate(-0.25rem, 0.425rem) rotate(-6deg);
  }
  to {
    transform: translate(-0.25rem, 0) rotate(-12deg);
  }
}

@keyframes transformUp2 {
  from {
    transform: translateY(0.425rem) rotate(0deg);
  }
  to {
    transform: translateY(0) rotate(-3deg);
  }
}

@keyframes transformUp3 {
  from {
    transform: translate(0.25rem, 0.275rem) rotate(0deg);
  }
  to {
    transform: translate(0.25rem, 0.25rem) rotate(6deg);
  }
}

@keyframes transformUp4 {
  from {
    transform: translateY(0.625rem);
  }
  to {
    transform: translateY(0);
  }
}
</style>
