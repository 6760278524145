<script setup lang="ts">
import type { PropertyType } from '@/backend/types'
import { useProjectActions } from '@/modules/Project/useProjectActions'
import { useProperty } from '@/modules/Project/useProperty'
import { computed, ref } from 'vue'
import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'
import ProjectConfiguration from './ProjectConfiguration.vue'
import { COLS_BEFORE_LOAD } from './ProjectTable.vue'
import ProjectTableHeaderAddProperty from './ProjectTableHeaderAddProperty.vue'
import ProjectTableHeaderCheckbox from './ProjectTableHeaderCheckbox.vue'
import ProjectTableHeaderItem from './ProjectTableHeaderItem.vue'
import { useAskGo } from './useAskGo'
import { useColumnReordering } from './useColumnReordering'
import { usePinnedColumn } from './usePinnedColumn'
import { useProject } from './useProject'
import { PINNED_HEADER_Z_INDEX, useTableZIndices } from './useTableZIndices'

defineProps<{
  indexColWidth: number
}>()

const askGoStore = useAskGo()
const permissionsStore = usePermissionsStore()
const pinnedColumn = usePinnedColumn()
const projectStore = useProject()
const propertyStore = useProperty()
const tableZIndex = useTableZIndices()
const projectActions = useProjectActions()

const header = ref<HTMLElement | null>(null)

const createNewPropertyInStoreAndBackend = (type: PropertyType) => {
  return projectActions.createProperty(type)
}

const { onHeaderItemMouseDown, hasMoved, dragBoxStyles, potentialNewIndex, lineStyles } =
  useColumnReordering(header)

const pinnedColumns = computed(() => pinnedColumn.numPinnedProperties)
</script>

<template>
  <div
    ref="header"
    role="row"
    aria-rowindex="1"
    class="contents"
  >
    <ProjectTableHeaderCheckbox
      class="sticky left-0 top-0 border-y border-r border-border-subtle bg-surface-primary shadow-[0px_-2px_0px_var(--color-surface-secondary-persist)]"
      :style="{ zIndex: PINNED_HEADER_Z_INDEX, width: indexColWidth + 'px' }"
      aria-rowindex="1"
      aria-colindex="1"
    />
    <ProjectTableHeaderItem
      v-for="(property, index) in projectStore.projectLoaded
        ? projectStore.visibleProperties
        : [...new Array(COLS_BEFORE_LOAD)].fill({ id: '', name: '' })"
      :key="property.id"
      class="group sticky top-0 border-y border-r border-border-subtle bg-surface-primary before:absolute before:-z-1 before:size-full focus-within:before:bg-background-transparent-hovered hover:before:bg-background-gray-subtlest focus:outline-none [&:nth-last-child(2)]:border-r-0"
      :property="property"
      :width="projectStore.getWidth(property.id)"
      :selected="property.id === projectStore.selectedPropertyId"
      :aria-selected="property.id === projectStore.selectedPropertyId"
      :is-input="
        propertyStore.editedInputs.some(
          ({ propertyId, entityId }) => propertyId === property.id && !entityId,
        ) || askGoStore.highlightedInput?.propertyId === property.id
      "
      data-test="property-header"
      :data-pinned="index < pinnedColumns ? '' : undefined"
      role="columnheader"
      :aria-rowindex="1"
      :aria-colindex="index + 2"
      :is-optimistic="projectStore.optimisticIds.includes(property.id)"
      :style="{
        ...pinnedColumn.getColStyle(index, indexColWidth),
        zIndex: index < pinnedColumns ? tableZIndex.zIndex.pinnedHeader : tableZIndex.zIndex.header,
      }"
      @select="projectStore.setSelectedProperty(property.id)"
      @resize="projectStore.resizeProperty(property.id, $event)"
      @mousedown="onHeaderItemMouseDown(property.id, $event)"
    />
    <div
      class="sticky right-[-30px] top-0 flex h-8 min-w-[100px] flex-row border-t border-border-subtle bg-surface-primary shadow-[0px_-2px_0px_var(--color-surface-secondary-persist)]"
      :style="{ zIndex: tableZIndex.zIndex.header }"
    >
      <template v-if="permissionsStore.currentProjectPermissions.manage_properties">
        <ProjectTableHeaderAddProperty
          class="border-b border-l border-border-subtle bg-surface-primary hover:bg-background-transparent-hovered active:bg-background-transparent-pressed"
          :project-id="projectStore.projectId"
          @create-property="createNewPropertyInStoreAndBackend"
        />
        <ProjectConfiguration />
      </template>
      <div
        v-else
        class="border-b border-l border-border-subtle"
      />
    </div>
    <Teleport
      v-if="hasMoved"
      to="body"
    >
      <div
        class="pointer-events-none absolute z-10 bg-background-selected"
        :style="dragBoxStyles"
      >
        <div class="flex h-full items-center justify-center"></div>
      </div>

      <div
        v-if="potentialNewIndex !== null"
        ref="wholeSpanRef"
        :style="lineStyles"
        class="absolute z-10 h-full border-l-2 border-background-primary"
      ></div>
    </Teleport>
  </div>
</template>
