<script setup lang="ts">
import { twMerge } from 'tailwind-merge'
import ListMenuContainer from '../ListMenuContainer.vue'
import { MenuCtx } from './Root.vue'

defineOptions({
  name: 'MenuContent',
  inheritAttrs: false,
})

const { menu, props } = MenuCtx.get()
</script>

<template>
  <Teleport
    :to="menu.open ? props.teleportTo : 'body'"
    :disabled="props.disableTeleport"
  >
    <div
      v-if="menu.open || $slots['context-trigger']"
      v-bind="props.static ? {} : menu.getPositionerProps()"
    >
      <ListMenuContainer
        v-bind="{ ...menu.getContentProps(), ...$attrs }"
        :class="twMerge('flex w-full flex-col p-0.5', String($attrs.class || ''))"
      >
        <slot :get-trigger-item-props="menu.getTriggerItemProps" />
      </ListMenuContainer>
    </div>
  </Teleport>
</template>
