<script setup lang="ts">
import CheckBox from '@/uiKit/CheckBox.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'

defineProps<{
  selected: number
  all: boolean
}>()

const emit = defineEmits<{
  select: [state: boolean]
  delete: []
}>()
</script>

<template>
  <div
    class="flex items-center rounded-xl bg-surface-tertiary text-sm-12px-default shadow-sm outline outline-1 outline-border-subtle"
  >
    <span class="pl-4 pr-3 tabular-nums">{{ selected }} Selected</span>
    <div
      class="flex items-center rounded-xl bg-surface-primary p-1 outline outline-1 outline-border-subtle"
    >
      <div class="flex gap-1">
        <CheckBox
          class="h-7 rounded-corner-8 px-2 py-1 hover:bg-background-transparent-hovered active:bg-background-transparent-pressed"
          :indeterminate="!all"
          :checked="all"
          :label="all ? 'Deselect all' : 'Select all'"
          @change="emit('select', $event)"
        />
      </div>
      <slot />

      <DarwinButton
        size="sm"
        variant="critical-transparent"
        @click="emit('delete')"
      >
        <div class="flex gap-1">
          <IconSprite icon="trash" />
          Delete
        </div>
      </DarwinButton>
    </div>
  </div>
</template>
