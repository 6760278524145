<script setup lang="ts">
import { useTemplateModal } from '@/modules/Workspaces/TemplateModal.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import Menu from '@/uiKit/Menu'
import { ref } from 'vue'
import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'
import { useCsvImportModal } from '../Workspaces/CsvImportModal.vue'
import { useTemplates } from './useTemplates'

const props = defineProps<{ workspaceId: string; size?: 'md' | 'xs' | 'sm' | 'lg' }>()

const templateModal = useTemplateModal()
const csvModal = useCsvImportModal()
const templateStore = useTemplates()

const isCreating = ref(false)

const startFromScratch = () => {
  isCreating.value = true
  templateStore.startFromScratch(props.workspaceId).finally(() => {
    isCreating.value = false
  })
}

const permissionsStore = usePermissionsStore()
</script>

<template>
  <Menu.Root
    v-if="permissionsStore.workspacePermissions.create_projects"
    v-slot="{ getTriggerProps }"
  >
    <DarwinButton
      variant="black"
      data-test="new-project-button-homepage"
      :size="size || 'sm'"
      v-bind="{ ...getTriggerProps(), ...$attrs }"
      :loading="isCreating"
      :disabled="isCreating"
    >
      <template #leading-icon><IconSprite icon="plus" /></template>
      <template #trailing-icon><IconSprite icon="chevron-bottom" /></template>
      New project
    </DarwinButton>
    <Menu.Content
      v-if="!isCreating"
      class="min-w-[242px]"
    >
      <Menu.Item
        data-test="explore-templates"
        label="Explore templates"
        @select="templateModal.open"
      >
        <template #prefix>
          <div class="flex size-5 items-center justify-center text-icon-subtle">
            <IconSprite icon="templates" />
          </div>
        </template>
      </Menu.Item>
      <Menu.Item
        label="Import CSV File"
        @select="csvModal.open"
      >
        <template #prefix>
          <div class="flex size-5 items-center justify-center text-icon-subtle">
            <IconSprite icon="table" />
          </div>
        </template>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        label="Start from scratch"
        @select="startFromScratch"
      />
    </Menu.Content>
  </Menu.Root>
</template>
