<script setup lang="ts">
import Menu from '@/uiKit/Menu'
import { TYPE_ICON } from '../icons'
import type { Property } from '../Properties/types'
import { useProject } from '../useProject'
import { useSorting } from '../useSorting'

defineEmits<{
  (e: 'select', pid: string): void
}>()
const projectStore = useProject()
const sortingStore = useSorting()

const getPropertyDisabled = (p: Property): boolean => sortingStore.hasRule(p.id)
</script>

<template>
  <Menu.Root
    v-slot="{ getTriggerProps, menu }"
    :positioning="{ placement: 'top-start' }"
    close-on-select
  >
    <slot
      :get-trigger-props="getTriggerProps"
      :menu="menu"
    />

    <Menu.Content class="!w-60">
      <Menu.GroupTitle label="Properties" />
      <Menu.Item
        v-for="property in projectStore.visibleProperties"
        :key="property.id"
        :icon="TYPE_ICON[property.type]"
        :class="{ 'cursor-not-allowed opacity-50': getPropertyDisabled(property) }"
        :default-hover-disabled="getPropertyDisabled(property)"
        :disabled="getPropertyDisabled(property)"
        :label="property.name"
        @select="$emit('select', property.id)"
      >
      </Menu.Item>
      <Menu.GroupTitle label="Misc attributes" />
      <Menu.Item
        label="Created at"
        icon="calendar"
        :disabled="sortingStore.hasRule('id')"
        @select="$emit('select', 'id')"
      />
    </Menu.Content>
  </Menu.Root>
</template>
