import { inject, provide } from 'vue'

const TELEPORT_KEY = 'popover-teleport-target'

/**
 * This function sets the target element for the Popover component to teleport to.
 * @param selector - The CSS selector of the target element. Null to disable teleporting.
 */
export const setPopoverTeleportTarget = (selector: string | null) => {
  provide(TELEPORT_KEY, selector)
}

/**
 * This function gets the target element that the Popover component should
 * teleport to.
 * @param selector - The CSS selector of the target element. Null to disable teleporting.
 */
export const getPopoverTeleportTarget = () => {
  return inject<string | undefined>(TELEPORT_KEY)
}
