<script setup lang="ts">
import type { Property } from '@/modules/Project/Properties/types'
import { parseConjunction, useFilters } from '@/modules/Project/useFilters'
import { useProject } from '@/modules/Project/useProject'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import DividerLine from '@/uiKit/DividerLine.vue'
import IconButton from '@/uiKit/IconButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import { computed } from 'vue'
import ProjectFilters from '../ProjectFilters.vue'
import FilterBarAddFilter from './FilterBarAddFilter.vue'
import FilterItem from './FilterItem.vue'
import SortPopover from './SortPopover.vue'
import type { SimpleFilter } from './types'

const filtersStore = useFilters()
const projectStore = useProject()

type TFilterItem = { filter: SimpleFilter; property?: Property | undefined }

const filterItems = computed(() => {
  if (!filtersStore.currentFilter) return []
  return filtersStore.currentFilter.filters.reduce<TFilterItem[]>((acc, f) => {
    if (!('matcher' in f)) return acc

    const propertyId = 'property_id' in f.matcher ? f.matcher.property_id : undefined
    const property = projectStore.properties.find((p) => p.id === propertyId)

    if (!property && f.subject !== 'entity_id' && f.subject !== 'entity_status') return acc

    return [...acc, { filter: f, property }]
  }, [])
})

const onChangeConjunction = () => {
  if (!filtersStore.currentFilter) return

  const newConjunction = filtersStore.currentFilter.conjunction === 'and' ? 'or' : 'and'
  filtersStore.setConjunction(newConjunction)
}

const onUpdateFilter = ({ filter, index }: { filter: SimpleFilter; index: number }) => {
  filtersStore.updateFilter(index, filter)
}
</script>

<template>
  <div
    class="no-scrollbar flex shrink-0 select-none items-center gap-2 overflow-x-scroll border-t border-border-subtle bg-surface-secondary-persist px-2.5 py-2"
    :class="!filtersStore.viewFilters ? 'hidden' : ''"
  >
    <SortPopover />
    <DividerLine
      direction="vertical"
      color="subtle"
    />
    <ProjectFilters />
    <template v-if="filtersStore.currentFilter && filtersStore.currentFilter.filters.length > 0">
      <DarwinButton
        variant="neutral"
        size="sm"
        @click="onChangeConjunction"
      >
        {{ parseConjunction(filtersStore.currentFilter.conjunction) }}
        <template #trailing-icon>
          <IconSprite icon="chevron-select" />
        </template>
      </DarwinButton>

      <div class="flex items-center gap-2">
        <FilterItem
          v-for="({ filter, property }, i) in filterItems"
          :key="i"
          class="flex h-6 shrink-0 cursor-default items-center rounded-corner-6 border border-border-subtle bg-surface-primary"
          :filter="filter"
          :index="i"
          :property="property"
          @delete="filtersStore.removeFilter(i)"
          @update="onUpdateFilter"
        />
        <FilterBarAddFilter>
          <template #trigger="{ isOpen, getTriggerProps }">
            <IconButton
              class="mr-2 text-icon-subtle"
              :class="{ 'bg-background-transparent-hovered': isOpen }"
              aria-label="Add a filter"
              size="lg"
              icon="plus"
              variant="outline"
              v-bind="getTriggerProps()"
            />
          </template>
        </FilterBarAddFilter>
      </div>
    </template>
  </div>
</template>
