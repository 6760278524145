import type { PropertyType } from '@/backend/types'
import type { Property } from '@/modules/Project/Properties/types'
import { exhaustiveGuard } from './typeAssertions'
import { isCollectionProperty } from './typeGuards'

/**
 * Gets the subproject ID from a property that supports subprojects
 */
export const getSubprojectId = (
  property: Property<'collection' | 'pdf' | 'file_collection'>,
): string => property.config.subprojectConfig.child_project_id

/**
 * Converts a property from one type to another, preserving all common fields but updating the
 * property's config to match its new type.
 */
export const convertPropertyType = (property: Property, newType: PropertyType): Property => {
  switch (newType) {
    case 'reference': {
      return {
        ...property,
        config: {
          entityLimit: 1,
          projectId: '',
        },
        type: newType,
      }
    }
    case 'text':
    case 'json':
    case 'file':
    case 'url': {
      return {
        ...property,
        type: newType,
      }
    }
    case 'single_select':
    case 'multi_select':
    case 'user_select': {
      // Keep the same config if converting between single/multi select
      const keepConfig =
        (property.type === 'single_select' || property.type === 'multi_select') &&
        (newType === 'single_select' || newType === 'multi_select')

      return {
        ...property,
        type: newType,
        config: keepConfig
          ? property.config
          : {
              options: [],
              defaultOption: null,
            },
      }
    }
    case 'collection':
    case 'file_collection': {
      return {
        ...property,
        type: newType,
        config: isCollectionProperty(property)
          ? property.config
          : {
              properties: [],
              subprojectConfig: {
                child_project_id: '',
                primary_property_id: '',
              },
            },
      }
    }
    case 'pdf': {
      return {
        ...property,
        type: newType,
        config: {
          splitter: 'page_split',
          subprojectConfig: {
            child_project_id: '',
            primary_property_id: '',
          },
        },
      }
    }

    case 'number': {
      return {
        ...property,
        type: newType,
        config: {
          isCustomFormat: false,
          format: {
            decimalPlaces: 'auto',
            negativeFormat: 'minus',
            rightAlign: false,
            thousandsSeparator: true,
          },
        },
      }
    }

    default: {
      return exhaustiveGuard(newType, 'Unhandled property type')
    }
  }
}
