<script setup lang="ts">
import { exhaustiveGuard, invariant } from '@/shared/utils/typeAssertions'
import { isSelectProperty } from '@/shared/utils/typeGuards'
import { computed, ref } from 'vue'
import { type ID } from './Filters/FilterItemMatcher.vue'
import FilterItemNumberValue from './Filters/FilterItemNumberValue.vue'
import FilterItemSelectValuePicker from './Filters/FilterItemSelectValuePicker.vue'
import FilterItemTextValue from './Filters/FilterItemTextValue.vue'
import {
  assertIsValidSelectMatcher,
  assertIsValidTextMatcher,
  getFilterSubjectForProperty,
  isTextLikeFilter,
  isTextLikeFilterSubject,
  isValidNumberMatcher,
  type FieldValueFilter,
  type FilterableProperty,
  type NumberValueFilter,
  type TextLikeFilter,
} from './Filters/types'
import {
  buildNumberFilter,
  getFilterDisplayValue,
  getFilterValue,
  getNumberFilterValue,
} from './Filters/utils'
import { FILTER_MENU_ID } from './ModelInputMenuFilters.vue'
import ModelInputMenuFiltersFilterTemplate from './ModelInputMenuFiltersFilterTemplate.vue'
/**
 * Renders a component that allows the user to update a text/select filter,
 * applied to a tool input.
 */

const props = defineProps<{
  properties: FilterableProperty[]
  filter: FieldValueFilter
}>()

const emit = defineEmits<{
  (e: 'update', filter: FieldValueFilter): void
}>()

const values = ref<string[] | string | undefined>(getFilterValue(props.filter))

const selectedProperty = computed<FilterableProperty | null>(() => {
  const propertyId = props.filter.matcher.property_id
  return props.properties.find((p) => p.id === propertyId) ?? null
})

const onNewMatcher = (matchId: ID) => {
  if (!selectedProperty.value) return

  if (isTextLikeFilter(props.filter)) {
    assertIsValidTextMatcher(matchId)
    const filter: FieldValueFilter = {
      ...props.filter,
      matcher: {
        ...props.filter.matcher,
        name: matchId,
      },
    }
    emit('update', filter)
  } else if (props.filter.subject === 'field_select_option_value') {
    let values = props.filter.matcher.values
    if (matchId === 'property_not_set') {
      matchId = 'property_none_of'
      if (isSelectProperty(selectedProperty.value)) {
        values = selectedProperty.value.config.options.map((option) => option.value)
      }
    }

    assertIsValidSelectMatcher(matchId)

    const filter: FieldValueFilter = {
      ...props.filter,
      matcher: {
        ...props.filter.matcher,
        name: matchId,
        values,
      },
    }
    emit('update', filter)
  } else if (props.filter.subject === 'field_number_value') {
    invariant(isValidNumberMatcher(matchId), 'Invalid number matcher ID')
    const filter = buildNumberFilter({
      matcherName: matchId,
      value: getNumberFilterValue(props.filter) || '',
      propertyId: props.filter.matcher.property_id,
    })

    emit('update', filter)
  } else {
    return exhaustiveGuard(props.filter, 'Unhandled filter subject')
  }
}

const onCreateFilter = (property: FilterableProperty) => {
  const subject = getFilterSubjectForProperty(property.type)

  /**
   * For the matchers we create new filters with, the value will only ever be an array.
   * But `values.value` could be typed as string, depending on the matcher name.
   */
  const valueAsArray = typeof values.value === 'string' ? [values.value] : values.value || []

  if (subject === 'field_select_option_value') {
    emit('update', {
      subject: 'field_select_option_value',
      matcher: {
        name: 'property_any_of',
        values: valueAsArray,
        property_id: property.id,
      },
    })
  } else if (isTextLikeFilterSubject(subject)) {
    emit('update', {
      subject,
      matcher: {
        name: 'property_contains_any_of',
        values: valueAsArray,
        property_id: property.id,
        case_sensitive: false,
      },
    })
  } else if (subject === 'field_number_value') {
    emit('update', {
      subject: 'field_number_value',
      matcher: {
        name: 'property_any_of',
        values: valueAsArray,
        property_id: property.id,
      },
    })
  } else {
    exhaustiveGuard(subject, 'Unhandled filter subject')
  }
}

const activeFilterValueDialog = ref<'text' | 'number' | null>(null)

const onUpdateFilter = (filter: TextLikeFilter | NumberValueFilter) => {
  if (!selectedProperty.value) return
  emit('update', filter)
}

const onCloseValueDialog = () => {
  activeFilterValueDialog.value = null
}

const onChangeSelectValues = (values: string[]) => {
  if (!selectedProperty.value) return

  const matcherName = props.filter.matcher.name
  assertIsValidSelectMatcher(matcherName)

  emit('update', {
    subject: 'field_select_option_value',
    matcher: {
      name: matcherName,
      values,
      property_id: selectedProperty.value.id,
    },
  })
}

const onOpenValueDialog = (subject: TextLikeFilter['subject'] | NumberValueFilter['subject']) => {
  if (subject === 'field_number_value') {
    activeFilterValueDialog.value = 'number'
  } else {
    activeFilterValueDialog.value = 'text'
  }
}
</script>

<template>
  <ModelInputMenuFiltersFilterTemplate
    :properties="properties"
    :selected-property="selectedProperty"
    :selected-matcher="filter.matcher.name"
    @update:property="onCreateFilter($event)"
    @update:matcher="onNewMatcher"
  >
    <template #value>
      <FilterItemSelectValuePicker
        v-if="
          filter.subject === 'field_select_option_value' &&
          selectedProperty &&
          isSelectProperty(selectedProperty)
        "
        :property="selectedProperty"
        :filter="filter"
        :positioning="{ placement: 'bottom-start' }"
        class="size-full hover:!bg-background-gray-subtlest-hovered focus:outline-none"
        :teleport-to="`#${FILTER_MENU_ID}`"
        aria-label="Edit Filter Value"
        @values="onChangeSelectValues"
      />
      <button
        v-if="isTextLikeFilter(filter) || filter.subject === 'field_number_value'"
        class="flex w-full cursor-pointer items-center gap-0.5 px-1.5 py-1 text-sm-12px-default text-text-subtle hover:bg-background-transparent-hovered focus:outline-none active:bg-background-transparent-pressed"
        :class="{ 'bg-background-transparent-hovered': activeFilterValueDialog }"
        aria-label="Edit Filter Value"
        @click="onOpenValueDialog(filter.subject)"
      >
        {{ getFilterDisplayValue(filter) }}
      </button>
    </template>
  </ModelInputMenuFiltersFilterTemplate>
  <FilterItemTextValue
    v-if="selectedProperty && isTextLikeFilter(filter)"
    :is-open="activeFilterValueDialog === 'text'"
    :property-name="selectedProperty.name"
    :filter="filter"
    @update="onUpdateFilter"
    @close="onCloseValueDialog"
  />
  <FilterItemNumberValue
    v-if="selectedProperty && filter.subject === 'field_number_value'"
    :is-open="activeFilterValueDialog === 'number'"
    :property-name="selectedProperty.name"
    :filter="filter"
    @update="onUpdateFilter"
    @close="onCloseValueDialog"
  />
</template>
