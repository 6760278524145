import { getProject } from '@/backend/getProject'
import { removeProperty } from '@/backend/removeProperty'
import { updateProperty, type PropertyConfig } from '@/backend/updateProperty'
import { updateView } from '@/backend/updateView'
import { useBilling } from '@/modules/Billing/useBilling'
import { useSubProject } from './useSubProject'

import { PropertyTool, type UpdatePropertyInput } from '@/backend/types'
import type { Property } from '@/modules/Project/Properties/types'
import { toast } from '@/shared/toast'
import { assertIsNotNullOrUndefined, invariant } from '@/shared/utils/typeAssertions'
import { isCollectionProperty, isEmptyObject, isSelectProperty } from '@/shared/utils/typeGuards'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import { ref, watch } from 'vue'
import { useLimitedAction } from '../Billing/useLimitedAction'
import { useLibraryStore } from '../Library/libraryStore'
import { serializeProperty, useProject } from './useProject'
import { useProperty } from './useProperty'

export const usePropertySidebarIntegration = () => {
  const projectStore = useProject()
  const propertyStore = useProperty()
  const libraryStore = useLibraryStore()
  const { workspaceId, projectId } = useRouteParams()

  const subProjectStore = useSubProject()

  const isPropertyBusy = ref(false)

  const deselectProperty = () => {
    projectStore.setSelectedProperty(null)
    propertyStore.reset()
  }

  const { addProperty } = useLimitedAction()
  const createProperty = async (property: Property<'reference'>) => {
    assertIsNotNullOrUndefined(
      property.config.projectId,
      'Cannot create unconfigured reference property',
    )
    return addProperty(workspaceId.value, projectId.value, {
      description: property.description,
      inputIds: property.inputs.map((i) => i.propertyId),
      isGrounded: property.isGrounded,
      name: property.name,
      tool: property.tool,
      type: property.type,
      projectId: property.config.projectId,
      entityLimit: property.config.entityLimit,
    })
  }

  const { captureAnalyticsEvent } = useAnalytics()
  const saveProperty = async (savedFrom: 'menu' | 'sidebar') => {
    const updatedProperty = propertyStore.editedProperty
    invariant(updatedProperty, 'No property to save')

    const config = getPropertyUpdateConfig()
    const { name, type, isGrounded, tool, description, inputs } = updatedProperty
    const property = propertyStore.savedProperty
    if (!property) {
      return false
    }

    isPropertyBusy.value = true

    const result = await updateProperty(workspaceId.value, projectId.value, property.id, {
      name,
      type,
      tool,
      isGrounded,
      description: description?.trim() === '' ? null : description,
      config,
      inputs:
        tool === PropertyTool.manual
          ? []
          : inputs.map<UpdatePropertyInput>(({ propertyId, entityId, filters, viaPropertyId }) => ({
              entity_id: entityId || undefined,
              property_id: propertyId,
              entities_filter: isEmptyObject(filters) ? undefined : filters || undefined,
              via_property_id: viaPropertyId,
            })),
    })

    if (result.ok) {
      const deletedLibraryInputs = property.inputs.filter(
        ({ propertyId, entityId }) =>
          entityId &&
          !inputs.some((input) => input.propertyId === propertyId && input.entityId === entityId),
      )
      deletedLibraryInputs.forEach(({ entityId }) => {
        if (!entityId) {
          return
        }
        libraryStore.deleteInputReference({
          itemId: entityId,
          projectId: projectId.value,
          propertyId: property.id,
        })
      })

      inputs
        .filter(
          ({ propertyId, entityId }) =>
            entityId &&
            !property.inputs.some(
              (input) => input.propertyId === propertyId && input.entityId === entityId,
            ),
        )
        .forEach(({ entityId }) => {
          if (!entityId) {
            return
          }

          libraryStore.addInputReference({
            itemId: entityId,
            projectId: projectId.value,
            propertyId: property.id,
          })
        })

      projectStore.upsertProperty(serializeProperty(result.data))
      propertyStore.reset()

      const hasUpdatedSubProjectProperties =
        (config?.upsertCollectionProperties?.length ?? 0) +
          (config?.removeCollectionProperties?.length ?? 0) >
        0
      if (
        hasUpdatedSubProjectProperties &&
        'config' in property &&
        property.config &&
        'subprojectConfig' in property.config
      ) {
        // we need to refetch the subproject properties to update the UI, or stale properties appear in MentionableTextInput
        // see GO-2248 for more
        const result = await getProject(
          workspaceId.value,
          property.config.subprojectConfig.child_project_id,
        )
        if (result.ok) {
          if (result.data.parent_property === null) {
            throw Error('Subproject must have a parent property')
          }
          subProjectStore.setProject(property.config.subprojectConfig.child_project_id, {
            id: result.data.id,
            name: result.data.name,
            workspaceId: result.data.workspace_id,
            properties: result.data.properties.map(serializeProperty),
            parentPropertyId: result.data.parent_property.id,
            updatedAt: result.data.updated_at,
          })
        }
      }

      captureAnalyticsEvent(ANALYTICS_EVENT.PROPERTY_UPDATED, {
        workspaceId: workspaceId.value,
        projectId: projectId.value,
        propertyId: property.id,
        savedFrom,
      })

      if (tool !== PropertyTool.manual) {
        captureAnalyticsEvent(ANALYTICS_EVENT.AI_PROPERTY_CREATED)
      }
      if (description && inputs.length > 0) {
        captureAnalyticsEvent(ANALYTICS_EVENT.AI_PROMPT_AND_INPUT_SAVED)
      }

      const advancedTypes: Array<typeof type> = [
        'url',
        'json',
        'single_select',
        'multi_select',
        'collection',
      ]
      if (advancedTypes.includes(type)) {
        captureAnalyticsEvent(ANALYTICS_EVENT.ADVANCED_PROPERTY_SUCCESS, {
          type,
        })
      }
    } else if (!result.ok) {
      toast.error(result.error.message)
      return false
    }

    isPropertyBusy.value = false
    return true
  }

  const deletePropertyConfirmationOpen = ref(false)

  const billingStore = useBilling()
  const deleteProperty = async () => {
    if (!projectStore.selectedPropertyId) {
      return
    }
    isPropertyBusy.value = true
    const propertyId = projectStore.selectedPropertyId

    const isOptimistic = propertyStore.editedProperty?.isOptimistic

    const result = isOptimistic
      ? { ok: true } // optimistic properties are not stored in the backend yet
      : await removeProperty(workspaceId.value, projectId.value, propertyId)

    if (result.ok) {
      if (billingStore.fieldUsage && !isOptimistic) {
        billingStore.fieldUsage.limitUsage -= projectStore.mainView?.entities?.length ?? 0
      }

      projectStore.setSelectedProperty(propertyId)
      projectStore.removeProperty(propertyId)
    }
    isPropertyBusy.value = false
    deletePropertyConfirmationOpen.value = false
  }

  const reprocessColumnConfirmationOpen = ref(false)

  const { recalculateEntities } = useLimitedAction()
  const reprocessColumn = async () => {
    if (!projectStore.selectedPropertyId) {
      return
    }

    isPropertyBusy.value = true

    const result = await recalculateEntities(workspaceId.value, projectId.value, [
      projectStore.selectedPropertyId,
    ])

    isPropertyBusy.value = false

    if (result.ok) {
      if (result.data.affected_count === 0) {
        toast.info('No stale fields found')
      } else {
        toast.info(`Recomputing ${result.data.affected_count} fields`)
      }
    }

    reprocessColumnConfirmationOpen.value = false
  }

  const hideProperty = async () => {
    if (projectStore.activeView) {
      await updateView({
        workspaceId: workspaceId.value,
        projectId: projectId.value,
        viewId: projectStore.activeView.id,
        name: projectStore.activeView.view.name,
        propertyIds:
          projectStore.activeView.view.propertyIds?.filter(
            (id) => id !== projectStore.selectedPropertyId,
          ) ?? [],
        propertyLayouts: projectStore.activeView.view.propertyLayouts,
        filters: projectStore.activeView.view.filters,
        propertyOptions: projectStore.activeView.view.propertyOptions ?? [],
        assignablePropertyId: projectStore.activeView.view.assignablePropertyId,
      })
      deselectProperty()
    }
  }

  watch(
    () => projectStore.selectedPropertyId,
    (newValue, oldValue) => {
      if (newValue !== oldValue) propertyStore.reset()
    },
    { immediate: true },
  )

  return {
    deleteProperty,
    deletePropertyConfirmationOpen,
    deselectProperty,
    hideProperty,
    isPropertyBusy,
    reprocessColumn,
    reprocessColumnConfirmationOpen,
    saveProperty,
    createProperty,
  }
}

function getPropertyUpdateConfig(): PropertyConfig {
  const store = useProperty()
  const property = store.editedProperty
  invariant(property, 'No property to update')

  const config: PropertyConfig = {}

  if (isSelectProperty(property)) {
    config.defaultOption = property.config.defaultOption
    config.removeOptions = store.removeOptions
    // skip empty value upsertOptions, thus the response will overwrite the existing options and they
    // will be removed from the UI too, otherwise we fall into the trap of empty options
    // and validation needs to be added
    config.upsertOptions = store.upsertOptions?.filter((option) => {
      return option.value.trim() !== '' && option.new_value?.trim() !== ''
    })
  }

  if (isCollectionProperty(property)) {
    config.upsertCollectionProperties = store.upsertCollectionProperties
    config.removeCollectionProperties = store.removeCollectionProperties
  }

  if (property.type === 'reference') {
    config.entityLimit = property.config.entityLimit
  }

  if (property.type === 'multi_select') {
    config.maxSelected = property.config.maxSelected
  }

  if (property.type === 'number') {
    config.numberConfig = {
      format: property.config.isCustomFormat ? 'custom' : 'auto',
      format_custom: {
        decimal_places: property.config.format.decimalPlaces,
        negative_format: property.config.format.negativeFormat,
        right_align: property.config.format.rightAlign,
        thousand_separator: property.config.format.thousandsSeparator ? 'comma' : 'none',
      },
    }
  }

  return config
}
