import type { View } from './useProject'

export const getSelectPropertyOptionColor = (
  property: SingleSelectProperty | MultiSelectProperty | UserSelectProperty,
  optionValue: string,
) => {
  return property.config.options.find((option) => option.value === optionValue)?.color
}

export const getViewColors = (view: View, properties: Property[]) => {
  return view.filters.flatMap((filter) => {
    const property = properties.find((property) => property.id === filter.property_id)
    if (!isSelectProperty(property)) return []

    return getSelectPropertyOptionColor(property, filter.select_option_value)
  })
}

export const getViewColor = (view: View, properties: Property[]) => {
  const colors = getViewColors(view, properties)

  if (colors.length === 1) {
    return colors[0]
  }

  return 'rainbow-17'
}

import type { PropertyType } from '@/backend/types'
import PropertyTypeTooltipCollection from '@/illustrations/property-type-tooltip-collection.svg'
import PropertyTypeTooltipFileCollection from '@/illustrations/property-type-tooltip-file-collection.svg'
import PropertyTypeTooltipFile from '@/illustrations/property-type-tooltip-file.svg'
import PropertyTypeTooltipJson from '@/illustrations/property-type-tooltip-json.svg'
import PropertyTypeTooltipMultiSelect from '@/illustrations/property-type-tooltip-multi-select.svg'
import PropertyTypeTooltipNumber from '@/illustrations/property-type-tooltip-number.svg'
import PropertyTypeTooltipPdf from '@/illustrations/property-type-tooltip-pdf.svg'
import PropertyTypeTooltipReference from '@/illustrations/property-type-tooltip-reference.svg'
import PropertyTypeTooltipSingleSelect from '@/illustrations/property-type-tooltip-single-select.svg'
import PropertyTypeTooltipText from '@/illustrations/property-type-tooltip-text.svg'
import PropertyTypeTooltipUrl from '@/illustrations/property-type-tooltip-url.svg'
import PropertyTypeTooltipUserSelect from '@/illustrations/property-type-tooltip-user-select.svg'

import type {
  BaseProperty,
  MultiSelectProperty,
  Property,
  SingleSelectProperty,
  UserSelectProperty,
} from '@/modules/Project/Properties/types'
import { isSelectProperty } from '@/shared/utils/typeGuards'
import type { Project } from '../Projects/useProjects'
import { FIELD_DEFAULT_TOOL } from '../WorkspaceSettings/propertyConfig'

export const PROPERTY_TYPE_TOOLTIP_DATA: Record<
  PropertyType,
  { illustrationComponent: string; description: string }
> = {
  file: {
    illustrationComponent: PropertyTypeTooltipFile,
    description:
      'Upload and manage large files, from PDFs to CSVs. Use files as inputs for AI models.',
  },
  text: {
    illustrationComponent: PropertyTypeTooltipText,
    description:
      'Generate freeform text, perfect for tasks like summarization and open ended analysis.',
  },
  single_select: {
    illustrationComponent: PropertyTypeTooltipSingleSelect,
    description:
      'Limit model outputs to a single choice from a set list. Ideal for classification tasks.',
  },
  multi_select: {
    illustrationComponent: PropertyTypeTooltipMultiSelect,
    description:
      'Allow model outputs to include multiple choices from a set list. Ideal for classification tasks requiring more than one output.',
  },
  user_select: {
    illustrationComponent: PropertyTypeTooltipUserSelect,
    description:
      'Assign users to entities for human-in-the-loop workflows, ensuring human oversight in tasks requiring review.',
  },
  json: {
    illustrationComponent: PropertyTypeTooltipJson,
    description: 'Output data in JSON format. Perfect for tasks that need a structured output.',
  },
  pdf: {
    illustrationComponent: PropertyTypeTooltipPdf,
    description:
      'The PDF type is specifically designed for handling large PDF documents. It supports viewing, processing, and extracting information from large PDF files within your project.',
  },
  collection: {
    illustrationComponent: PropertyTypeTooltipCollection,
    description:
      'Convert inputs into a structured table of sub-entities, enabling deeper levels of analysis. Ideal for tasks requiring one level deeper analysis, with a structured output.',
  },
  file_collection: {
    illustrationComponent: PropertyTypeTooltipFileCollection,
    description:
      'Split files into a collection of individual pages or sheets. Now supports both PDFs and Excel files.',
  },
  url: {
    illustrationComponent: PropertyTypeTooltipUrl,
    description:
      'The URL tool extracts data from web pages, enabling you to use URLs as inputs for AI models.',
  },
  reference: {
    illustrationComponent: PropertyTypeTooltipReference,
    description:
      'Connect projects and mention other project properties. Useful for connecting properties across your projects.',
  },
  number: {
    illustrationComponent: PropertyTypeTooltipNumber,
    description: 'Work with numeric data such as integers, percentages and currency values.',
  },
}

// All optimistic properties can be created with these defaults.
const optimisticPropertyBase: Omit<BaseProperty, 'id' | 'slug' | 'tool'> = {
  name: 'New property',
  isGrounded: false,
  description: '',
  inputs: [],
  hash: '',
  owner: 'user',
  isOptimistic: true,
}
/**
 * Maps each property type to an object that is *nearly* a full property object.
 * The only missing fields are `id` and `slug`, which must be unique to each property.
 */
export const optimisticPropertyMap: {
  [Type in Property['type']]: Omit<Property<Type>, 'id' | 'slug'>
} = {
  reference: {
    ...optimisticPropertyBase,
    tool: FIELD_DEFAULT_TOOL.reference,
    type: 'reference',
    config: {
      entityLimit: 1,
      projectId: '',
    },
  },
  text: {
    ...optimisticPropertyBase,
    tool: FIELD_DEFAULT_TOOL.text,
    type: 'text',
  },
  collection: {
    ...optimisticPropertyBase,
    config: {
      properties: [],
      subprojectConfig: {
        child_project_id: '',
        primary_property_id: '',
      },
    },
    tool: FIELD_DEFAULT_TOOL.collection,
    type: 'collection',
  },
  file: {
    ...optimisticPropertyBase,
    tool: FIELD_DEFAULT_TOOL.file,
    type: 'file',
  },
  file_collection: {
    ...optimisticPropertyBase,
    tool: FIELD_DEFAULT_TOOL.file_collection,
    type: 'file_collection',
    config: {
      properties: [],
      subprojectConfig: {
        child_project_id: '',
        primary_property_id: '',
      },
    },
  },
  json: {
    ...optimisticPropertyBase,
    tool: FIELD_DEFAULT_TOOL.json,
    type: 'json',
  },
  multi_select: {
    ...optimisticPropertyBase,
    tool: FIELD_DEFAULT_TOOL.multi_select,
    type: 'multi_select',
    config: {
      options: [],
      defaultOption: null,
    },
  },
  pdf: {
    ...optimisticPropertyBase,
    tool: FIELD_DEFAULT_TOOL.pdf,
    type: 'pdf',
    config: {
      splitter: 'page_split',
      subprojectConfig: {
        child_project_id: '',
        primary_property_id: '',
      },
    },
  },
  single_select: {
    ...optimisticPropertyBase,
    tool: FIELD_DEFAULT_TOOL.single_select,
    type: 'single_select',
    config: {
      options: [],
      defaultOption: null,
    },
  },
  url: {
    ...optimisticPropertyBase,
    tool: FIELD_DEFAULT_TOOL.url,
    type: 'url',
  },
  user_select: {
    ...optimisticPropertyBase,
    tool: FIELD_DEFAULT_TOOL.user_select,
    type: 'user_select',
    config: {
      options: [],
      defaultOption: null,
    },
  },
  number: {
    ...optimisticPropertyBase,
    tool: FIELD_DEFAULT_TOOL.number,
    type: 'number',
    config: {
      isCustomFormat: false,
      format: {
        decimalPlaces: 'auto',
        negativeFormat: 'minus',
        rightAlign: false,
        thousandsSeparator: true,
      },
    },
  },
}

/**
 * Will return the URL for a project's cover image, or null if the image is not available.
 */
export const getCoverImageUrl = (
  project: Project,
  quality: keyof Project['coverImageUrls'],
): string | null => {
  if (project.coverImageDownloadError) {
    return null
  }

  return project.coverImageUrls[quality]
}
