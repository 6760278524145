<script setup lang="ts">
import { captureException } from '@sentry/vue'
import type { EditorProps } from 'prosemirror-view'
import { useTemplateRef } from 'vue'
import ProseMirrorMention, { getMentionContainer } from '../MarkdownEditor/ProseMirrorMention.vue'
import type { ProseMirrorProps } from '../ProseMirror/ProseMirror.vue'
import ProseMirror from '../ProseMirror/ProseMirror.vue'
import { parser, schema, serializer } from './utils'

/**
 * Renders a text editor component with support for mentions, used to tag other properties or
 * library items in a tool prompt.
 */

type Props = Omit<ProseMirrorProps, 'mode' | 'toolbar'>
defineProps<Props>()

const editor = useTemplateRef('editor')

const editorOverrides: EditorProps = {
  /**
   * Use our own serializer to convert the selected nodes to text, so that mentions are
   * serialized as @<P${id}>.
   */
  clipboardTextSerializer: (slice) => {
    const paragraphs = slice.content.content.map(serializer.serialize)
    return paragraphs.join('\n\n')
  },

  /**
   * When pasting into the editor, we want to preserve indentation. To do this, we pass
   * the pasted text through our parser, which will
   */
  handlePaste: (view, event) => {
    const clipboardData = event.clipboardData
    if (!clipboardData) return false

    const text = clipboardData.getData('text/plain')
    if (!text) return false

    const docNode = parser.parse(text)
    const tr = view?.state.tr.replaceSelectionWith(docNode)
    view.dispatch(tr)

    if (!editor.value) {
      captureException(new Error('No editor when pasting into prompt'))
      return false
    }

    editor.value.rerenderEditorView()
    return true
  },
}
</script>

<template>
  <ProseMirror
    ref="editor"
    v-bind="{ ...$attrs, ...$props }"
    :mode="{
      schema,
      parser,
      serializer,
      nodeViewOptions: [
        {
          name: 'mention',
          component: ProseMirrorMention,
          as: getMentionContainer,
        },
      ],
    }"
    :editor-props="editorOverrides"
  />
</template>
