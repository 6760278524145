<script setup lang="ts">
import type { ResourceRole } from '@/backend/types'
import IconSprite from '@/uiKit/IconSprite.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import Select from '@/uiKit/Select'
import type { SelectItem } from '@/uiKit/Select/Select.vue'
import type { OffsetOptions } from '@floating-ui/vue'
import { computed } from 'vue'
import { FeatureFlag } from '../App/featureFlags'
import { useFeatureFlags } from '../App/useFeatureFlags'
import { roleLabelMap } from '../IdentityAndAccess/roleConfig'

type AllowedRole = Extract<ResourceRole, 'admin' | 'editor' | 'worker'>

const props = defineProps<{
  /** The selected role */
  value: AllowedRole
  /** floating-ui offset options to apply to the dropdown menu */
  offset?: OffsetOptions
  /** All roles that the user is able to invite/update */
  allowedRoles: AllowedRole[]
}>()

const emit = defineEmits<{
  (e: 'change', value: AllowedRole): void
}>()

const hasWorkerRole = useFeatureFlags(FeatureFlag.WORK_ASSIGNENT)

type Option = SelectItem & {
  description: string
  value: AllowedRole
}

const OPTIONS = computed(() => {
  return (
    [
      props.allowedRoles.includes('admin') && {
        value: 'admin',
        label: roleLabelMap['admin'],
        description: 'Has full access to view, edit, and share all projects within the workspace.',
      },
      props.allowedRoles.includes('editor') && {
        value: 'editor',
        label: roleLabelMap['editor'],
        description:
          'Can view and edit their own projects or those they’ve been invited to collaborate on.',
      },
      hasWorkerRole.value &&
        props.allowedRoles.includes('worker') && {
          value: 'worker',
          label: roleLabelMap['worker'],
          description: 'Can view only projects where they have assigned work.',
        },
    ] as Array<Option | boolean>
  ).filter((i): i is Option => !!i) satisfies Option[]
})
</script>

<template>
  <Select
    :items="OPTIONS"
    :value="value"
    @change="emit('change', $event)"
  >
    <template #trigger="slotProps">
      <slot
        name="trigger"
        v-bind="slotProps"
      />
    </template>
    <template #item="{ item, api }">
      <ListMenuItem
        class="max-w-80"
        :active="api.highlightedValue === item.value"
        :role="null"
      >
        <template #prefix>
          <IconSprite
            :icon="value === item.value ? 'check' : 'blank'"
            class="mr-1 size-5 text-icon-subtle"
          />
        </template>
        <div>
          <div class="text-sm-12px-default text-text">{{ item.label }}</div>
          <div class="text-sm-12px-light text-text-subtle">{{ item.description }}</div>
        </div>
      </ListMenuItem>
    </template>
  </Select>
</template>
