<script setup lang="ts">
import { parseMatcherName } from '@/modules/Project/useFilters'
import IconSprite from '@/uiKit/IconSprite.vue'
import Menu from '@/uiKit/Menu'
import type { Context as MenuContext } from '@zag-js/menu'
import { computed, ref } from 'vue'
import {
  getFilterSubjectForProperty,
  hasTextLikeFilter,
  type FilterablePropertyType,
  type MatcherName,
  type SimpleFilter,
} from './types'

export type ID = Extract<
  MatcherName,
  | 'property_any_of'
  | 'property_all_of'
  | 'property_none_of'
  | 'property_not_set'
  | 'property_contains_any_of'
  | 'property_contains_none_of'
  | 'property_less_than_or_equal_to'
  | 'property_less_than'
  | 'property_greater_than_or_equal_to'
  | 'property_greater_than'
>
const props = defineProps<{
  matcherName: SimpleFilter['matcher']['name'] | 'property_not_set'
  values: string | string[]
  type: FilterablePropertyType
  /** The total number of options this property has */
  optionCount?: number
  positioning?: MenuContext['positioning']
  teleportTo?: string
  disabled?: boolean
}>()

defineEmits<{
  (e: 'match', matchId: ID): void
}>()

const IDS = {
  matchAnyOf: 'property_any_of',
  matchAllOf: 'property_all_of',
  matchNoneOf: 'property_none_of',
  matchNotSet: 'property_not_set',
  matchContainsAnyOf: 'property_contains_any_of',
  matchContainsNoneOf: 'property_contains_none_of',
  matchLessThanOrEqualTo: 'property_less_than_or_equal_to',
  matchLessThan: 'property_less_than',
  matchGreaterThanOrEqualTo: 'property_greater_than_or_equal_to',
  matchGreaterThan: 'property_greater_than',
} as const

const amount = computed(() => {
  if (typeof props.values === 'string') return 1
  return props.values.length
})

/**
 * 'property_not_set' is not a valid backend filter matcher, so this is where we
 * swap out the 'is_none_of <all_options>' matcher for the user friendly 'is not set'
 * option.
 */
const currentFilterName = computed(() => {
  if (props.matcherName === 'property_none_of' && amount.value === props.optionCount) {
    return IDS.matchNotSet
  }

  return props.matcherName
})

const matchers = computed<ID[]>(() => {
  const returnArray: ID[] = []
  if (hasTextLikeFilter(props.type)) {
    returnArray.push('property_contains_any_of', 'property_contains_none_of')
  }

  if (
    hasTextLikeFilter(props.type) ||
    getFilterSubjectForProperty(props.type) === 'field_select_option_value'
  ) {
    returnArray.push('property_any_of', 'property_none_of')
  }

  if (getFilterSubjectForProperty(props.type) === 'field_select_option_value') {
    returnArray.push('property_not_set')
  }

  if (props.type === 'multi_select') {
    returnArray.push('property_all_of')
  }

  if (props.type === 'number') {
    returnArray.push(
      'property_less_than_or_equal_to',
      'property_less_than',
      'property_greater_than_or_equal_to',
      'property_greater_than',
      'property_any_of',
      'property_none_of',
    )
  }

  return returnArray
})

const isOpen = ref(false)
</script>

<template>
  <Menu.Root
    v-slot="{ getTriggerProps }"
    :positioning="positioning ?? { placement: 'top-start' }"
    :teleport-to="teleportTo"
    :open="isOpen"
    close-on-select
    @change:open="isOpen = $event"
  >
    <button
      v-bind="{ ...getTriggerProps(), ...$attrs }"
      class="flex cursor-pointer items-center gap-0.5 overflow-hidden text-nowrap px-1.5 py-1 text-sm-12px-default hover:bg-background-transparent-hovered"
      :class="{
        'bg-background-transparent-hovered': isOpen,
        'text-text-disabled': disabled,
        'text-text-subtle active:bg-background-transparent-pressed': !disabled,
      }"
      :disabled="disabled"
    >
      {{
        parseMatcherName({
          matcherName: currentFilterName,
          amount,
          type,
        })
      }}
    </button>
    <Menu.Content class="min-w-[242px]">
      <Menu.Item
        v-for="matcher in matchers"
        :key="matcher"
        :label="parseMatcherName({ matcherName: matcher, amount, type })"
        @select="$emit('match', matcher)"
      >
        <template #prefix>
          <IconSprite
            :icon="currentFilterName === matcher ? 'check' : 'blank'"
            class="mr-1 text-icon-subtle"
          />
        </template>
      </Menu.Item>
    </Menu.Content>
  </Menu.Root>
</template>
