<script setup lang="ts">
import { useLibraryStore, type LibraryItem } from '@/modules/Library/libraryStore'
import { useLibraryBackend } from '@/modules/Library/useLibraryBackend'
import FilePlaceholder from '@/modules/Workspaces/KnowledgeHub/Files/FilePlaceholder.vue'
import { useCurrentWorkspace } from '@/modules/Workspaces/useCurrentWorkspace'
import CircularProgress from '@/uiKit/CircularProgress.vue'
import { whenever } from '@vueuse/core'
import { computed, nextTick, ref } from 'vue'

const props = defineProps<{
  item: LibraryItem
}>()

const isRenaming = defineModel<boolean>('isRenaming')
const newName = ref('')
const renameInput = ref<HTMLInputElement | null>(null)
const { renameItem } = useLibraryBackend()
const workspace = useCurrentWorkspace()
const libraryStore = useLibraryStore()

whenever(
  isRenaming,
  async () => {
    newName.value = props.item.name
    await nextTick()
    renameInput.value?.focus()
    renameInput.value?.select()
  },
  { immediate: true },
)

const progress = computed(() => libraryStore.uploadProgressMap[props.item.id])
const isIncomplete = computed(() => props.item.status !== 'complete')

function submitName() {
  const nextName = newName.value.trim()
  isRenaming.value = false
  renameItem({ id: props.item.id, newName: nextName, workspaceId: workspace.value.id })
}

function onRenameClick() {
  if (isIncomplete.value) return
  isRenaming.value = true
}
</script>

<template>
  <div class="size-5 shrink-0">
    <CircularProgress
      v-if="isIncomplete"
      :value="progress"
      size="xs"
    />
    <FilePlaceholder
      v-else
      :item="item"
      class="h-full w-auto max-w-4 rounded-sm border border-border-subtle"
    />
  </div>

  <div
    class="min-w-1/2 shrink cursor-text truncate"
    :class="{ 'text-text-disabled': isIncomplete }"
    @click="onRenameClick"
  >
    <!-- 👆 min-w exists  so that there's still enough space to click if the name is short -->
    <template v-if="isRenaming">
      <input
        ref="renameInput"
        v-model="newName"
        class="w-full bg-background-transparent outline-none"
        @click.stop
        @blur="submitName"
        @keydown.enter="submitName"
        @keydown.esc="isRenaming = false"
      />
    </template>
    <template v-else>
      {{ item.name }}
    </template>
  </div>
</template>
