<template>
  <div class="flex items-center justify-between">
    <div class="flex h-10 grow">
      <slot name="title" />
    </div>
    <div class="flex">
      <slot name="actions" />
    </div>
  </div>
</template>
