import ProjectSidebar from '@/modules/Projects/ProjectSidebar.vue'
import KnowledgeHubFiles from '@/modules/Workspaces/KnowledgeHub/Files/KnowledgeHubFiles.vue'
import KnowledgeHubIntro from '@/modules/Workspaces/KnowledgeHub/Intro/KnowledgeHubIntro.vue'
import KnowledgeHubHome from '@/modules/Workspaces/KnowledgeHub/KnowledgeHubHome.vue'
import TopBar from '@/modules/Workspaces/KnowledgeHub/Layout/NavigationBar.vue'
import { showKnowledgeHubIntro } from '@/modules/Workspaces/KnowledgeHub/preferences'
import type { RouteLocationNormalizedGeneric, RouteRecordRaw } from 'vue-router'

const components = {
  Sidebar: ProjectSidebar,
  BreadCrumbs: TopBar,
}

const props = {
  Sidebar: (route: RouteLocationNormalizedGeneric) => ({
    workspaceId: route.params.workspaceId,
    open: true,
  }),
  BreadCrumbs: {
    hideLinks: false,
  },
}

const knowledgeHubRoute: RouteRecordRaw = {
  path: 'knowledge-hub',
  name: 'KnowledgeHub',
  redirect() {
    if (showKnowledgeHubIntro.value) {
      return { name: 'KnowledgeHubIntro' }
    }
    return { name: 'Files' }
  },
  children: [
    {
      path: 'welcome',
      name: 'KnowledgeHubIntro',
      components: {
        ...components,
        default: KnowledgeHubIntro,
      },
      props: {
        ...props,
        BreadCrumbs: { hideLinks: true },
      },
    },
    {
      path: 'integrations',
      name: 'Integrations',
      components: {
        ...components,
        default: KnowledgeHubHome,
      },
      props: { ...props },
    },
    {
      path: 'files',
      name: 'Files',
      components: { ...components, default: KnowledgeHubFiles },
      props: { ...props },
    },
  ],
}

export const knowledgeHubRoutes: RouteRecordRaw[] = [knowledgeHubRoute]
