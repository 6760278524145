import { invariant } from '@/shared/utils/typeAssertions'

type PromiseQueueOptions = {
  maxConcurrent?: number
}

/**
 * Executes an array of promises in a queue with a maximum number of concurrent executions
 * @param promises Array of promises to execute
 * @param options Configuration options
 * @returns Promise that resolves when all promises are complete
 */
export async function promiseQueue<T>(
  promises: Array<() => Promise<T>>,
  options: PromiseQueueOptions = {},
): Promise<T[]> {
  const maxConcurrent = options.maxConcurrent ?? 4
  const results: T[] = []
  const inProgress = new Set<Promise<void>>()
  const promisesToProcess = [...promises]

  async function executeNext(): Promise<void> {
    if (promisesToProcess.length === 0) return

    const promise = promisesToProcess.shift()
    invariant(promise)

    const execution = promise().then((result) => {
      results.push(result)
      inProgress.delete(execution)
      return executeNext()
    })

    inProgress.add(execution)
    if (inProgress.size < maxConcurrent && promisesToProcess.length > 0) {
      await executeNext()
    }
  }

  // Start initial batch of promises
  await Promise.all(
    Array.from({ length: Math.min(maxConcurrent, promises.length) }, () => executeNext()),
  )

  return results
}
