<script setup lang="ts">
import LibraryDocument from '@/modules/Library/LibraryDocument.vue'
import type { LibraryItem } from '@/modules/Library/libraryStore'
import { useLibraryBackend } from '@/modules/Library/useLibraryBackend'
import { useLibraryDocument } from '@/modules/Library/useLibraryDocument'
import { useFileTableContext } from '@/modules/Workspaces/KnowledgeHub/Files/context'
import FileLink from '@/modules/Workspaces/KnowledgeHub/Files/FileLink.vue'
import { useCurrentWorkspace } from '@/modules/Workspaces/useCurrentWorkspace'
import IconButton from '@/uiKit/IconButton.vue'
import Menu from '@/uiKit/Menu'
import { computed } from 'vue'

const props = defineProps<{
  item: LibraryItem
}>()

defineEmits<{
  rename: []
  menuOpen: [boolean]
}>()

const workspace = useCurrentWorkspace()
const { deleteItem } = useLibraryBackend()
const { isEditingLibraryDocument, onSaveDocument, activeItem, onEditDocument, onClose } =
  useLibraryDocument()

const selection = useFileTableContext()
const isSelected = computed(() => selection.isSelected(props.item.id))

function onDocumentSave(event: { content: string; title: string }) {
  onSaveDocument({ content: event.content, title: event.title, workspaceId: workspace.value.id })
}

function onDelete() {
  deleteItem({ itemId: props.item.id, workspaceId: workspace.value.id })
}
</script>

<template>
  <Menu.Root
    v-slot="{ getTriggerProps }"
    :positioning="{ placement: 'bottom-end', offset: { mainAxis: 4 } }"
    close-on-select
    @change:open="$emit('menuOpen', $event)"
  >
    <IconButton
      v-bind="getTriggerProps()"
      icon="more-dots"
      size="md"
      variant="transparent"
    />
    <Menu.Content class="min-w-40">
      <!-- Open, Download -->
      <FileLink :item="item">
        <template #default="{ action }">
          <Menu.Item
            icon="expand"
            :label="action === 'download' ? 'Download' : 'Open'"
            @click="item.type === 'text' ? onEditDocument(item) : void 0"
          />
        </template>
      </FileLink>

      <!-- Rename -->
      <Menu.Item
        label="Rename"
        icon="edit"
        @click="$emit('rename')"
      />

      <Menu.Divider />
      <Menu.Item
        :icon="isSelected ? 'minus' : 'plus'"
        :label="isSelected ? 'Remove from selection' : 'Add to selection'"
        @click="selection.setSelected(item.id, !isSelected)"
      />
      <Menu.Divider />

      <!-- Delete -->
      <Menu.Item
        critical
        icon="trash"
        label="Delete"
        @click="onDelete"
      />
    </Menu.Content>
  </Menu.Root>

  <LibraryDocument
    :aria-label="`Edit ${activeItem?.name || ''}`"
    :open="isEditingLibraryDocument"
    :initial-title="activeItem?.name"
    :initial-content="activeItem?.content"
    @close="onClose"
    @save="onDocumentSave"
  />
</template>
