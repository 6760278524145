<script setup lang="ts">
import { removeView } from '@/backend/removeView'
import { updateView } from '@/backend/updateView'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import ConfirmationDialog from '@/uiKit/ConfirmationDialog.vue'
import DividerLine from '@/uiKit/DividerLine.vue'
import IconButton from '@/uiKit/IconButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import InlineTextField from '@/uiKit/InlineTextField.vue'
import Menu from '@/uiKit/Menu'
import { computed, ref, useTemplateRef, watch } from 'vue'
import { RouterLink, useRouter } from 'vue-router'
import { FeatureFlag } from '../App/featureFlags'
import { useFeatureFlags } from '../App/useFeatureFlags'
import { useRoutes } from '../App/useRoutes'
import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'
import { useProject, type View } from './useProject'
import { useProjectActions } from './useProjectActions'
import { useResolveProjectRoute } from './useResolveProjectRoute'
import { getViewColor } from './utils'

const props = defineProps<{ view: View }>()

const isConfirmingDelete = ref(false)
const isRenaming = ref(false)

const viewHasError = ref(false)
const localViewName = ref('')

const projectStore = useProject()
const projectActions = useProjectActions()
const router = useRouter()
const views = computed(() => projectStore.views)
const resolveProjectRoute = useResolveProjectRoute()

const { currentlyInEntityView, currentlyInSubProject, route } = useRoutes()
const { parentEntityId, parentProjectId, projectId, workspaceId, parentViewId } = useRouteParams()

const isViewActive = (view: View) => projectStore.activeView?.id == view.id

const routerLocation = (view: View) => {
  if (currentlyInEntityView.value && currentlyInSubProject.value) {
    return view.name === 'main'
      ? {
          name: 'WorkspaceSubProjectEntityView',
          params: {
            workspaceId: route.params.workspaceId,
            projectId: route.params.projectId,
            parentProjectId: route.params.parentProjectId,
            entityId: route.params.entityId,
          },
          query: {
            parentEntityId: route.query.parentEntityId,
            parentViewId: route.query.parentViewId,
          },
        }
      : {
          name: 'WorkspaceSubProjectEntitySubView',
          params: {
            workspaceId: route.params.workspaceId,
            projectId: route.params.projectId,
            parentProjectId: route.params.parentProjectId,
            entityId: route.params.entityId,
            viewId: view.id,
          },
          query: {
            parentEntityId: route.query.parentEntityId,
            parentViewId: route.query.parentViewId,
          },
        }
  }

  if (currentlyInEntityView.value) {
    return view.name === 'main'
      ? {
          name: 'WorkspaceProjectEntityView',
          params: {
            workspaceId: route.params.workspaceId,
            projectId: route.params.projectId,
            entityId: route.params.entityId,
          },
        }
      : {
          name: 'WorkspaceProjectEntitySubView',
          params: {
            workspaceId: route.params.workspaceId,
            projectId: route.params.projectId,
            entityId: route.params.entityId,
            viewId: view.id,
          },
        }
  }

  return resolveProjectRoute({
    parentEntityId: parentEntityId.value,
    workspaceId: workspaceId.value,
    parentProjectId: parentProjectId.value,
    projectId: projectId.value,
    viewId: view.name === 'main' ? undefined : view.id,
    parentViewId: parentViewId.value,
  })
}

const style = (view: View) => {
  const color = getViewColor(view, projectStore.properties)

  return {
    color:
      view.name === 'main' || color === 'rainbow-17'
        ? 'var(--color-icon-subtle)'
        : `var(--${color}-saturated)`,
  }
}

const updateLocalValue = (newValue: string) => {
  localViewName.value = newValue
  viewHasError.value = newValue.length < 3
}

const renameView = async (newName: string) => {
  viewHasError.value = false
  if (newName.length < 3) {
    viewHasError.value = true
    return
  }

  const result = await updateView({
    workspaceId: workspaceId.value,
    projectId: projectId.value,
    viewId: props.view.id,
    name: newName,
    propertyIds: props.view.propertyIds ?? [],
    propertyLayouts: props.view.propertyLayouts,
    filters: props.view.filters,
    propertyOptions: props.view.propertyOptions ?? [],
    assignablePropertyId: props.view.assignablePropertyId,
  })

  projectStore.upsertView({
    id: props.view.id,
    name: newName,
    propertyIds: props.view.propertyIds,
    propertyLayouts: props.view.propertyLayouts,
    filters: props.view.filters,
    propertyOptions: props.view.propertyOptions,
    assignablePropertyId: props.view.assignablePropertyId,
    numPinnedProperties: props.view.numPinnedProperties,
  })

  if (!result.ok) {
    viewHasError.value = true
    return
  }

  isRenaming.value = false
}

watch(
  () => isRenaming.value,
  (newValue) => {
    if (!newValue) return

    localViewName.value = props.view.name

    router.push(
      resolveProjectRoute({
        workspaceId: workspaceId.value,
        projectId: projectId.value,
        viewId: props.view.id,
      }),
    )
  },
)

const assignableSubmenu = ref()

const userSelectProperties = computed(() =>
  projectStore.properties
    .filter((p) => p.type === 'user_select')
    .map((p) => ({
      id: p.id,
      data: {
        label: p.name,
      },
    })),
)

const assignView = async (propertyId: string, viewId: string) => {
  const view = views.value.find((v) => v.id === viewId)

  if (!viewId || !view) {
    return
  }

  let result
  if (view.assignablePropertyId === propertyId) {
    result = await updateView({
      workspaceId: workspaceId.value,
      projectId: projectId.value,
      viewId: viewId,
      name: view.name,
      propertyIds: view.propertyIds ?? [],
      propertyLayouts: view.propertyLayouts,
      filters: view.filters,
      assignablePropertyId: null,
      propertyOptions: view.propertyOptions ?? [],
    })
  } else {
    result = await updateView({
      workspaceId: workspaceId.value,
      projectId: projectId.value,
      viewId: viewId,
      name: view.name,
      propertyIds: view.propertyIds ?? [],
      propertyLayouts: view.propertyLayouts,
      filters: view.filters,
      assignablePropertyId: propertyId,
      propertyOptions: view.propertyOptions ?? [],
    })
  }

  if (!result.ok) {
    return
  }

  projectStore.upsertView({
    id: viewId,
    name: view.name,
    propertyIds: view.propertyIds,
    propertyLayouts: view.propertyLayouts,
    filters: view.filters,
    assignablePropertyId: result.data.assignable_property_id,
    propertyOptions: view.propertyOptions,
    numPinnedProperties: view.numPinnedProperties,
  })
}

const assignedUserProperty = computed(() => {
  const userProperty = projectStore.properties.find((p) => p.id === props.view.assignablePropertyId)
  return userProperty
})

const isWorkAssignmentEnabled = useFeatureFlags(FeatureFlag.WORK_ASSIGNENT)

const permissionsStore = usePermissionsStore()

async function createUserSelectProperty(assignableViewId: string) {
  const newProperty = await projectActions.createProperty('user_select')
  if (newProperty) {
    assignView(newProperty.id, assignableViewId)
  }
}

const deleteView = async () => {
  const result = await removeView(workspaceId.value, projectId.value, props.view.id)
  if (result.ok) {
    projectStore.removeView(props.view.id)
    projectStore.setActiveViewId()

    router.push(
      resolveProjectRoute({
        parentEntityId: parentEntityId.value,
        workspaceId: workspaceId.value,
        parentProjectId: parentProjectId.value,
        projectId: projectId.value,
      }),
    )
  }
  isConfirmingDelete.value = false
}

const rootmenu = useTemplateRef('menu')
const assigmentSubmenu = useTemplateRef('submenu')
</script>

<template>
  <div class="flex h-7 items-center gap-0.5">
    <RouterLink
      :to="routerLocation(view)"
      :aria-label="`View to '${view.name}'`"
      class="group/tab relative"
      data-test="view-tab"
    >
      <div
        class="flex h-7 items-center whitespace-nowrap text-nowrap rounded-corner-8 px-1 text-center text-sm-12px-default"
        :class="[
          isViewActive(view)
            ? `${viewHasError ? 'bg-background-critical-subtle' : 'bg-surface-primary'} shadow-xs`
            : 'text-text-subtle',
          !isRenaming &&
            'hover:bg-background-transparent-hovered active:bg-background-transparent-pressed',
        ]"
      >
        <div class="flex items-center gap-1 px-1">
          <IconSprite
            icon="table"
            :style="style(view)"
          />

          <InlineTextField
            v-if="isRenaming"
            autofocus
            size="xs"
            :value="localViewName"
            :error="viewHasError"
            class="inline h-4 !bg-background-transparent"
            :input-attrs="{ size: Math.max(5, view.name.length).toString() }"
            @submit="renameView"
            @input="updateLocalValue"
            @blur="renameView(localViewName)"
            @esc="isRenaming = false"
          />
          <p
            v-else
            data-sentry="unmask"
            class="max-w-40 truncate"
          >
            {{ view.name === 'main' ? 'Main' : view.name }}
          </p>
        </div>

        <span
          v-if="view.entityCount"
          class="rounded-corner-6 bg-background-gray-subtlest px-1.5 py-0.5 text-center text-sm-12px-default text-text-subtle"
        >
          {{ view.entityCount }}
        </span>
      </div>

      <div
        v-if="!isRenaming && view.name !== 'main'"
        class="absolute inset-0 rounded-corner-8 bg-gradient-to-l from-[20px] to-background-transparent focus-within:opacity-100"
        :class="[
          isViewActive(view) ? 'from-surface-primary' : 'bg-background-gray-sunken-hovered',
          !isViewActive(view) &&
            permissionsStore.currentProjectPermissions.manage_views &&
            'from-surface-tertiary-persist',
          permissionsStore.currentProjectPermissions.manage_views && 'group-hover/tab:opacity-100',
          rootmenu?.menu.open ? 'opacity-100' : 'opacity-0',
        ]"
      >
        <Menu.Root
          v-if="permissionsStore.currentProjectPermissions.manage_views"
          ref="menu"
          v-slot="{ getTriggerProps, getTriggerItemProps }"
        >
          <IconButton
            v-if="view.name !== 'main'"
            size="xs"
            icon="chevron-bottom"
            variant="transparent"
            class="absolute right-1.5 top-1/2 -translate-y-1/2"
            v-bind="getTriggerProps()"
            aria-label="View options"
            @click.prevent
          />
          <Menu.Content class="w-[280px] min-w-max">
            <template v-if="isWorkAssignmentEnabled">
              <Menu.Root
                ref="submenu"
                v-slot="{ menu: subMenu }"
                close-on-select
                :positioning="{ offset: { mainAxis: 4, crossAxis: -2 } }"
              >
                <Menu.Item
                  label="Assignable via"
                  v-bind="assigmentSubmenu ? getTriggerItemProps(assigmentSubmenu!.menu) : {}"
                  @select="subMenu.setOpen(true)"
                  @mouseenter="subMenu.setOpen(true)"
                >
                  <template #suffix>
                    <div v-if="assignedUserProperty">{{ assignedUserProperty.name }}</div>
                    <IconSprite
                      class="text-icon-subtlest"
                      icon="chevron-right"
                    />
                  </template>
                </Menu.Item>
                <Menu.Content
                  ref="assignableSubmenu"
                  class="min-w-[240px]"
                  aria-label="Select a User property"
                >
                  <Menu.Item
                    v-if="userSelectProperties.length === 0"
                    @select="createUserSelectProperty(view.id)"
                  >
                    Create a new user selection property
                    <template #prefix>
                      <IconSprite icon="plus-fill" />
                    </template>
                  </Menu.Item>
                  <Menu.Item
                    v-for="item in userSelectProperties"
                    :key="item.id"
                    :label="item.data.label"
                    default-hover-disabled
                    icon="user"
                    @select="assignView(item.id, view.id)"
                  >
                    <template #prefix>
                      <IconSprite
                        :icon="item.id === view.assignablePropertyId ? 'check' : 'blank'"
                        class="mr-1 text-icon-subtle"
                      />
                    </template>
                  </Menu.Item>
                </Menu.Content>
              </Menu.Root>
              <Menu.Divider />
            </template>
            <Menu.Item
              label="Rename"
              icon="edit"
              @select="isRenaming = true"
            />
            <Menu.Divider v-if="!isWorkAssignmentEnabled" />
            <Menu.Item
              critical
              label="Delete view"
              icon="trash"
              @select="isConfirmingDelete = true"
            />
          </Menu.Content>
        </Menu.Root>
      </div>
    </RouterLink>
    <DividerLine
      color="default"
      direction="vertical"
      :width="1"
      class="h-7 py-1.5"
      :class="[!isViewActive(view) ? 'opacity-100' : 'opacity-0']"
    />
  </div>
  <ConfirmationDialog
    :open="isConfirmingDelete"
    title="Delete this view?"
    description="This view will be deleted immediately. You can't undo this action."
    @confirm="deleteView"
    @close="isConfirmingDelete = false"
  />
</template>
