<script lang="ts" setup>
import { twMerge } from 'tailwind-merge'
import { useId } from 'vue'
import ListMenuItem, { type ListMenuItemProps } from '../ListMenuItem.vue'
import { MenuCtx } from './Root.vue'

type Props = Omit<ListMenuItemProps, 'element'> & {
  /** If set to true, this item will be active when the menu first renders */
  selectedOnMount?: boolean
}

const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'select'): void
}>()

defineOptions({
  name: 'MenuItem',
})

const { initItem, menu } = MenuCtx.get()
const id = useId()
initItem({ onSelect: () => emit('select'), id })

if (props.selectedOnMount) {
  menu.value.setHighlightedValue(id)
}
</script>

<template>
  <ListMenuItem
    element="button"
    v-bind="{ ...props, ...menu.getItemProps({ value: id }) }"
    :class="twMerge('text-left', String($attrs.class || ''))"
  >
    <template #prefix>
      <slot
        :id="id"
        :active="menu.highlightedValue === id"
        name="prefix"
      />
    </template>
    <slot />
    <template #suffix>
      <slot
        :id="id"
        name="suffix"
        :active="menu.highlightedValue === id"
      />
    </template>
  </ListMenuItem>
</template>
