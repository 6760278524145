<script setup lang="ts">
import { computed, ref } from 'vue'

import { useFilters } from '@/modules/Project/useFilters'
import { useProject } from '@/modules/Project/useProject'
import { useWorkspaceMembers } from '@/modules/WorkspaceSettings/useWorkspaceMembers'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import { useStorage } from '@vueuse/core'

import { isSelectProperty } from '@/shared/utils/typeGuards'
import Menu from '@/uiKit/Menu'
import { FILTERABLE_TYPE_ICON } from '../icons'
import FilterItemNumberValue from './FilterItemNumberValue.vue'
import FilterBarTextItemValue from './FilterItemTextValue.vue'
import {
  getFilterSubject,
  getFilterSubjectForProperty,
  hasTextLikeFilter,
  isFilterableProperty,
  isStatusFilterMatcherValue,
  isTextLikeFilterSubject,
  type AllFilterableType,
  type GroupFilter,
  type SelectOptionValueFilter,
  type SimpleFilter,
  type StatusFilter,
} from './types'
import { statusFilterItems } from './utils'
/**
 * This component renders the set of menus and dialogs that allow users to add
 * new filters to the current view.
 */

const filtersStore = useFilters()
const projectStore = useProject()
const workspaceMemberStore = useWorkspaceMembers()

const localFilter = useStorage<Record<string, GroupFilter>>('localFilter', {})

type Filter = {
  id: string
  label: string
  type: AllFilterableType
  options: { color?: string | null; value: string; label?: string }[]
}

/** The set of filter types to render at the first level of the menu */
type FirstLevelFilter = Filter & {
  group: 'property' | 'misc'
}
const items = computed((): FirstLevelFilter[] => {
  const arr: FirstLevelFilter[] = []

  projectStore.visibleProperties.forEach((property) => {
    if (!isFilterableProperty(property)) return
    arr.push({
      id: property.id,
      label: property.name,
      type: property.type,
      options: isSelectProperty(property) ? property.config.options : [],
      group: 'property',
    })
  })

  arr.push(statusFilterItems)

  return arr
})

/**
 * The property (if any) that has been selected at the first level of the menu.
 * Some properties trigger submenus (e.g. select properties), while others
 * will trigger a dialog (e.g. text properties).
 */
const activePropertyItem = ref<Filter | null>(null)

const onUpdate = (newFilter: SimpleFilter) => {
  assertIsNotNullOrUndefined(projectStore.activeView, 'activeView is not set')
  if (!activePropertyItem.value) return

  if (projectStore.activeView.id in localFilter.value) {
    // If the local filter already has a value for the current project, add the new filter to the list
    localFilter.value = {
      ...localFilter.value,
      [projectStore.activeView.id]: {
        ...localFilter.value[projectStore.activeView.id],
        filters: [...localFilter.value[projectStore.activeView.id].filters, newFilter],
      },
    }
  } else {
    // If the filter was set, but has no value for the current project, set the new filter for it
    localFilter.value = {
      ...localFilter.value,
      [projectStore.activeView.id]: {
        conjunction: 'or',
        filters: [newFilter],
      },
    }
  }

  filtersStore.viewFilters = true
  activePropertyItem.value = null
}

const onSelectFilter = (property: Filter, item: Filter['options'][number]) => {
  assertIsNotNullOrUndefined(projectStore.activeView, 'activeView is not set')

  if (
    property.type === 'single_select' ||
    property.type === 'multi_select' ||
    property.type === 'user_select'
  ) {
    // The default select filter to be added will have no specified value
    const newFilter: SelectOptionValueFilter = {
      matcher: {
        name: 'property_any_of',
        property_id: property.id,
        values: [item.value],
      },
      subject: 'field_select_option_value',
    }

    if (projectStore.activeView.id in localFilter.value) {
      // If the local filter already has a value for the current project, add the new filter to the list
      localFilter.value = {
        ...localFilter.value,
        [projectStore.activeView.id]: {
          ...localFilter.value[projectStore.activeView.id],
          filters: [...localFilter.value[projectStore.activeView.id].filters, newFilter],
        },
      }
    } else {
      // If the filter was set, but has no value for the current project, set the new filter for it
      localFilter.value = {
        ...localFilter.value,
        [projectStore.activeView.id]: {
          conjunction: 'or',
          filters: [newFilter],
        },
      }
    }
  }

  if (property.type === 'status') {
    if (!isStatusFilterMatcherValue(item.value)) {
      throw new Error(`Invalid status filter value: ${item.value}`)
    }

    // The default select filter to be added will have no specified value
    const newFilter: StatusFilter = {
      matcher: {
        name: 'view_any_of',
        values: [item.value],
        view_id: projectStore.activeView.id,
      },
      subject: 'entity_status',
    }

    if (projectStore.activeView.id in localFilter.value) {
      // If the local filter already has a value for the current project, add the new filter to the list
      localFilter.value = {
        ...localFilter.value,
        [projectStore.activeView.id]: {
          ...localFilter.value[projectStore.activeView.id],
          filters: [...localFilter.value[projectStore.activeView.id].filters, newFilter],
        },
      }
    } else {
      // If the filter was set, but has no value for the current project, set the new filter for it
      localFilter.value = {
        ...localFilter.value,
        [projectStore.activeView.id]: {
          conjunction: 'or',
          filters: [newFilter],
        },
      }
    }
  }

  filtersStore.viewFilters = true
  activePropertyItem.value = null
  isOpen.value = false
}

/** Handle the user selecting a property at the first menu level */
const onSelectProperty = (propertyItem: Filter) => {
  activePropertyItem.value = propertyItem

  // If the property's filter requires a user-inputted text value, open the text modal
  if (hasTextLikeFilter(propertyItem.type)) {
    isOpen.value = false
    isTextModalOpen.value = true
  }

  if (propertyItem.type === 'number') {
    isOpen.value = false
  }
}

const isOpen = ref(false)
const onOpenChange = (open: boolean) => {
  if (!open) {
    activePropertyItem.value = null
  }

  isOpen.value = open
}

const isTextModalOpen = ref(false)

const property = computed(() => {
  if (!activePropertyItem.value) return

  const propertyId = activePropertyItem.value.id
  return projectStore.properties.find((p) => p.id === propertyId)
})

/** The filter subject for the selected property */
const propertyFilterSubject = computed(() => {
  if (!property.value || !isFilterableProperty(property.value)) {
    return null
  }

  return getFilterSubjectForProperty(property.value.type)
})

const filteredSelectOptions = ref<Filter['options']>([])
const filteredProperties = ref<FirstLevelFilter[]>([])
</script>

<template>
  <Menu.Root
    v-slot="{ getTriggerProps }"
    :open="isOpen"
    :positioning="{ placement: 'top-start' }"
    @change:open="onOpenChange"
  >
    <slot
      name="trigger"
      :is-open="isOpen"
      :get-trigger-props="getTriggerProps"
    />
    <Menu.Content class="min-w-60 max-w-[320px]">
      <template
        v-if="
          activePropertyItem &&
          (getFilterSubject(activePropertyItem.type) === 'field_select_option_value' ||
            getFilterSubject(activePropertyItem.type) === 'entity_status')
        "
      >
        <Menu.Search
          :placeholder="`Filter ${activePropertyItem.label}`"
          :items="activePropertyItem.options"
          :key-or-predicate="'value'"
          @change="filteredSelectOptions = $event"
        />
        <div class="go-scrollbar max-h-[500px] overflow-y-auto">
          <template
            v-for="item in filteredSelectOptions"
            :key="item.value"
          >
            <Menu.Item
              v-if="
                activePropertyItem.type === 'multi_select' || activePropertyItem.type === 'status'
              "
              :label="item.label ?? item.value"
              @select="onSelectFilter(activePropertyItem, item)"
            />
            <Menu.Item
              v-else
              :label="item.value"
              @select="onSelectFilter(activePropertyItem, item)"
            >
              <BadgeItem
                size="sm"
                :label="
                  activePropertyItem.type === 'user_select'
                    ? workspaceMemberStore.getMemberNameFromId(item.value)
                    : item.value
                "
                variant="blue"
                :rainbow-color="item.color"
              />
            </Menu.Item>
          </template>
        </div>
      </template>
      <template v-else>
        <Menu.Search
          :items="items"
          key-or-predicate="label"
          @change="filteredProperties = $event"
        />
        <div class="go-scrollbar max-h-[500px] overflow-y-auto">
          <Menu.Item
            v-for="item in filteredProperties"
            :key="item.id"
            :label="item.label"
            :checked="projectStore.activeView?.view.propertyIds?.includes(item.id) ?? false"
            :icon="FILTERABLE_TYPE_ICON[item.type]"
            :always-visible="true"
            @select="onSelectProperty(item)"
          />
        </div>
      </template>
    </Menu.Content>
  </Menu.Root>

  <FilterBarTextItemValue
    v-if="property && isTextLikeFilterSubject(propertyFilterSubject)"
    :filter="{
      matcher: {
        name: 'property_contains_any_of',
        property_id: property.id,
        values: [''],
      },
      subject: propertyFilterSubject,
    }"
    :is-open="isTextModalOpen"
    :property-name="property.name"
    @close="isTextModalOpen = false"
    @update="onUpdate"
  />

  <FilterItemNumberValue
    :filter="{
      matcher: {
        name: 'property_any_of',
        property_id: property?.id || '',
        values: [],
      },
      subject: 'field_number_value',
    }"
    :is-open="property?.type === 'number'"
    :property-name="property?.name || ''"
    @close="activePropertyItem = null"
    @update="onUpdate"
  />
</template>
