<script setup lang="ts">
import { getProject } from '@/backend/getProject'
import { listEntities } from '@/backend/listEntities'
import type { CollectionProperty, Property } from '@/modules/Project/Properties/types'
import SimpleTable from '@/uiKit/SimpleTable.vue'
import { ref, watch } from 'vue'
import { serializeField, serializeProperty } from './useProject'
import { useSerializeFieldToText } from './useSerializeFieldToText'

const props = defineProps<{
  workspaceId: string
  projectId: string
  entityId: string
  property: CollectionProperty
}>()

const MAX_ENTITY_NUMBER = 50

const serializeFieldToText = useSerializeFieldToText()

const properties = ref<Property[]>([])
const data = ref<Array<{ id: string; data: Record<string, string> }>>([])

watch(
  () => props.property,
  async (property) => {
    const projectRes = await getProject(
      props.workspaceId,
      property.config.subprojectConfig.child_project_id,
    )
    if (!projectRes.ok) return

    properties.value = projectRes.data.properties.map(serializeProperty)
  },
  {
    immediate: true,
  },
)

watch(
  () => props.entityId,
  async (parentEntityId) => {
    if (!parentEntityId) {
      return
    }

    const entitiesResult = await listEntities(
      props.workspaceId,
      props.property.config.subprojectConfig.child_project_id,
      0,
      MAX_ENTITY_NUMBER,
      props.entityId,
    )
    if (!entitiesResult.ok) {
      return
    }

    data.value = entitiesResult.data.data.map((d) => ({
      id: d.id,
      data: Object.fromEntries(
        Object.values(d.fields).map((f) => [
          f.property_id,
          serializeFieldToText(serializeField(f)),
        ]),
      ),
    }))
  },

  {
    immediate: true,
  },
)
</script>

<template>
  <div class="size-full overflow-scroll px-1">
    <SimpleTable
      v-if="data && properties.length > 0"
      :columns="properties.map((p) => ({ key: p.id, label: p.name }))"
      :data="data"
      :hover="false"
    >
      <template
        v-for="prop in properties"
        #[prop.id]="{ row }"
      >
        {{ row.data[prop.id] }}
      </template>
    </SimpleTable>
  </div>
</template>
