<script setup lang="ts">
import * as popover from '@zag-js/popover'
import { normalizeProps, useMachine } from '@zag-js/vue'
import { computed, useId, watch } from 'vue'
import { getPopoverTeleportTarget } from './utils/teleport'

const props = withDefaults(
  defineProps<{
    open?: boolean
    positioning?: popover.PositioningOptions
    closeOnInteractOutside?: boolean
    disableTeleport?: boolean
  }>(),
  {
    open: undefined,
    positioning: undefined,
    closeOnInteractOutside: false,
    disableTeleport: false,
  },
)

const emit = defineEmits<{
  (e: 'change:open', open: boolean): void
}>()

const id = useId()
const [state, send] = useMachine(
  popover.machine({
    id,
    positioning: props.positioning,
    onOpenChange: (e) => {
      emit('change:open', e.open)
    },
    closeOnInteractOutside: true,
    open: props.open,
    'open.controlled': typeof props.open === 'boolean',
  }),
  {
    context: computed(() => ({
      closeOnInteractOutside: props.closeOnInteractOutside,
      open: props.open,
    })),
  },
)

const api = computed(() => popover.connect(state.value, send, normalizeProps))

watch(
  () => props.open,
  (newOpen) => {
    if (!newOpen) return
    api.value.setOpen(newOpen)
  },
)

const popoverTarget = getPopoverTeleportTarget()

defineExpose({
  api,
})

function getTriggerProps() {
  const p = api.value.getTriggerProps()
  return {
    ...p,
    disabled: p.disabled === 'true' || p.disabled === true,
  }
}
</script>

<template>
  <slot
    name="trigger"
    :api="api"
    :get-trigger-props="getTriggerProps"
  />

  <Teleport
    :to="popoverTarget || 'body'"
    :disable-teleport="props.disableTeleport"
    :defer="!!popoverTarget"
  >
    <div v-bind="api.getPositionerProps()">
      <slot
        name="content"
        :api="api"
      />
    </div>
  </Teleport>
</template>
