<script setup lang="ts">
import IntegrationModal from '@/modules/Workspaces/KnowledgeHub/Integrations/IntegrationModal.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import type { IconName } from '@/uiKit/IconName'
import IconSprite from '@/uiKit/IconSprite.vue'
import { ref } from 'vue'

defineProps<{ name: string; description: string; icon: IconName }>()

const open = ref(false)
</script>

<template>
  <div
    class="group relative flex h-64 flex-col rounded-corner-12 bg-surface-secondary p-4 transition-all hover:bg-background-transparent-hovered"
  >
    <h3 class="text-md-13px-default transition-all">
      {{ name }}
    </h3>
    <p
      v-if="description"
      class="translate-y-1 text-sm-12px-light text-text-subtle opacity-0 blur-sm transition group-hover:translate-y-0 group-hover:opacity-100 group-hover:blur-none"
    >
      {{ description }}
    </p>
    <IconSprite
      :icon="icon"
      size="32"
      class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transition-all group-hover:blur-sm"
    />
    <div class="mt-auto transition-all">
      <DarwinButton
        variant="outline"
        size="sm"
        rounded
        class="before:absolute before:inset-0 before:content-['']"
        @click="open = true"
      >
        Connect
      </DarwinButton>
    </div>
  </div>

  <IntegrationModal
    :open="open"
    @close="open = false"
  />
</template>
