<script lang="ts" setup>
import { computedAsync } from '@vueuse/core'
import { ref, useTemplateRef, watchEffect } from 'vue'

const props = defineProps<{
  loading: boolean
  url: string
}>()

const emit = defineEmits<{
  (e: 'error'): void
}>()

const loadingState = ref<'idle' | 'loading' | 'loaded'>('idle')
const value = computedAsync(async () => {
  if (props.loading) {
    URL.revokeObjectURL(props.url)
    return null
  }

  loadingState.value = 'loading'
  const response = await fetch(props.url)
  loadingState.value = 'loaded'

  if (!response.ok) {
    emit('error')
  }
  const blob = await response.blob()
  const urlWithHost = props.url.startsWith('/') ? window.location.origin + props.url : props.url
  const path = new URL(urlWithHost).pathname
  if (path.endsWith('.svg')) {
    const asText = await blob.text()
    return {
      svg: true,
      svgAsText: asText,
    } as const
  }
  return {
    url: true,
    urlObject: URL.createObjectURL(blob),
  } as const
}, null)

/**
 *
 * The favicon URL can be a .svg, but we can't verify that what we download
 * is actually an svg. In the past it has actually been an HTML document with
 * styles that leak into the rest of the page. To prevent the styles from
 * leaking into the rest of the page we use a shadow DOM to render the svg.
 *
 * See GO-3298
 * https://linear.app/v7labs/issue/GO-3298/bug-squished-collection-view
 */
const shadowContainer = useTemplateRef('shadow-container')
watchEffect(() => {
  const svgAsText = value.value?.svgAsText
  if (!shadowContainer.value || !svgAsText) {
    return
  }

  const shadow = shadowContainer.value.attachShadow({ mode: 'open' })
  shadow.innerHTML = svgAsText
})
</script>

<template>
  <div
    v-if="loadingState !== 'loading' && value?.svg === true"
    ref="shadow-container"
    v-bind="$attrs"
    class="[&>*]:size-full"
  />
  <img
    v-else-if="loadingState !== 'loading' && value?.url"
    :src="value.urlObject"
    v-bind="$attrs"
  />
  <slot
    v-else
    name="fallback"
    v-bind="$attrs"
  />
</template>
