<script setup lang="ts">
import { createConnection } from '@/backend/createNewConnection'
import { GooglePickerClosedError } from '@/modules/Workspaces/KnowledgeHub/Integrations/GoogleDrive/errors'
import { useGoogleDriveConnection } from '@/modules/Workspaces/KnowledgeHub/Integrations/GoogleDrive/useGoogleDriveConnection'
import { useIntegrationStore } from '@/modules/Workspaces/KnowledgeHub/useIntegrationStore'
import { toast } from '@/shared/toast'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconButton from '@/uiKit/IconButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import ModalDialog from '@/uiKit/ModalDialog.vue'
import Nango from '@nangohq/frontend'
import { captureException } from '@sentry/vue'
import { ref } from 'vue'

const props = defineProps<{ open: boolean }>()
const emit = defineEmits<{
  close: []
}>()

const step = ref(0)
const loading = ref(false)
const connectionId = ref<string>()
const integrationStore = useIntegrationStore()
const googleDriveConnection = useGoogleDriveConnection()

function withLoading(cb: () => Promise<unknown>) {
  return async () => {
    loading.value = true
    try {
      await cb()
    } finally {
      loading.value = false
    }
  }
}

const { workspaceId } = useRouteParams()

async function connect() {
  if (!workspaceId.value) return
  const connection = await createConnection({
    workspaceId: workspaceId.value,
    integrationId: 'google_drive',
  })
  if (!connection.ok) {
    toast.error('Failed to connect, please try again')
    return
  }

  const connectSessionToken = connection.data.connect_session_token
  const nangoClient = new Nango({ connectSessionToken })

  try {
    const res = await nangoClient.auth('google-drive', { detectClosedAuthWindow: true })
    connectionId.value = res.connectionId
    step.value = 1
  } catch (e) {
    // No need to track
    if (isUnexpectedException(e)) {
      captureException(e)
    }

    const msg = e instanceof Error ? e.message : 'Failed to connect, please try again'
    toast.error(msg)
  }
}

/**
 * Checks whether an exception is of a kind that we don't know how to handle gracefully.
 */
function isUnexpectedException(e: unknown) {
  const isErrorWithType = (type: string) => e instanceof Error && 'type' in e && e.type === type

  /** User explicitly closed the auth window */
  const isWindowClosed = isErrorWithType('windowClosed')

  /** Auth popup was blocked by the browser */
  const isBlockedByBrowser = isErrorWithType('blocked_by_browser')

  if (isWindowClosed || isBlockedByBrowser) {
    return false
  }

  return true
}

async function pick() {
  if (!connectionId.value) {
    return
  }

  try {
    await googleDriveConnection.pick(connectionId.value)
    step.value = 2
  } catch (e) {
    if (e instanceof GooglePickerClosedError) {
      // This is user action, no need for a toast
      return
    }

    const msg = e instanceof Error ? e.message : 'Failed to pick files, please try again'
    toast.error(msg)
    throw e
  }
}

type StepInfo = {
  title: string
  description?: string
  actions?: Array<{ label: string; onClick: () => void }>
}

const stepInfos: StepInfo[] = [
  {
    title: 'Connect your account',
    actions: [{ label: 'Connect for workspace', onClick: withLoading(connect) }],
  },
  {
    title: 'Select data sources',
    actions: [{ label: 'Browse assets', onClick: withLoading(pick) }],
  },
  {
    title: 'Ready',
    description: `You’re good to go! Your files are syncing in the background and will be available shortly. To make any changes, visit the Integrations page in the Knowledge Hub and select Google Drive`,
    actions: [{ label: 'Done', onClick: onClose }],
  },
]

function onClose() {
  integrationStore.load()
  emit('close')
}
</script>

<template>
  <ModalDialog
    :open="props.open"
    class="!bg-background-transparent"
    @close="onClose"
  >
    <div class="grid h-[560px] w-[964px] grid-cols-2 gap-1">
      <div class="relative flex flex-col rounded-corner-16 bg-surface-popover p-8">
        <IconButton
          size="md"
          variant="neutral"
          class="absolute right-4 top-4 text-text-subtle"
          icon="close"
          rounded
          @click="onClose()"
        />

        <div class="grid size-12 place-items-center rounded-full bg-surface-secondary">
          <IconSprite
            icon="company-google"
            size="32"
          />
        </div>

        <h2 class="mt-8 text-display-sm-24px-default">Google Drive</h2>
        <p class="mt-3 text-lg-15px-light text-text-subtle">
          Use folders, docs, sheets, slides and other Drive files as context for AI models and Ask
          Go
        </p>

        <ul class="mt-8 flex flex-col gap-2">
          <li
            v-for="(stepInfo, i) in stepInfos"
            :key="stepInfo.title"
          >
            <div class="flex items-center gap-2">
              <div
                class="grid size-4 place-items-center rounded-full text-center text-xxs-8px-default transition"
                :class="{
                  'bg-background-black text-text-inverted': i === step,
                  'bg-background-gray-subtle text-text-subtlest': i !== step,
                  'bg-background-success': i < step,
                }"
              >
                <svg
                  v-if="step > i"
                  width="9"
                  height="8"
                  viewBox="0 0 9 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.76996 0.843137C8.15387 1.14186 8.22569 1.69889 7.93038 2.08723L4.08016 7.31288C3.93472 7.50413 3.71855 7.62722 3.48147 7.65375C3.24439 7.68028 3.00685 7.60797 2.8236 7.4535L0.427862 5.47357C0.0557768 5.15992 0.00550446 4.60047 0.315575 4.22409C0.625646 3.84771 1.17864 3.79685 1.55073 4.1105L3.24592 5.49987L6.54014 1.00541C6.83545 0.617073 7.38606 0.544417 7.76996 0.843137Z"
                    fill="white"
                  />
                </svg>

                <span
                  v-else
                  class="text-xxs-8px-default"
                >
                  {{ i + 1 }}
                </span>
              </div>
              <span
                class="text-lg-15px-default"
                :class="{ 'text-text-subtlest': i !== step }"
              >
                {{ stepInfo.title }}
              </span>
            </div>

            <p
              v-if="step === i && stepInfo.description"
              class="mt-1 text-lg-15px-light"
            >
              {{ stepInfo.description }}
            </p>
          </li>
        </ul>

        <div class="mt-auto flex flex-col gap-2">
          <DarwinButton
            v-for="(action, i) in stepInfos[step].actions"
            :key="action.label"
            :variant="i === 0 ? 'black' : 'outline'"
            rounded
            size="lg"
            class="w-full"
            :loading="loading"
            :disabled="loading"
            @click="action.onClick"
          >
            {{ action.label }}
          </DarwinButton>
        </div>
      </div>

      <div class="rounded-corner-12 bg-background-gray-subtle p-8 backdrop-blur-2xl">
        <div data-theme="dark">
          <h2 class="text-lg-15px-default text-[white]">Permissions and privacy</h2>
          <p class="mt-2 text-lg-15px-light text-text-subtle">
            Only users in your workspace will be able to see this data. This data is never used for
            model training.
          </p>

          <h2 class="mt-8 text-lg-15px-default text-[white]">
            How long does it take for my files to appear?
          </h2>
          <p class="mt-2 text-lg-15px-light text-text-subtle">
            It takes a few minutes for Go to understand your files. New files are then synced into
            Go every 5 minutes.
          </p>
        </div>
      </div>
    </div>
  </ModalDialog>
</template>
