<script setup lang="ts">
import { formatDistance } from 'date-fns'
import { computed, onMounted } from 'vue'

import { deleteApiKey } from '@/backend/deleteApiKey'
import { listApiKeys } from '@/backend/listApiKeys'

import Illustration from '@/assets/images/empty-api.svg?raw'

import LoadingSkeleton from '@/sharedComponents/LoadingSkeleton.vue'
import { useDataLoader } from '@/sharedComposables/useDataLoader'
import IconButton from '@/uiKit/IconButton.vue'
import SimpleTable from '@/uiKit/SimpleTable.vue'

import { toast } from '@/shared/toast'
import Menu from '@/uiKit/Menu'
import WorkspaceSettingsApiKeysCreateForm from './WorkspaceSettingsApiKeysCreateForm.vue'
import { serializeApiKey, useApiKeys } from './useApiKeys'

const API_KEYS_TABLE_COLUMNS = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'prefix',
    label: 'Prefix',
  },
  {
    key: 'expires',
    label: 'Expires',
  },
  {
    key: 'menu',
    width: '32px',
  },
]

type ApiKeyRow = {
  id: string
  name: string | null
  prefix: string
  expires_at: string | null
}

const apiKeyStore = useApiKeys()

const apiKeyLoader = useDataLoader(() => listApiKeys())
const isLoading = computed(() => apiKeyLoader.status.value === 'loading')

onMounted(async () => {
  const apiKeyResponse = await apiKeyLoader.load()
  if (apiKeyResponse.ok) {
    apiKeyStore.setApiKeys(apiKeyResponse.data.data.map(serializeApiKey))
  }
})

const tableRows = computed<ApiKeyRow[]>(() => {
  if (isLoading.value) {
    const LOADING_DATA: ApiKeyRow[] = Array(3).fill({
      id: '',
      name: '',
      prefix: '',
      expires_at: new Date(),
    })

    return LOADING_DATA
  }

  return apiKeyStore.apiKeys.map<ApiKeyRow>((key) => {
    return {
      id: key.id,
      name: key.name,
      prefix: key.prefix,
      expires_at: key.expires_at,
    }
  })
})

const onDeleteApiKey = async (row: ApiKeyRow) => {
  const result = await deleteApiKey(row.id)
  if (result.ok) {
    apiKeyStore.removeApiKey(row.id)
    toast.info(`Key with prefix ${row.prefix} has been deleted`)
  } else {
    toast.error('Failed to delete API key')
  }
}

const getKeyExpiry = (expiresAt: string | null) => {
  if (!expiresAt) {
    return 'Never'
  }

  return formatDistance(new Date(expiresAt), new Date(), { addSuffix: true })
}
</script>

<template>
  <div class="mx-auto flex w-[768px] flex-col">
    <h2 class="mx-4 mb-1 mt-16 text-xl-18px-bold text-text">API Keys</h2>
    <p class="mx-4 mb-8 text-sm-12px-light text-text-subtle">
      Manage API keys for programmatic access to your Go workspace.
    </p>

    <div class="mb-8 rounded-xl bg-surface-secondary p-4">
      <h3 class="mb-1 text-sm-12px-bold font-semibold">Create API key</h3>
      <p class="mb-4 text-sm-12px-light text-text-subtle">
        You can create a new API key by giving it an optional name and setting an expiration time.
      </p>
      <WorkspaceSettingsApiKeysCreateForm />
    </div>

    <SimpleTable
      :columns="API_KEYS_TABLE_COLUMNS"
      :data="tableRows"
      :hover="false"
      class="mx-4 mb-16"
    >
      <template #name="{ row }">
        <LoadingSkeleton
          class="flex h-5 w-2/3 items-center"
          :status="isLoading"
        >
          {{ row.name ?? 'Unnamed' }}
        </LoadingSkeleton>
      </template>

      <template #prefix="{ row }">
        <LoadingSkeleton
          class="flex h-5 w-2/3 items-center"
          :status="isLoading"
        >
          <pre>{{ row.prefix }}</pre>
        </LoadingSkeleton>
      </template>

      <template #expires="{ row }">
        <LoadingSkeleton
          class="flex h-5 w-2/3 items-center"
          :status="isLoading"
        >
          {{ isLoading ? '' : getKeyExpiry(row.expires_at) }}
        </LoadingSkeleton>
      </template>

      <template #menu="{ row }">
        <Menu.Root
          v-slot="{ getTriggerProps, menu }"
          :positioning="{ placement: 'bottom-start', offset: { mainAxis: 6, crossAxis: -6 } }"
          close-on-select
        >
          <IconButton
            v-bind="getTriggerProps()"
            :class="
              menu.open
                ? 'opacity-100'
                : 'opacity-0 group-hover/row:opacity-100 focus-visible:opacity-100'
            "
            icon="more-dots"
            size="sm"
            :aria-label="`Open context menu`"
            variant="transparent"
          />
          <Menu.Content class="flex flex-col gap-0.5 p-0.5">
            <Menu.Item
              label="Delete API key"
              critical
              class="min-w-[180px]"
              icon="trash"
              @select="onDeleteApiKey(row)"
            />
          </Menu.Content>
        </Menu.Root>
      </template>
    </SimpleTable>

    <div
      v-if="tableRows.length === 0"
      class="grid place-items-center gap-8"
    >
      <p class="text-sm-12px-default text-text-subtle">No API Key created yet</p>
      <!-- We use it here to inline the SVG -->
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="pointer-events-none"
        v-html="Illustration"
      ></div>
    </div>
  </div>
</template>
