import type { FieldPreviewResponse, PropertyType } from '@/backend/types'
import type { Field } from '@/modules/Project/Fields/types'
import { coalesceEmptyObjectToNull } from '@/shared/utils'
import { exhaustiveGuard, invariant } from '@/shared/utils/typeAssertions'

/**
 * Preview fields always come from the backend with these properties.
 */
type FieldFromBackend = 'manualValue' | 'toolValue' | 'propertyId'

type TextPreview = Pick<Field<'text'>, FieldFromBackend | 'type'>

type URLPreview = Pick<Field<'url'>, FieldFromBackend | 'type'>

type FilePreview = Pick<
  Field<'file'>,
  FieldFromBackend | 'type' | 'manualFilename' | 'toolFilename'
>

type JsonPreview = Pick<Field<'json'>, FieldFromBackend | 'type'>

type SingleSelectPreview = Pick<Field<'single_select'>, FieldFromBackend | 'type'>

type MultiSelectPreview = Pick<Field<'multi_select'>, FieldFromBackend | 'type'>

type UserSelectPreview = Pick<Field<'user_select'>, FieldFromBackend | 'type'>

type PdfPreview = Pick<Field<'pdf'>, FieldFromBackend | 'type' | 'manualFilename' | 'toolFilename'>

type FileCollectionPreview = Pick<Field<'file_collection'>, FieldFromBackend | 'type'>

type CollectionPreview = Pick<Field<'collection'>, FieldFromBackend | 'type'>

type ReferencePreview = Pick<
  Field<'reference'>,
  FieldFromBackend | 'type' | 'toolEntityIds' | 'manualEntityIds'
>

type NumberPreview = Pick<
  Field<'number'>,
  FieldFromBackend | 'type' | 'manualValueUnit' | 'toolValueUnit'
>

type _FieldPreview =
  | TextPreview
  | URLPreview
  | FilePreview
  | JsonPreview
  | SingleSelectPreview
  | MultiSelectPreview
  | UserSelectPreview
  | PdfPreview
  | CollectionPreview
  | FileCollectionPreview
  | ReferencePreview
  | NumberPreview

/**
 * A field preview is a lightweight version of a field that only contains a minimal
 * amount of properties required to display the field's value.
 *
 * It is used when wire-size is a limiting factor, e.g. when a collection field contains
 * information on its subproject or when a reference field contains information on its
 * matched entities.
 */
export type FieldPreview<Type extends PropertyType = _FieldPreview['type']> = Extract<
  _FieldPreview,
  { type: Type }
> & { entityId: string }

/**
 * Preview fields are returned from the backend with no property type, but we need the property
 * type to serialize the field to a string (as different property types have different rules
 * for serialization).
 *
 * This function takes:
 * @param response - preview field as returned from the backend
 * @param type - the field's property type, sourced by matching the field's property ID to a property in a frontend datastore
 * @param entityId - the ID of the entity that the field belongs to
 *
 * @returns a more complete field preview with the property type and entity ID included
 */
export const serializePreview = (
  response: FieldPreviewResponse,
  type: PropertyType,
  entityId: string,
): FieldPreview => {
  const isStringOrNull = (v: unknown): v is string | null => typeof v === 'string' || v === null
  if (type === 'text' || type === 'url' || type === 'json') {
    if (
      !isStringOrNull(response.manual_value.value) ||
      !isStringOrNull(response.tool_value.value)
    ) {
      throw new Error('Expected text field to have string values')
    }

    return {
      type,
      manualValue: response.manual_value.value,
      toolValue: response.tool_value.value,
      propertyId: response.property_id,
      entityId,
    }
  }

  if (type === 'single_select' || type === 'multi_select' || type === 'user_select') {
    const isArrayOrNull = (v: unknown): v is string[] | null => Array.isArray(v) || v === null
    if (!isArrayOrNull(response.manual_value.value) || !isArrayOrNull(response.tool_value.value)) {
      throw new Error('Expected select field to have array values')
    }

    return {
      type,
      manualValue: response.manual_value.value,
      toolValue: response.tool_value.value,
      propertyId: response.property_id,
      entityId,
    }
  }

  if (type === 'file' || type === 'pdf') {
    if (
      !isStringOrNull(response.manual_value.value) ||
      !isStringOrNull(response.tool_value.value)
    ) {
      throw new Error('Expected file field to have string values')
    }

    if (
      !('original_filename' in response.manual_value) ||
      !('original_filename' in response.tool_value)
    ) {
      throw new Error('Expected file field to have original_filename values')
    }

    return {
      type: 'file',
      manualFilename: response.manual_value.original_filename,
      toolFilename: response.tool_value.original_filename,
      manualValue: response.manual_value.value,
      toolValue: response.tool_value.value,
      propertyId: response.property_id,
      entityId,
    }
  }

  if (type === 'collection' || type === 'file_collection') {
    if (
      !isStringOrNull(response.manual_value.value) ||
      !isStringOrNull(response.tool_value.value)
    ) {
      throw new Error('Expected collection field to have string values')
    }

    return {
      type,
      manualValue: response.manual_value.value,
      toolValue: response.tool_value.value,
      propertyId: response.property_id,
      entityId,
    }
  }

  if (type === 'reference') {
    if (
      !('matched_entity_ids' in response.manual_value) ||
      !('matched_entity_ids' in response.tool_value)
    ) {
      throw new Error('Expected reference field to have entity_ids values')
    }

    return {
      type,
      manualEntityIds: response.manual_value.matched_entity_ids,
      toolEntityIds: response.tool_value.matched_entity_ids,
      manualValue: coalesceEmptyObjectToNull(response.manual_value.value),
      toolValue: coalesceEmptyObjectToNull(response.tool_value.value),
      propertyId: response.property_id,
      entityId,
    }
  }

  if (type === 'number') {
    invariant(
      'raw_text' in response.manual_value &&
        response.manual_value.value &&
        'unit' in response.manual_value.value &&
        'raw_text' in response.tool_value &&
        response.tool_value.value &&
        'unit' in response.tool_value.value,
      'Number field is invalid',
    )

    return {
      type,
      manualValue: response.manual_value.value.number,
      manualValueUnit: response.manual_value.value.unit,
      toolValue: response.tool_value.value.number,
      toolValueUnit: response.tool_value.value.unit,
      propertyId: response.property_id,
      entityId,
    }
  }

  exhaustiveGuard(type, 'Unhandled property type')
  throw new Error('Unreachable')
}
