<script setup lang="ts">
/*
 * Figma uses Tab Element part for this, but it's links, not tabs.
 * TabList.vue is not suitable for a list of links.
 * https://www.figma.com/design/1HfA941cU4A9RZxXHLmbpG/V7-Go---Design-System?node-id=1293-25360&m=dev
 */
import { RouterLink, type RouteRecordName } from 'vue-router'

const links: { label: string; to: RouteRecordName }[] = [
  // { label: 'Intro', to: 'KnowledgeHubIntro' },
  { label: 'Files', to: 'Files' },
  { label: 'Integrations', to: 'Integrations' },
]
</script>

<template>
  <RouterLink
    v-for="link in links"
    :key="link.to"
    class="rounded-lg px-2 py-1.5 text-sm-12px-default text-text-subtle hover:bg-background-gray-subtlest-hovered"
    :to="{ name: link.to }"
    exact-active-class="bg-background-gray-subtlest !text-text"
  >
    {{ link.label }}
  </RouterLink>
</template>
