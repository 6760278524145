<script setup lang="ts">
import { invariant } from '@/shared/utils/typeAssertions'
import { computed, ref } from 'vue'
import { TYPE_ICON } from '../icons'
import FilterItemContainer from './FilterItemContainer.vue'
import FilterBarItemMatcher, { type ID } from './FilterItemMatcher.vue'
import FilterItemNumberValue from './FilterItemNumberValue.vue'
import { isValidNumberMatcher, type NumberValueFilter } from './types'
import { buildNumberFilter, getFilterDisplayValue, getNumberFilterValue } from './utils'

/**
 * This component renders a filter on a number property that is applied to the current
 * view. It provides functionality that lets the user update or delete the filter.
 */

const props = defineProps<{
  filter: NumberValueFilter
  propertyName: string
}>()

const emit = defineEmits<{
  (e: 'delete'): void
  (e: 'update', filter: NumberValueFilter): void
}>()

const value = computed(() => getNumberFilterValue(props.filter) || '')

const onNewMatcher = (matchId: ID) => {
  invariant(isValidNumberMatcher(matchId), 'Invalid number matcher ID')
  const newFilter = buildNumberFilter({
    matcherName: matchId,
    value: value.value,
    propertyId: props.filter.matcher.property_id,
  })

  emit('update', newFilter)
}

const isTextModalOpen = ref(false)
</script>

<template>
  <FilterItemContainer
    :icon="TYPE_ICON['number']"
    :applied-to="propertyName"
    @delete="$emit('delete')"
  >
    <FilterBarItemMatcher
      :matcher-name="filter.matcher.name"
      :values="[]"
      type="number"
      @match="onNewMatcher"
    />
    <button
      class="flex cursor-pointer items-center gap-0.5 px-1.5 py-1 text-sm-12px-default text-text-subtle hover:bg-background-transparent-hovered active:bg-background-transparent-pressed"
      :class="{ 'bg-background-transparent-hovered': isTextModalOpen }"
      @click="isTextModalOpen = true"
    >
      {{ getFilterDisplayValue(filter) }}
    </button>
    <FilterItemNumberValue
      :filter="filter"
      :is-open="isTextModalOpen"
      :property-name="propertyName"
      @close="isTextModalOpen = false"
      @update="$emit('update', $event)"
    />
  </FilterItemContainer>
</template>
