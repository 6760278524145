<script setup lang="ts">
import { isCommandOrControlKey } from '@/shared/utils/event'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import { computed, toRef, useTemplateRef, watch } from 'vue'
import type { Field } from './Fields/types'
import type { Property } from './Properties/types'
import { useNumberField } from './useNumberField'
import { injectPinnedProp } from './usePinnedColumn'
import { useTableCellFocus } from './useTableCellFocus'
import { PINNED_SELECTED_CELL_Z_INDEX, SELECTED_CELL_Z_INDEX } from './useTableZIndices'

const props = defineProps<{
  field: Field<'number'>
  config: Property<'number'>['config']
  isSelected: boolean
  isFocused: boolean
  hasSelectedRange: boolean
}>()

const emit = defineEmits<{
  (e: 'next' | 'focus', event: KeyboardEvent): void
}>()

const { workspaceId, projectId } = useRouteParams()

const cell = useTemplateRef('cell')
useTableCellFocus({
  cell,
  isSelected: toRef(props, 'isSelected'),
  isFocused: toRef(props, 'isFocused'),
})

const { inputFieldValue, syncLocalValue, shouldBeRed, saveFieldValue, format } = useNumberField(
  computed(() => props.field),
  computed(() => props.config),
)

watch(
  () => props.isFocused,
  (newFocused, oldFocused) => {
    const hasLostFocus = oldFocused && !newFocused
    if (hasLostFocus) {
      saveFieldValue({
        projectId: projectId.value,
        workspaceId: workspaceId.value,
      })
    }

    const input = cell.value?.querySelector('input')
    if (newFocused) {
      input?.focus()
    } else {
      input?.blur()
    }
  },
)

const isPinned = injectPinnedProp()
const shouldFloat = computed(() => props.isFocused || (props.isSelected && !props.hasSelectedRange))

const onGridcellKeydown = (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    syncLocalValue()
  }

  if (event.key === 'Enter') {
    emit('focus', event)
  }

  const isPrintableCharacter = event.key.length === 1 && !isCommandOrControlKey(event)
  if (isPrintableCharacter && !props.isFocused && props.isSelected) {
    emit('focus', event)
    inputFieldValue.value = ''
    cell.value?.querySelector('input')?.focus()
  }
}
</script>

<template>
  <div
    ref="cell"
    class="relative size-full min-w-max"
    v-bind="$attrs"
    @keydown="onGridcellKeydown"
  >
    <div
      class="h-full w-max min-w-full max-w-96"
      :class="[
        shouldFloat &&
          'absolute left-0 top-0 rounded-corner-4 outline outline-2 outline-border-focused',
        isSelected && (!hasSelectedRange || isFocused) && 'bg-surface-primary',
        isSelected && hasSelectedRange && !isFocused && 'bg-background-selected',
        isFocused && 'shadow-md',
      ]"
      :style="{
        zIndex: isPinned ? PINNED_SELECTED_CELL_Z_INDEX : SELECTED_CELL_Z_INDEX,
      }"
    >
      <div
        v-if="isSelected"
        aria-hidden
        class="line-clamp-1 whitespace-nowrap p-2 pl-3 text-sm-12px-light opacity-0"
      >
        {{ inputFieldValue }}
      </div>
      <!-- 👆 This hidden element is here so that this input 👇 will resize as the number grows -->
      <input
        v-model="inputFieldValue"
        class="w-full bg-background-transparent p-2 pl-3 text-sm-12px-light outline-none"
        :class="[
          isSelected ? 'absolute left-0 top-0' : 'pointer-events-none',
          shouldBeRed ? 'text-text-critical' : 'text-text',
          format.rightAlign && 'text-right',
        ]"
        inputmode="numeric"
        type="text"
        @keydown.stop.enter="emit('next', $event)"
      />
    </div>
  </div>
</template>
