<script setup lang="ts">
import { FeatureFlag } from '@/modules/App/featureFlags'
import { useFeatureFlags } from '@/modules/App/useFeatureFlags'
import MarkdownEditor from '@/sharedComponents/MarkdownEditor/MarkdownEditor.vue'
import FlowerProgress from '@/uiKit/FlowerProgress.vue'
import { computed } from 'vue'
import type { LogMessage, ProgressMessage } from '../types'

const props = defineProps<{
  message: ProgressMessage | LogMessage
}>()

const meterColor = computed(() => {
  if (props.message.type === 'progress' && props.message.pctComplete === 100) return 'success'

  return 'warning'
})

const isDevMode = useFeatureFlags(FeatureFlag.DEV_MODE)
</script>

<template>
  <div class="rounded-xl bg-surface-primary text-sm-12px-default text-text shadow-sm">
    <div
      class="flex items-center justify-between gap-9 px-2.5 pt-3"
      :class="
        message.type === 'progress' && message.messages.length
          ? 'border-b border-dashed border-border-subtle pb-2'
          : 'pb-3'
      "
    >
      <div>
        <MarkdownEditor
          v-if="message.type === 'progress'"
          :value="message.text"
          class="max-w-prose"
          readonly
        />
        <div
          v-else-if="isDevMode"
          class="max-w-prose font-mono text-text"
        >
          {{ 'LOG: ' + message.message }}
        </div>
      </div>
      <div
        v-if="message.type === 'progress' && typeof message.pctComplete === 'number'"
        class="flex gap-1"
      >
        <div>{{ message.pctComplete }}%</div>
        <FlowerProgress
          :color="meterColor"
          :max="100"
          :min="0"
          :value="message.pctComplete"
        />
      </div>
    </div>
    <div
      v-if="message.type === 'progress' && message.messages.length > 0"
      class="pb-3 pt-2"
    >
      <MarkdownEditor
        v-for="child in message.messages"
        :key="child.id"
        readonly
        :value="child.text"
        class="max-w-prose px-2.5 text-text-subtlest"
      />
    </div>
    <div
      v-else-if="isDevMode && message.type === 'log'"
      class="pb-3 pt-2"
    >
      <pre class="go-scrollbar h-max min-w-full max-w-prose px-2.5 text-text-subtlest">{{
        JSON.stringify(message.data, null, 2)
      }}</pre>
    </div>
  </div>
</template>
