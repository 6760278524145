import { defineStore } from 'pinia'
import { ref, watch } from 'vue'

import {
  hasTextLikeFilter,
  type Filter,
  type FilterablePropertyType,
  type GroupFilter,
  type MatcherName,
} from './Filters/types'
import { useProject } from './useProject'

export type Conjunction = 'and' | 'or'

export const parseConjunction = (conjunction: string) => {
  if (conjunction === 'and') {
    return 'match all filters'
  }
  return 'match any filter'
}

/**
 * From a matcher name, amount and property type, return a human-readable string.
 * e.g. 'property_any_of', 3, 'multi_select' => 'is any of'
 * e.g. 'property_none_of', 1, 'single_select' => 'is not'
 */
export const parseMatcherName = (matcherParams: {
  matcherName: MatcherName
  amount?: number
  type: FilterablePropertyType
}) => {
  if (hasTextLikeFilter(matcherParams.type)) {
    if (matcherParams.matcherName === 'property_any_of') {
      return 'is'
    } else if (matcherParams.matcherName === 'property_none_of') {
      return 'is not'
    } else if (matcherParams.matcherName === 'property_contains_any_of') {
      return 'contains'
    } else if (matcherParams.matcherName === 'property_contains_none_of') {
      return 'does not contain'
    }
  }

  if (matcherParams.type === 'single_select' || matcherParams.type === 'user_select') {
    if (matcherParams.matcherName === 'property_any_of') {
      return matcherParams.amount === 1 ? 'is' : 'is any of'
    } else if (matcherParams.matcherName === 'property_none_of') {
      return matcherParams.amount === 1 ? 'is not' : 'is none of'
    }
  }

  if (matcherParams.type === 'multi_select') {
    if (matcherParams.matcherName === 'property_any_of') {
      return 'is any of'
    } else if (matcherParams.matcherName === 'property_all_of') {
      return 'is all of'
    } else if (matcherParams.matcherName === 'property_none_of') {
      return 'is none of'
    }
  }

  if (matcherParams.matcherName === 'property_not_set') {
    return 'is not set'
  }

  if (matcherParams.type === 'number') {
    switch (matcherParams.matcherName) {
      case 'property_less_than':
        return '<'
      case 'property_less_than_or_equal_to':
        return '≤'
      case 'property_greater_than':
        return '>'
      case 'property_greater_than_or_equal_to':
        return '≥'
      case 'property_any_of':
        return '='
      case 'property_none_of':
        return '≠'
    }
  }

  return matcherParams.matcherName
}

type GetGroupFilterArgs = {
  filter: GroupFilter
  parentEntityId?: string
  activeViewId?: string
}
export function getGroupFilter({
  filter,
  parentEntityId,
  activeViewId,
}: GetGroupFilterArgs): GroupFilter {
  const filters: Filter[] = [filter]

  if (activeViewId) {
    filters.push({
      matcher: { name: 'any_of', values: [activeViewId] },
      subject: 'active_view_id',
    })
  }

  if (parentEntityId) {
    filters.push({
      matcher: { name: 'any_of', values: [parentEntityId] },
      subject: 'parent_entity_id',
    })
  }

  const viewFilter: GroupFilter = {
    conjunction: 'and',
    filters,
  }

  return viewFilter
}

/**
 * The project table can show a filtered list of entities on that project. This
 * store holds the state of the filters that are currently applied, and provides
 * functions to add/remove/update filters.
 */
export const useFilters = defineStore('filters', () => {
  const project = useProject()
  const currentFilter = ref<GroupFilter>()

  const setCurrentFilter = (filter?: GroupFilter) => {
    currentFilter.value = filter
  }

  const addFilter = (filter: Filter) => {
    if (!currentFilter.value) {
      currentFilter.value = {
        conjunction: 'and',
        filters: [filter],
      }
      return
    }

    currentFilter.value.filters.push(filter)
  }

  const updateFilter = (index: number, filter: Filter) => {
    currentFilter.value?.filters.splice(index, 1, filter)
  }

  const removeFilter = (index: number) => {
    currentFilter.value?.filters.splice(index, 1)
  }

  const setConjunction = (conjunction: Conjunction) => {
    if (!currentFilter.value) {
      currentFilter.value = {
        conjunction,
        filters: [],
      }
      return
    }

    currentFilter.value.conjunction = conjunction
  }

  /** Whether the filter bar is currently visible */
  const viewFilters = ref<boolean>(false)

  watch(
    () => currentFilter.value,
    () => {
      // This ensures entities are reloaded on the project table
      project.setEntitiesStale(true)
    },
    { deep: true },
  )

  return {
    currentFilter,
    setCurrentFilter,
    addFilter,
    updateFilter,
    removeFilter,
    setConjunction,
    viewFilters,
  }
})
