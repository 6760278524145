<script setup lang="ts">
import type { ResourceRole } from '@/backend/types'
import type { WorkspaceMember } from '@/modules/WorkspaceSettings/useWorkspaceMembers'
import AvatarIcon from '@/uiKit/AvatarIcon.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import Menu from '@/uiKit/Menu'
import { computed } from 'vue'
import { PERMISSIONS_DIALOG_ID } from './consts'
import type { ProjectInvitation } from './projectPermissionsStore'
import type { ProjectMemberRole } from './types'

const props = defineProps<{
  member:
    | (WorkspaceMember & { type: 'workspaceMember' })
    | (ProjectInvitation & { type: 'invitation' })
  isCurrentUser: boolean
  role?: ResourceRole
  /** All roles that the user is able to update to */
  allowedRoles: Extract<ResourceRole, 'reader' | 'editor'>[]
}>()

const emit = defineEmits<{
  (e: 'change:role', role: ResourceRole | null): void
  (e: 'delete:invitation', invitationId: string): void
}>()

const roleTitleMap: Record<ProjectMemberRole, string> = {
  editor: 'Can edit',
  reader: 'Can view',
}
const getRoleTitle = (role?: ResourceRole): string | undefined => {
  if (role === 'owner') {
    return 'Owner'
  }

  if (role !== 'editor' && role !== 'reader') {
    return
  }

  return roleTitleMap[role].toLowerCase()
}

type ListItem = { title: string; description: string; role: ProjectMemberRole }
const allowedRoles = computed<Array<ListItem>>(() => {
  const roles: ListItem[] = []
  if (props.allowedRoles.includes('editor')) {
    roles.push({
      role: 'editor',
      title: roleTitleMap.editor,
      description: 'Can open the project and edit, recompute and download data',
    })
  }

  if (props.allowedRoles.includes('reader')) {
    roles.push({
      role: 'reader',
      title: roleTitleMap.reader,
      description: 'Can open the project but cannot edit any property or data',
    })
  }

  return roles
})

/** For a11y. ID to give the element that has this item's label */
const LABEL_ID = computed(() => `permissions-dialog-project-member-${props.member.id}`)

const label = computed(() => {
  if (props.member.type === 'invitation') {
    return props.member.email
  }

  return props.member.fullName ?? props.member.email ?? props.member.id
})

const onDeleteInvitation = (invitation: ProjectInvitation) => {
  if (!invitation.id) {
    throw new Error('Invitation ID is missing, it is probably still being created')
  }

  emit('delete:invitation', invitation.id)
}

const dropdownIsDisabled = computed(() => props.role === 'owner' || props.allowedRoles.length === 0)
</script>

<template>
  <Menu.Root
    v-slot="{ getTriggerProps, menu }"
    :positioning="{ sameWidth: true, placement: 'bottom-start', offset: { mainAxis: 8 } }"
    :teleport-to="`#${PERMISSIONS_DIALOG_ID}`"
    close-on-select
  >
    <li
      class="flex h-8 w-full select-none items-center gap-2 rounded-lg focus-within:bg-background-transparent-hovered hover:bg-background-transparent-hovered"
      :class="[
        menu.open && 'bg-background-transparent-hovered',
        !dropdownIsDisabled && 'cursor-pointer',
      ]"
      :aria-labelledby="LABEL_ID"
    >
      <button
        v-bind="getTriggerProps()"
        :disabled="dropdownIsDisabled"
        class="flex w-full items-center justify-start gap-2 px-1.5 text-left outline-none"
      >
        <AvatarIcon
          v-if="member.type === 'workspaceMember'"
          :full-text="label"
          shape="circle"
          size="sm"
        />
        <div
          v-else
          class="flex size-5 items-center justify-center rounded-full bg-background-gray-subtlest"
        >
          <IconSprite
            icon="user-2"
            size="xs"
            class="text-icon-subtle"
          />
        </div>
        <div
          :id="LABEL_ID"
          class="contents"
        >
          <div class="w-max grow text-sm-12px-default text-text">
            {{ label }}
            <template v-if="isCurrentUser"> (You)</template>
          </div>
          <div
            class="flex items-center gap-2"
            :class="dropdownIsDisabled ? 'text-text-subtlest' : 'text-text-subtle'"
          >
            <div>{{ getRoleTitle(role) }}</div>
            <IconSprite icon="chevron-select" />
          </div>
        </div>
      </button>
    </li>
    <Menu.Content>
      <Menu.Item
        v-for="item in allowedRoles"
        :key="item.role"
        @select="$emit('change:role', item.role)"
      >
        <div class="flex">
          <div class="flex size-5 items-center justify-center">
            <IconSprite
              v-if="role === item.role"
              icon="check"
              class="text-icon-subtle"
              size="sm"
            />
          </div>
          <div class="px-1 py-0.5">
            <div class="text-sm-12px-default text-text">
              {{ item.title }}
            </div>
            <div class="text-xs-11px-light text-text-subtle">
              {{ item.description }}
            </div>
          </div>
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        critical
        icon="trash"
        @select="
          member.type === 'invitation' ? onDeleteInvitation(member) : $emit('change:role', null)
        "
      >
        <div>
          {{ member.type === 'invitation' ? 'Revoke access' : 'Reset access' }}
        </div>
      </Menu.Item>
    </Menu.Content>
  </Menu.Root>
</template>
