<script setup lang="ts">
import { updateUser } from '@/backend/updateUser'
import { GO_DOCS_REFERENCE_URL } from '@/shared/utils'
import { invariant } from '@/shared/utils/typeAssertions'
import IconButton from '@/uiKit/IconButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import Menu from '@/uiKit/Menu'
import { useUser } from '../IdentityAndAccess/useUser'
import { forceEnableSignup } from '../Signup/SignupForm.vue'
import { useWelcomeTour } from '../WelcomeTour/useWelcomeTour'

const isDev = import.meta.env.DEV
const userStore = useUser()
const welcomeTour = useWelcomeTour()

const onHideTutorials = async () => {
  invariant(userStore.user, 'No user found in the store')
  userStore.user.showTutorials = false
  try {
    await updateUser({ showTutorials: false })
  } catch (error) {
    userStore.user.showTutorials = true
    throw error
  }
}

const onFindMoreTutorials = () => window.open(GO_DOCS_REFERENCE_URL, '_blank')

const onShowTutorials = async () => {
  invariant(userStore.user, 'No user found in the store')
  userStore.user.showTutorials = true
  try {
    await updateUser({ showTutorials: true })
  } catch (error) {
    userStore.user.showTutorials = false
    throw error
  }
}
</script>

<template>
  <Menu.Root
    v-if="userStore.user"
    v-slot="{ getTriggerProps }"
    v-bind="$attrs"
    close-on-select
  >
    <IconButton
      icon="more-dots"
      size="lg"
      variant="transparent"
      v-bind="{ ...getTriggerProps(), ...$attrs }"
      aria-label="Open tutorials menu"
      >Click me</IconButton
    >
    <Menu.Content class="min-w-56">
      <Menu.Item
        v-if="userStore.user.showTutorials"
        element="button"
        icon="hide"
        @select="onHideTutorials"
      >
        Hide tutorials
      </Menu.Item>
      <Menu.Item
        v-else
        element="button"
        icon="show"
        @select="onShowTutorials"
      >
        Show tutorials
      </Menu.Item>
      <Menu.Item
        element="button"
        icon="video"
        @select="onFindMoreTutorials"
        >Find tutorials
        <template #suffix>
          <IconSprite
            class="ml-auto text-icon-subtle"
            icon="arrow-top-right"
          /> </template
      ></Menu.Item>
      <Menu.Item
        element="button"
        icon="chat-bubble"
        @select="welcomeTour.prepare"
      >
        Begin welcome tour
      </Menu.Item>
      <Menu.Item
        v-if="isDev"
        element="button"
        icon="chat-bubble"
        @select="forceEnableSignup"
      >
        (DEV) Force open signup form
      </Menu.Item>
    </Menu.Content>
  </Menu.Root>
</template>
