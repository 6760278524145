<script setup lang="ts">
/**
 * Top left dropdown showing login status, workspace list and workspace creation.
 */
import { useAuth0 } from '@auth0/auth0-vue'
import { computed, ref, useTemplateRef } from 'vue'

import CurrentWorkspace from '@/modules/Workspaces/CurrentWorkspace.vue'
import WorkspaceCreationDialog from '@/modules/Workspaces/WorkspaceCreationDialog.vue'

import { useTheme } from '@/modules/App/useTheme'
import { GO_DOCS_REFERENCE_URL } from '@/shared/utils'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import AvatarIcon from '@/uiKit/AvatarIcon.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import Menu from '@/uiKit/Menu'
import { useRouter } from 'vue-router'
import ObjectURLImage from '../Projects/ObjectURLImage.vue'
import { useWorkspaces, type Workspace } from '../Workspaces/useWorkspaces'
import { usePermissionsStore } from './permissionsStore'
import { roleLabelMap } from './roleConfig'
import { useWorkspaceOwnership } from './useWorkspaceOwnership'

defineProps<{ displayCurrentWorkspaceName?: boolean }>()
const { logout: logoutBase } = useAuth0()

const { theme, setTheme, themePreference } = useTheme()

const store = useWorkspaces()

const router = useRouter()

const isCreating = ref(false)

const currentWorkspaceId = computed(() => store.currentWorkspace?.id)

const { isAllowedToCreate: isUserAllowedToCreateWorkspace } = useWorkspaceOwnership(
  store.workspaces,
)

const { captureAnalyticsEvent } = useAnalytics()

const onClickHelp = () => {
  captureAnalyticsEvent(ANALYTICS_EVENT.OPEN_HELP_CENTER)
  window.open(GO_DOCS_REFERENCE_URL, '_blank')
}

const onClickSettings = () => {
  captureAnalyticsEvent(ANALYTICS_EVENT.OPEN_SETTINGS_MENU)
  router.push({ name: 'WorkspaceSettings' })
}

const formatWorkspaceRole = (role: Exclude<Workspace['role'], undefined | null>) => {
  const fallback = role.charAt(0).toUpperCase() + role.slice(1)
  return roleLabelMap[role] ?? fallback
}

const logout = () => logoutBase({ logoutParams: { returnTo: window.location.origin } })

const onImageSrcError = (workspaceId: string) => {
  store.setIconDownloadError(workspaceId, true)
}

const sortedWorkspaces = computed(() => {
  return store.workspaces.toSorted((a, b) =>
    // puts first the active workspace but also sorts the rest alphabetically
    a.id === currentWorkspaceId.value ? -1 : a.name.localeCompare(b.name),
  )
})

const permissionsStore = usePermissionsStore()
const canAccessSettings = computed(() => permissionsStore.workspaceRole !== 'worker')

const themeSubmenu = useTemplateRef('themeSubmenu')
</script>

<template>
  <Menu.Root
    v-slot="{ getTriggerProps, menu, getTriggerItemProps }"
    :positioning="{ placement: 'bottom-end', offset: { mainAxis: 2 } }"
    close-on-select
  >
    <CurrentWorkspace
      class="max-w-full"
      :class="menu.open && 'bg-background-transparent-hovered'"
      :display-name="displayCurrentWorkspaceName"
      v-bind="getTriggerProps()"
    />
    <Menu.Content class="!w-80">
      <div
        class="max-h-[400px] overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-track-background-transparent scrollbar-thumb-background-gray-subtle scrollbar-track-rounded-md"
      >
        <Menu.Item
          v-for="workspace in sortedWorkspaces"
          :key="workspace.id"
          class="w-full py-2"
          @select="router.push(`/${workspace.id}`)"
        >
          <template #prefix>
            <div
              class="rounded-[9px] p-0.5"
              :class="
                workspace.id === currentWorkspaceId
                  ? 'border-2 border-border-focused-transparent'
                  : 'p-1'
              "
            >
              <ObjectURLImage
                v-if="!workspace.iconDownloadError && workspace.iconUrl"
                class="size-7 rounded-corner-6 object-cover"
                :url="workspace.iconUrl"
                :loading="workspace.iconUploading ?? false"
                @error="onImageSrcError(workspace.id)"
              />
              <AvatarIcon
                v-else
                :full-text="workspace.name"
                class="size-7"
              />
            </div>
          </template>
          <template #default>
            <div class="mr-2 flex flex-col px-1">
              <p class="truncate text-sm-12px-default leading-4">{{ workspace.name }}</p>
              <p
                v-if="workspace.role"
                class="text-xs-11px-default leading-4 text-text-subtlest"
              >
                {{ formatWorkspaceRole(workspace.role) }}
              </p>
            </div>
          </template>
        </Menu.Item>
      </div>
      <Menu.Divider />
      <Menu.Item
        v-if="isUserAllowedToCreateWorkspace"
        label="Create Workspace"
        class="cursor-pointer"
        role="button"
        icon="plus"
        @select="isCreating = true"
      />
      <Menu.Divider v-if="isUserAllowedToCreateWorkspace" />
      <Menu.Item
        v-if="canAccessSettings"
        label="Settings"
        role="button"
        class="w-full cursor-pointer"
        icon="settings"
        @select="onClickSettings"
      />
      <Menu.Root
        v-slot="{ menu: subMenu }"
        ref="themeSubmenu"
        :positioning="{
          placement: 'right-start',
          offset: {
            mainAxis: 2,
            crossAxis: -2,
          },
        }"
      >
        <Menu.Item
          v-bind="themeSubmenu ? getTriggerItemProps(themeSubmenu!.menu) : {}"
          label="Theme"
          class="cursor-pointer"
          icon="light-mode"
          @select="subMenu.setOpen(true)"
          @mouseenter="subMenu.setOpen(true)"
        >
          <template #suffix>
            <IconSprite
              class="text-icon-subtlest"
              icon="chevron-right"
            />
          </template>
        </Menu.Item>

        <Menu.Content>
          <Menu.Item
            role="menuitem"
            @select="setTheme('light')"
          >
            <template #prefix>
              <IconSprite
                :icon="theme === 'light' ? 'check' : 'blank'"
                class="text-icon-subtlest"
              />
            </template>
            Light</Menu.Item
          >
          <Menu.Item
            role="menuitem"
            @select="setTheme('dark')"
          >
            <template #prefix>
              <IconSprite
                :icon="theme === 'dark' ? 'check' : 'blank'"
                class="text-icon-subtlest"
              />
            </template>
            Dark</Menu.Item
          >
          <Menu.Item
            role="menuitem"
            @select="setTheme('auto')"
          >
            <template #prefix>
              <IconSprite
                :icon="theme === 'auto' ? 'check' : 'blank'"
                class="text-icon-subtlest"
              />
            </template>
            Use System Default ({{ themePreference === 'light' ? 'Light' : 'Dark' }})</Menu.Item
          >
        </Menu.Content>
      </Menu.Root>

      <Menu.Item
        label="Help Center"
        role="menuitem"
        icon="help"
        @select="onClickHelp"
      />
      <Menu.Divider />
      <Menu.Item
        label="Log out"
        role="menuitem"
        class="cursor-pointer"
        @select="logout"
      />
    </Menu.Content>
  </Menu.Root>
  <WorkspaceCreationDialog
    v-if="isCreating"
    @close="isCreating = false"
  />
</template>
