<script setup lang="ts">
import type { LibraryItem } from '@/modules/Library/libraryStore'
import { getFileExtension } from '@/modules/Workspaces/KnowledgeHub/Files/library-item'
import { computed } from 'vue'

const props = defineProps<{
  item: LibraryItem
}>()

/** Extensions that Chrome can open directly, rather than downloading */
const displayedInChrome = ['pdf', 'jpg', 'png', 'jpeg']

const actionName = computed<'open' | 'download'>(() => {
  if (props.item.type === 'text') {
    return 'open'
  }

  const ext = getFileExtension(props.item)
  if (ext && displayedInChrome.includes(ext)) {
    return 'open'
  }

  return 'download'
})
</script>

<template>
  <template v-if="item.type === 'file' && item.fileUrl">
    <a
      :href="item.fileUrl"
      :target="actionName === 'download' ? '_self' : '_blank'"
      class="outline-none"
    >
      <!-- 👆 Outline is removed because this is shown in a Menu, and would get immediately focused -->
      <slot :action="actionName" />
    </a>
  </template>
  <template v-else>
    <slot :action="actionName" />
  </template>
</template>
