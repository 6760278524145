<script setup lang="ts">
import { addSpace } from '@/backend/addSpace'
import { toast } from '@/shared/toast'
import AutosizedTextarea from '@/sharedComponents/AutosizedTextarea.vue'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import DividerLine from '@/uiKit/DividerLine.vue'
import IconButton from '@/uiKit/IconButton.vue'
import { captureException } from '@sentry/vue'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useUser } from '../IdentityAndAccess/useUser'
import ProjectBadge from '../Project/ProjectBadge.vue'
import IntegrationBadge from '../Workspaces/KnowledgeHub/Integrations/IntegrationBadge.vue'
import InitialQuestionSource, { type ProjectOrIntegration } from './InitialQuestionSource.vue'
import { serializeSpace } from './serializers'
import { useSpaceStore } from './useSpaceStore'

const router = useRouter()
const message = ref('')
const files = ref<File[]>([])

const { workspaceId } = useRouteParams()

const spaceStore = useSpaceStore()
const userStore = useUser()
const onSubmit = async () => {
  if (selectedProjects.value.length === 0) {
    toast.warning('Please select a source project')
    return
  }

  if (!message.value.trim()) {
    toast.warning('Please enter a query')
    return
  }

  const res = await addSpace({
    workspaceId: workspaceId.value,
    query: message.value,
    data: [
      ...selectedProjects.value.map((item) => ({
        type: 'project_id' as const,
        project_id: item.projectId,
      })),
      ...files.value.map((f) => ({
        type: 'attachment_url' as const,
        attachment_url: `https://storage.example.com/${f.name}`,
      })),
    ],
  })

  if (!res.ok) {
    captureException(new Error('Failed to add space'))
    toast.warning('Sorry, something went wrong. Please try again.')
    return
  }

  const serializedSpace = serializeSpace(res.data)
  spaceStore.allSpaces.push(serializedSpace)
  spaceStore.currentSpaceId = serializedSpace.id

  router.replace({
    name: 'AgenticChatSpace',
    params: {
      spaceId: res.data.id,
      workspaceId: workspaceId.value,
    },
  })
}

const selectedProjects = ref<ProjectOrIntegration[]>([])
const toggleProjectId = (project: ProjectOrIntegration) => {
  const index = selectedProjects.value.findIndex((p) => p.id === project.id)
  if (index === -1) {
    selectedProjects.value.push(project)
  } else {
    selectedProjects.value.splice(index, 1)
  }
}

// const dropzoneRef = useTemplateRef('dropzone')
// const onAddFiles = (newFiles: File[]) => {
//   files.value = files.value.concat(newFiles)
// }
// const { isOverDropZone } = useDropZone(dropzoneRef, {
//   onDrop: (droppedFiles) => {
//     if (!droppedFiles) return
//     onAddFiles(droppedFiles)
//   },
// })
</script>

<template>
  <div
    ref="dropzone"
    class="flex min-h-full min-w-full flex-col items-center justify-center p-8"
  >
    <form
      class="w-full max-w-4xl"
      @submit.prevent="onSubmit"
    >
      <div class="flex w-full items-center">
        <AutosizedTextarea
          v-model="message"
          aria-label="Ask a question"
          single-line
          autofocus
          class="grow bg-background-transparent text-display-lg-36px-light outline-none placeholder:text-text-disabled"
          :placeholder="`What would you like to know${userStore.user?.firstName ? ` ${userStore.user.firstName}` : ''}?`"
          @keydown.enter="onSubmit"
        />
        <IconButton
          icon="arrow-right"
          size="xxl"
          variant="black"
          rounded
          aria-label="Submit question"
          type="submit"
        />
      </div>
      <DividerLine
        color="subtle"
        :width="1"
        class="my-3"
      />
      <div class="flex gap-2">
        <InitialQuestionSource
          :value="selectedProjects"
          @select:item="toggleProjectId"
        />
        <!-- <FileUpload
          v-slot="{ triggerProps, hiddenInputProps }"
          name="file-upload"
          @change="onAddFiles"
        >
          <input v-bind="hiddenInputProps" />
          <DarwinButton
            v-bind="triggerProps"
            size="md"
            :variant="isOverDropZone ? 'blue' : 'neutral'"
            rounded
            type="button"
          >
            <template #leading-icon>
              <IconSprite icon="circle-plus" />
            </template>
            Attach
          </DarwinButton>
        </FileUpload> -->
      </div>
      <div class="mt-2 flex flex-wrap gap-2">
        <template
          v-for="item in selectedProjects"
          :key="item.id"
        >
          <ProjectBadge
            v-if="item.itemType === 'project'"
            size="md"
            :name="item.name"
            :cover-image-url="item.coverImageUrl"
          />
          <IntegrationBadge
            v-else
            :name="item.integration.name"
            size="md"
            status="started"
            :icon="item.integration.icon"
          />
        </template>
        <BadgeItem
          v-for="(file, index) in files"
          :key="file.name"
          :label="file.name"
          leading-icon="file-fill"
          trailing-icon="close"
          size="md"
          variant="neutral"
          @trailing-icon-click="files.splice(index, 1)"
        />
      </div>
    </form>
  </div>
</template>
