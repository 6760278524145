import { useFuzzySearch } from '@/sharedComposables/useFuzzySearch'
import type { Schema } from 'prosemirror-model'
import { SearchQuery } from 'prosemirror-search'
import type { EditorView } from 'prosemirror-view'
import { computed, ref, watch, type Ref, type ShallowRef } from 'vue'
import type { Mentionable } from '../ProseMirror.vue'
import { suggestionsPlugin } from './suggestions'

/**
 * Provides a plugin and helper functions for using mentionables in a
 * ProseMirror editor.
 */
export const useMentionablePlugin = ({
  view,
  mentionables,
  schema,
  triggerListMenuSelectionUp,
  triggerListMenuSelectionDown,
  triggerListMenuSelect,
}: {
  mentionables: Ref<Mentionable[] | undefined>
  view: ShallowRef<EditorView | undefined>
  schema: Schema
  triggerListMenuSelectionUp: () => void
  triggerListMenuSelectionDown: () => void
  triggerListMenuSelect: () => void
}) => {
  /**
   * DOM coordinates of the mentionables popup. Null if the popup is
   * closed.
   */
  const menuCoords = ref<{ left: number; top: number } | null>(null)

  /**
   * ProseMirror range representing the selection that has
   * triggered the mention. When selecting a mentionable, this
   * range is replaced with a mention badge.
   */
  const mentionMatchRange = ref<{ from: number; to: number } | null>(null)

  const searchText = ref<string>('')

  const plugin = suggestionsPlugin({
    // Called when mentionable selection should be shown
    onEnter: (args) => {
      const coords = args.view.coordsAtPos(args.range.from)
      menuCoords.value = {
        left: coords.left,
        top: coords.top,
      }
      mentionMatchRange.value = args.range
    },
    // Called when mentionable selection should be hidden
    onExit: () => {
      menuCoords.value = null
      mentionMatchRange.value = null
      searchText.value = ''
    },
    // Called when the mentionable selection is already triggered and the
    // search term changes.
    onChange: (args) => {
      // Remove the "@" character to get the search term
      const searchTerm = args.text.slice(1)

      // We take ",", ".", and whitespace as terminators for the search term
      const searchWithoutTerminators = searchTerm.replace(/[\s,.]+$/, '')
      const isTerminatingSearch = searchTerm.match(/[\s,.]$/)

      // All mentionables that start with the search term
      const matches =
        mentionables.value?.filter((m) =>
          m.label.toLowerCase().startsWith(searchWithoutTerminators.toLowerCase()),
        ) || []

      const exactMatch = mentionables.value?.find(
        (m) => m.label.toLowerCase() === searchWithoutTerminators.toLowerCase(),
      )

      /**
       * We should add a mention in the case where:
       * - There is only 1 mentionable that starts with the search term
       * - The search term is an exact match to a mentionable
       * - The search term is terminated by a comma, period, or whitespace
       */
      const shouldAddMention = matches.length === 1 && exactMatch && isTerminatingSearch
      if (shouldAddMention) {
        onSelectItem(exactMatch)
        return
      }

      mentionMatchRange.value = args.range
      searchText.value = searchTerm
    },
    // Called when mentionable selection is already triggered and the user
    // presses a key. Is different from onChange because onChange
    // is only called when the search term changes (e.g. if a character
    // is pressed), whereas onKeyDown is called for all key presses,
    // (e.g. arrow keys will trigger onKeyDown but not onChange).
    onKeyDown: (e) => {
      // if up or down we move the active item in the ListMenu of suggestions
      if (e.event.key === 'ArrowDown') {
        e.event.preventDefault()
        triggerListMenuSelectionDown()
        return true
      }
      if (e.event.key === 'ArrowUp') {
        e.event.preventDefault()
        triggerListMenuSelectionUp()
        return true
      }
      if (e.event.key === 'Enter') {
        e.event.preventDefault()
        e.event.stopPropagation()
        triggerListMenuSelect()
        return true
      }
    },
  })

  /** Replaces the provided ProseMirror selection with a mention Node. */
  const addMentionAtPosition = (from: number, to: number, item: Mentionable) => {
    const mentionNode = schema.nodes.mention.create(item)
    if (!view.value) return

    const tr = view.value.state.tr
    tr.replaceWith(from, to, mentionNode)
    view.value.dispatch(tr)
  }

  /** Called when selecting a mention from the dropdown */
  const onSelectItem = (item: Mentionable) => {
    if (!mentionMatchRange.value) {
      throw new Error('No mention match range when selecting mention')
    }

    addMentionAtPosition(mentionMatchRange.value.from, mentionMatchRange.value.to, item)
  }

  const filteredMentionables = useFuzzySearch({
    items: computed(() => mentionables.value || []),
    keys: ['label'],
    searchTerm: searchText,
  })

  /**
   * Format the filtered mentions into objects accepted by the menu.
   */
  const filteredItems = computed(() =>
    filteredMentionables.value.map((item) => ({
      id: item.id,
      data: item,
    })),
  )

  /**
   * When the view first renders we need to serach for serialized mentions
   * in the text and replace them with ProseMirror mention Nodes. This
   * can't be done by the markdown parser because mentions rely on the
   * array of mentionable inputs (a serialized mention is meaningless
   * in the UI without the knowledge of what the IDs refer to).
   */
  watch(view, (newView) => {
    if (!newView || !mentionables.value) {
      return
    }
    const search = new SearchQuery({
      search: '@<(.*?)>',
      regexp: true,
    })

    let match = search.findNext(newView.state)
    while (match) {
      const id = match.match ? match.match[1] : undefined
      const matchingItem = mentionables.value.find((i) => i.id === id)
      if (matchingItem) {
        addMentionAtPosition(match.from, match.to, matchingItem)
      } else {
        addMentionAtPosition(match.from, match.to, {
          group: 'unknown',
          icon: 'help',
          id: id || 'unknown',
          label: 'Unknown item',
        })
      }

      match = search.findNext(newView.state, match.from + 1)
    }
  })

  return {
    plugin,
    menuCoords,
    onSelectItem,
    filteredItems,
  }
}
