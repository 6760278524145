<script setup lang="ts">
import { omit } from '@/shared/utils'
import IconSprite from '@/uiKit/IconSprite.vue'
import InlineTextField from '@/uiKit/InlineTextField.vue'
import ListMenuCheckboxItem from '@/uiKit/ListMenuCheckboxItem.vue'
import ListMenuContainer from '@/uiKit/ListMenuContainer.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import Menu from '@/uiKit/Menu'
import NumberInput from '@/uiKit/NumberInput.vue'
import Select from '@/uiKit/Select'
import SwitchButton from '@/uiKit/SwitchButton.vue'
import ZagPopover from '@/uiKit/ZagPopover.vue'
import type { Context as PopoverContext } from '@zag-js/popover'
import { computed, reactive, ref, watch } from 'vue'
import type { NumberPropertyFormat } from './Properties/types'

/**
 * Renders a popover that allows the user to customize the format of a number property.
 */

const props = defineProps<
  NumberPropertyFormat & {
    positioning: PopoverContext['positioning']
    isCustomFormat: boolean
  }
>()

const emit = defineEmits<{
  (e: 'change:isCustom', isCustom: boolean): void
  (e: 'change:format', format: NumberPropertyFormat): void
}>()

const localFormat = reactive<NumberPropertyFormat>({
  decimalPlaces: props.decimalPlaces,
  negativeFormat: props.negativeFormat,
  thousandsSeparator: props.thousandsSeparator,
  rightAlign: props.rightAlign,
})
watch(localFormat, (newFormat) => {
  emit('change:format', newFormat)
})

const MAX_DECIMALS = 9
const MIN_DECIMALS = 0
/**
 * We apply a bit of a hack to make it so that the 'auto' value can still
 * be used with the number input, by representing 'auto' as an out of range
 * value.
 */
const decimals = ref<number>(
  props.decimalPlaces === 'auto' ? MAX_DECIMALS + 1 : props.decimalPlaces,
)
const onDecimalsChange = (value: number) => {
  decimals.value = value
  if (value < MIN_DECIMALS || value > MAX_DECIMALS) {
    localFormat.decimalPlaces = 'auto'
  } else {
    localFormat.decimalPlaces = value
  }
}

const negativeFormatItems: Record<
  NumberPropertyFormat['negativeFormat'],
  {
    label: string
    value: NumberPropertyFormat['negativeFormat']
    critical: boolean
  }
> = {
  minus: {
    value: 'minus',
    label: '-100',
    critical: false,
  },
  colored: {
    value: 'colored',
    label: '100',
    critical: true,
  },
  parentheses: {
    value: 'parentheses',
    label: '(100)',
    critical: false,
  },
  colored_parentheses: {
    value: 'colored_parentheses',
    label: '(100)',
    critical: true,
  },
}
const selectedNegativeFormat = computed(() => negativeFormatItems[localFormat.negativeFormat])
</script>

<template>
  <ZagPopover
    :positioning="positioning"
    close-on-interact-outside
  >
    <template #trigger="{ api, getTriggerProps }">
      <slot
        :get-trigger-props="getTriggerProps"
        :is-open="api.open"
      />
    </template>
    <template #content="{ api }">
      <ListMenuContainer
        class="min-w-64 p-0.5 [&[hidden]]:!hidden"
        v-bind="api.getContentProps()"
      >
        <ListMenuItem
          label="Custom data format"
          default-hover-disabled
          tabindex="-1"
          @select="$emit('change:isCustom', !isCustomFormat)"
        >
          <template #suffix="{ label }">
            <SwitchButton
              :checked="isCustomFormat"
              :aria-label="label"
            />
          </template>
        </ListMenuItem>
        <Menu.Divider />
        <ListMenuItem
          label="Decimals"
          default-hover-disabled
          :disabled="!isCustomFormat"
          tabindex="-1"
        >
          <template #suffix="{ label }">
            <!--
              This number input has a bit of a hack to make it so that out
              of range values are displayed (and sent to the backend) as
              "Auto".
            -->
            <NumberInput
              class="-mr-1"
              :disabled="!isCustomFormat"
              :value="decimals"
              name="decimals"
              :min="MIN_DECIMALS - 1"
              :max="MAX_DECIMALS + 1"
              :label="{ ariaLabel: 'Decimals' }"
              size="xs"
              :input-attrs="{ size: 4, 'aria-label': label }"
              @change="onDecimalsChange"
            >
              <template
                v-if="decimals < MIN_DECIMALS || decimals > MAX_DECIMALS"
                #input="{ inputProps }"
              >
                <InlineTextField
                  :input-attrs="{ ...inputProps, value: 'Auto' }"
                  :disabled="!isCustomFormat"
                  size="xs"
                />
              </template>
            </NumberInput>
          </template>
        </ListMenuItem>
        <Select
          :items="Object.values(negativeFormatItems)"
          :positioning="{
            placement: 'right-start',
            offset: {
              mainAxis: 2,
              crossAxis: -2,
            },
          }"
          @change="localFormat.negativeFormat = $event"
        >
          <template #trigger="{ getTriggerProps, isOpen: submenuIsOpen, getLabelProps }">
            <ListMenuItem
              label="Negatives"
              :active="submenuIsOpen"
              v-bind="omit(getTriggerProps(), 'onSelect')"
              :disabled="!isCustomFormat"
            >
              <div
                v-bind="getLabelProps()"
                class="ml-1"
              >
                Negatives
              </div>
              <template #suffix>
                <div class="flex items-center gap-2">
                  <div
                    :class="[
                      selectedNegativeFormat.critical ? 'text-text-critical' : 'text-text',
                      !isCustomFormat && '!text-text-disabled',
                    ]"
                  >
                    {{ selectedNegativeFormat.label }}
                  </div>
                  <IconSprite icon="chevron-right" />
                </div>
              </template>
            </ListMenuItem>
          </template>
          <template #item="{ api: negativesApi, item }">
            <ListMenuItem
              :label="item.label"
              :critical="item.critical"
              :active="negativesApi.highlightedValue === item.value"
            >
              <template #prefix>
                <IconSprite
                  icon="check"
                  class="text-icon-subtle"
                  :class="!negativesApi.value.includes(item.value) && 'opacity-0'"
                />
              </template>
            </ListMenuItem>
          </template>
        </Select>
        <Menu.Divider />
        <ListMenuCheckboxItem
          default-hover-disabled
          :active="false"
          :checked="localFormat.thousandsSeparator"
          always-visible
          label="Thousands separator"
          :disabled="!isCustomFormat"
          tabindex="-1"
          @select="localFormat.thousandsSeparator = !localFormat.thousandsSeparator"
        />
        <ListMenuCheckboxItem
          default-hover-disabled
          :active="false"
          :disabled="!isCustomFormat"
          :checked="localFormat.rightAlign"
          always-visible
          label="Right alignment"
          tabindex="-1"
          @select="localFormat.rightAlign = !localFormat.rightAlign"
        />
      </ListMenuContainer>
    </template>
  </ZagPopover>
</template>
