<script setup lang="ts">
import IconSprite from '@/uiKit/IconSprite.vue'
import { useGroundingToggle } from './useGroundingToggle'

const { groundingConditions } = useGroundingToggle()
</script>

<template>
  <div
    class="max-w-[300px]"
    data-test="grounding-tooltip"
  >
    <p>The following conditions must be met in order to enable AI Citations:</p>
    <ul
      class="mt-2 flex flex-col gap-1"
      data-theme="dark"
    >
      <li
        v-for="{ fulfilled, label } in groundingConditions"
        :key="label"
        class="flex items-center text-text-subtle"
        :class="{ 'text-text-success': fulfilled }"
      >
        <!-- 18px is line height on label text -->
        <div class="mr-1 flex size-[18px] w-4 shrink-0 items-center justify-center self-baseline">
          <IconSprite
            v-if="fulfilled"
            icon="check"
          />
          <div
            v-else
            class="size-[3px] rounded-full bg-text-subtle"
          />
        </div>
        {{ label }}
      </li>
    </ul>
  </div>
</template>
