<script setup lang="ts">
import { useWorkspaces } from '@/modules/Workspaces/useWorkspaces'
import AvatarIcon from '@/uiKit/AvatarIcon.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import Menu from '@/uiKit/Menu'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { PERMISSIONS_DIALOG_ID } from './consts'
export type ProjectDefaultRole = 'editor' | 'reader' | 'noaccess'

const props = withDefaults(
  defineProps<{
    defaultRole?: ProjectDefaultRole
  }>(),
  { defaultRole: 'reader' },
)
defineEmits<{
  (e: 'update:projectDefaultRole', role: (typeof LIST_ITEMS)[number]['role']): void
}>()

const workspaceStore = useWorkspaces()
const { currentWorkspace } = storeToRefs(workspaceStore)

const roleTitleMap: Record<ProjectDefaultRole, string> = {
  editor: 'Can edit',
  reader: 'Can view',
  noaccess: 'Has no access',
}

const dropdownTriggerText = computed(() => roleTitleMap[props.defaultRole].toLowerCase())

const LIST_ITEMS = [
  {
    role: 'noaccess',
    title: roleTitleMap.noaccess,
    description:
      'This project will be private by default. Only invited users will be able to see it in their workspace.',
  },
  {
    role: 'reader',
    title: roleTitleMap.reader,
    description: 'Can access the project but cannot modify properties or data.',
  },
  {
    role: 'editor',
    title: roleTitleMap.editor,
    description:
      'Everyone in the workspace will be able to see and open this project, even non-invited users.',
  },
] as const
</script>

<template>
  <Menu.Root
    v-slot="{ getTriggerProps, menu }"
    :positioning="{
      offset: {
        mainAxis: 5,
      },
      sameWidth: true,
    }"
    :teleport-to="`#${PERMISSIONS_DIALOG_ID}`"
    close-on-select
  >
    <ListMenuItem
      class="cursor-pointer select-none py-1.5 focus:bg-background-transparent-hovered"
      :active="menu.open"
      :label="`Everyone in ${currentWorkspace?.name} workspace`"
      v-bind="getTriggerProps()"
      role="combobox"
      @select="menu.setOpen(!menu.open)"
    >
      <template #prefix>
        <AvatarIcon
          :url="currentWorkspace?.iconUrl"
          :full-text="currentWorkspace?.name"
          size="sm"
          :class="!currentWorkspace?.iconUrl && 'min-w-max'"
          :error-condition="currentWorkspace?.iconDownloadError"
          :loading-condition="currentWorkspace?.iconUploading"
        />
      </template>
      <template #suffix>
        <div class="flex w-max items-center gap-1 text-text">
          <div class="grow-0 text-nowrap text-text-subtle">
            {{ dropdownTriggerText }}
          </div>
          <div class="flex min-h-5 items-center justify-center text-icon-subtlest">
            <IconSprite icon="chevron-select" />
          </div>
        </div>
      </template>
    </ListMenuItem>
    <Menu.Content>
      <Menu.Item
        v-for="item in LIST_ITEMS"
        :key="item.role"
        @select="$emit('update:projectDefaultRole', item.role)"
      >
        <div class="flex items-start">
          <div class="flex size-5 min-w-5 items-center justify-center">
            <IconSprite
              v-if="defaultRole === item.role"
              class="text-icon-subtle"
              icon="check"
            />
          </div>
          <div class="px-1 py-0.5 text-left">
            <div>{{ item.title }}</div>
            <div class="text-text-subtle">{{ item.description }}</div>
          </div>
        </div>
      </Menu.Item>
    </Menu.Content>
  </Menu.Root>
</template>
