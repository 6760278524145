import { listConnections } from '@/backend/listConnections'
import { FeatureFlag } from '@/modules/App/featureFlags'
import { useFeatureFlags } from '@/modules/App/useFeatureFlags'
import { integrations } from '@/modules/Workspaces/KnowledgeHub/Integrations/integrations'
import type {
  Integration,
  IntegrationConnection,
} from '@/modules/Workspaces/KnowledgeHub/Integrations/integrationTypes'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'

export const useIntegrationStore = defineStore('integrations', () => {
  const { workspaceId } = useRouteParams()

  const hasAccess = ref<boolean>()
  const connections = ref<IntegrationConnection[]>([])

  /** All integrations that are available, including ones not connected. */
  const allIntegrations = ref(integrations)

  /** Integrations that have not been connected*/
  const unconnectedIntegrations = computed<Integration[]>(() => {
    const list = Object.values(allIntegrations.value)
    return list.filter((i) => !connections.value.some((c) => c.integration_id === i.id))
  })

  const startedConnections = computed(() => {
    return connections.value.filter((c) => c.status === 'started')
  })

  const knowledgeHubEnabled = useFeatureFlags(FeatureFlag.KNOWLEDGE_HUB)
  async function load() {
    if (!workspaceId.value || !knowledgeHubEnabled.value) return
    const res = await listConnections({ workspaceId: workspaceId.value })
    // If the user is not an admin, he cannot access connections.
    // In the future, this logic will change in the backend, so the error is
    // the best way to check access for now.
    hasAccess.value = res.ok
    connections.value = (res.ok ? res.data.data : []) as IntegrationConnection[]
  }

  watch(() => workspaceId.value, load, { immediate: true })

  return {
    connections,
    hasAccess,
    load,
    startedConnections,
    unconnectedIntegrations,
  }
})
