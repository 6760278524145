<script setup lang="ts">
import { useLibraryStore } from '@/modules/Library/libraryStore'
import { useFileTableContext } from '@/modules/Workspaces/KnowledgeHub/Files/context'
import {
  documentFileType,
  getFileExtension,
  type ExtensionOrDocument,
} from '@/modules/Workspaces/KnowledgeHub/Files/library-item'
import { useProfileImage } from '@/modules/WorkspaceSettings/useProfileImage'
import { useWorkspaceMembers } from '@/modules/WorkspaceSettings/useWorkspaceMembers'
import AvatarIcon from '@/uiKit/AvatarIcon.vue'
import CheckBox from '@/uiKit/CheckBox.vue'
import IconButton from '@/uiKit/IconButton.vue'
import Menu from '@/uiKit/Menu'
import { computed } from 'vue'

const context = useFileTableContext()
const libraryStore = useLibraryStore()
const membersStore = useWorkspaceMembers()

const hasAppliedFilter = computed(() => {
  return context.filterOwners.value.length > 0 || context.filterFileType.value.length > 0
})

const fileTypeOptions = computed<{ label: string; type: ExtensionOrDocument }[]>(() => {
  const usedTypes = new Set<ExtensionOrDocument>()
  for (const item of libraryStore.libraryItems) {
    if (item.type === 'text') {
      usedTypes.add(documentFileType)
      continue
    }

    const ext = getFileExtension(item)
    if (ext) {
      usedTypes.add(ext)
    }
  }

  return [...usedTypes]
    .map<{ label: string; type: ExtensionOrDocument }>((type: ExtensionOrDocument) => {
      const label = type === documentFileType ? 'Document' : type.toUpperCase()
      return { label, type }
    })
    .sort((a, b) => a.label.localeCompare(b.label))
})

function memberProfileImageUrl(userId: string) {
  return useProfileImage(userId).value
}

function onMemberSelectionChange(memberId: string) {
  const shouldBecomeSelected = !context.filterOwners.value.includes(memberId)
  if (shouldBecomeSelected) {
    context.filterOwners.value.push(memberId)
  } else {
    context.filterOwners.value = context.filterOwners.value.filter((id) => id !== memberId)
  }
}

function onFileTypeSelectionChange(type: ExtensionOrDocument) {
  const index = context.filterFileType.value.indexOf(type)
  if (index >= 0) {
    context.filterFileType.value.splice(index, 1)
  } else {
    context.filterFileType.value.push(type)
  }
}
</script>

<template>
  <Menu.Root
    v-slot="{ getTriggerProps }"
    :positioning="{ placement: 'bottom-end', offset: { mainAxis: 4 } }"
  >
    <IconButton
      icon="filter"
      size="lg"
      rounded
      :variant="hasAppliedFilter ? 'brand' : 'outline'"
      wide
      v-bind="getTriggerProps()"
    />

    <Menu.Content class="min-w-48 max-w-80">
      <Menu.GroupTitle label="Owner" />
      <Menu.Item
        v-for="member in membersStore.workspaceMembers"
        :key="member.id"
        @select="onMemberSelectionChange(member.id)"
      >
        <template #prefix>
          <CheckBox
            :checked="context.filterOwners.value.includes(member.id)"
            class="mx-1"
          />
        </template>
        <template #default>
          <div class="flex gap-1.5">
            <AvatarIcon
              shape="circle"
              size="xs"
              :url="memberProfileImageUrl(member.id)"
              :full-text="member.fullName"
            />
            {{ member.fullName }}
          </div>
        </template>
      </Menu.Item>
      <Menu.GroupTitle label="Type" />
      <Menu.Item
        v-for="option in fileTypeOptions"
        :key="option.type"
        @select="onFileTypeSelectionChange(option.type)"
      >
        <template #prefix>
          <CheckBox
            :checked="context.filterFileType.value.includes(option.type)"
            class="mx-1"
          />
        </template>
        <template #default>
          {{ option.label }}
        </template>
      </Menu.Item>
    </Menu.Content>
  </Menu.Root>
</template>
