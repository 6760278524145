<script setup lang="ts">
import { type StatusFilter, type StatusFilterMatcherValue } from '@/modules/Project/Filters/types'

import Menu from '@/uiKit/Menu'
import type { UnwrapRef } from 'vue'
import { computed, ref } from 'vue'
import FilterItemContainer from './FilterItemContainer.vue'
import { statusFilterItems, statusFilterMap } from './utils'

const props = defineProps<{
  filter: StatusFilter
}>()

const emit = defineEmits<{
  (e: 'delete'): void
  (e: 'update', filter: StatusFilter): void
}>()

const onNewValues = (value: StatusFilterMatcherValue) => {
  const newFilter: StatusFilter = {
    ...props.filter,
    matcher: { ...props.filter.matcher, values: [value] },
  }
  emit('update', newFilter)
  isOpen.value = false
}

const isOpen = ref(false)

const selectedLabel = computed(() => {
  const value = props.filter.matcher.values[0]
  if (value in statusFilterMap) {
    return statusFilterMap[value as keyof typeof statusFilterMap]
  }
  return value
})

const filteredItems = ref<UnwrapRef<typeof statusFilterItems.options>>([])
</script>

<template>
  <FilterItemContainer
    icon="status"
    applied-to="Status"
    @delete="$emit('delete')"
  >
    <Menu.Root
      v-slot="{ getTriggerProps, menu }"
      :positioning="{ placement: 'top-start' }"
      close-on-select
    >
      <button
        class="flex cursor-pointer items-center gap-0.5 px-1.5 py-1 text-sm-12px-default lowercase text-text-subtle hover:bg-background-transparent-hovered active:bg-background-transparent-pressed"
        :class="{ 'bg-background-transparent-hovered': menu.open }"
        v-bind="getTriggerProps()"
      >
        {{ selectedLabel }}
      </button>
      <Menu.Content>
        <Menu.Search
          :items="statusFilterItems.options"
          key-or-predicate="label"
          @change="filteredItems = $event"
        />
        <Menu.Item
          v-for="item in filteredItems"
          :key="item.value"
          :label="item.label ?? item.value"
          @select="onNewValues(item.value)"
        />
      </Menu.Content>
    </Menu.Root>
  </FilterItemContainer>
</template>
