import { captureException } from '@sentry/vue'
import { computed, type ComputedRef } from 'vue'
import { useIntegrationStore } from '../useIntegrationStore'
import type { Integration, StartedConnection } from './integrationTypes'
import { integrations } from './integrations'

/**
 * Represents an active integration connection, with an `integration` property to
 * represent the integration's functionality within the UI.
 */
export type StartedIntegration = StartedConnection & {
  integration: Integration
}

export const useStartedIntegrations = (): ComputedRef<StartedIntegration[]> => {
  const integrationStore = useIntegrationStore()

  return computed(() =>
    integrationStore.startedConnections.reduce<StartedIntegration[]>((acc, c) => {
      const integration = integrations[c.integration_id]

      if (integration) {
        acc.push({
          ...c,
          integration,
        })
      } else {
        // We shouldn't ever get here. If we do, then this workspace is connected to
        // an integration that we haven't defined in the frontend.
        captureException(new Error('Integration not found'), {
          data: { integrationId: c.integration_id },
        })
      }

      return acc
    }, []),
  )
}
