<script setup lang="ts">
import { computed } from 'vue'
import { FeatureFlag } from '../App/featureFlags'
import { useFeatureFlags } from '../App/useFeatureFlags'
import AgenticSpaceComplete from './AgenticSpaceComplete.vue'
import AgenticSpaceInProgress from './AgenticSpaceInProgress.vue'
import { useSpaceChannel } from './useSpaceChannel'
import { useSpaceStore } from './useSpaceStore'

const props = defineProps<{ spaceId: string }>()

useSpaceChannel(computed(() => props.spaceId))

const spaceStore = useSpaceStore()

const isDevMode = useFeatureFlags(FeatureFlag.DEV_MODE)

defineOptions({ inheritAttrs: false })
</script>

<template>
  <AgenticSpaceInProgress v-if="isDevMode || spaceStore.space?.status === 'running'" />
  <AgenticSpaceComplete v-if="spaceStore.space?.status === 'success'" />
</template>
