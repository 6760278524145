<script setup lang="ts">
import type { PropertyType } from '@/backend/types'
import CheckBox from '@/uiKit/CheckBox.vue'
import Menu from '@/uiKit/Menu'
import SegmentedControl from '@/uiKit/SegmentedControl.vue'
import { FeatureFlag } from '../App/featureFlags'
import { useFeatureFlags } from '../App/useFeatureFlags'
import { TYPE_ICON } from './icons'

defineProps<{
  propertyId: string
  label: string
  type: PropertyType
  checked: boolean
  readonly: boolean
}>()

defineEmits<{
  (e: 'toggle:visibility'): void
  (e: 'set:readonly', readonly: boolean): void
}>()

const canAssignWork = useFeatureFlags(FeatureFlag.WORK_ASSIGNENT)
</script>

<template>
  <Menu.Item
    :label="label"
    :checked="checked"
    :icon="TYPE_ICON[type]"
    @select="$emit('toggle:visibility')"
  >
    <template #prefix>
      <CheckBox :checked="checked" />
    </template>
    <template #suffix>
      <SegmentedControl
        v-if="canAssignWork"
        :items="[
          { label: 'Read', value: 'read' },
          { label: 'Write', value: 'read-write' },
        ]"
        :value="readonly ? 'read' : 'read-write'"
        :name="`readonly-${propertyId}`"
        size="sm"
        @change="$emit('set:readonly', $event === 'read')"
        @click.stop
      />
    </template>
  </Menu.Item>
</template>
