<script setup lang="ts">
import { ref } from 'vue'

import { copyToClipboard } from '@/shared/clipboard'
import ConfirmationDialog from '@/uiKit/ConfirmationDialog.vue'
import IconButton from '@/uiKit/IconButton.vue'

import { removeProperty } from '@/backend/removeProperty'
import Menu from '@/uiKit/Menu'
import ToolTip from '@/uiKit/ToolTip.vue'
import { useProject } from './useProject'
import { useProperty } from './useProperty'

const props = defineProps<{ workspaceId: string }>()

const isDeleting = ref<boolean>(false)
const open = ref<boolean>(false)

defineExpose({ open })

const projectStore = useProject()
const propertyStore = useProperty()

const onDelete = async () => {
  if (projectStore.projectId && projectStore.selectedPropertyId) {
    await removeProperty(props.workspaceId, projectStore.projectId, projectStore.selectedPropertyId)
  }
  propertyStore.sidebarIsOpen = false
  isDeleting.value = false
}
</script>

<template>
  <Menu.Root
    v-slot="{ getTriggerProps }"
    :open="open"
    placement="bottom-end"
    trigger-element="div"
    :offset="{ mainAxis: 4 }"
    v-bind="$attrs"
    @change:open="open = !open"
  >
    <IconButton
      v-bind="getTriggerProps()"
      icon="more-dots"
      data-test="property-sidebar-top-menu-trigger"
      size="md"
      variant="transparent"
      aria-label="More options"
    />
    <Menu.Content class="w-[296px] min-w-56 p-0.5">
      <template v-if="projectStore.selectedProperty">
        <ToolTip
          class="w-full"
          :arrow="true"
          :placement="{ allowedPlacements: ['left'] }"
          :title="projectStore.selectedPropertyId ?? 'Copy property ID'"
        >
          <Menu.Item
            element="button"
            icon="copy"
            @select="copyToClipboard('Property ID', projectStore.selectedProperty.id)"
          >
            Copy property ID
          </Menu.Item>
        </ToolTip>
        <ToolTip
          class="w-full"
          :arrow="true"
          :placement="{ allowedPlacements: ['left'] }"
          :title="projectStore.selectedProperty?.slug ?? 'Copy property slug'"
        >
          <Menu.Item
            element="button"
            icon="copy"
            @select="copyToClipboard('Property slug', projectStore.selectedProperty.slug)"
          >
            Copy property slug
          </Menu.Item>
        </ToolTip>
      </template>
      <Menu.Divider />
      <Menu.Item
        element="button"
        icon="trash"
        critical
        @select="isDeleting = true"
      >
        Delete property
      </Menu.Item>
    </Menu.Content>
  </Menu.Root>

  <ConfirmationDialog
    id="delete-property-confirmation"
    :open="isDeleting"
    title="Delete this property?"
    description="This property will be deleted immediately. You can't undo this action."
    @confirm="onDelete"
    @close="isDeleting = false"
  />
</template>
