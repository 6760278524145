<script setup lang="ts">
import { usePermissionsStore } from '@/modules/IdentityAndAccess/permissionsStore'
import type { Project } from '@/modules/Projects/useProjects'
import { copyToClipboard } from '@/shared/clipboard'
import { doExportProject } from '@/shared/utils/exports'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import IconButton from '@/uiKit/IconButton.vue'
import Menu from '@/uiKit/Menu'
import ToolTip from '@/uiKit/ToolTip.vue'
import { ref, watch } from 'vue'

const props = withDefaults(
  defineProps<{
    project: Project
    open?: boolean
  }>(),
  {
    open: false,
  },
)

const emit = defineEmits<{
  rename: []
  delete: []
}>()

const { workspaceId } = useRouteParams()
const permissionsStore = usePermissionsStore()
const { captureAnalyticsEvent } = useAnalytics()

const localProjectName = ref('')
watch(
  () => props.project.name,
  (next) => (localProjectName.value = next || ''),
  { immediate: true },
)

function onCrumbClick(isOpen: boolean) {
  captureAnalyticsEvent(ANALYTICS_EVENT.OPEN_CRUMB_MENU, {
    workspaceId: workspaceId.value,
    projectId: props.project.id,
    value: isOpen,
  })
}

const onRename = () => {
  if (props.project?.name) {
    localProjectName.value = props.project.name
  }
  emit('rename')
}

const onExport = (format: 'csv' | 'xlsx') => {
  captureAnalyticsEvent(ANALYTICS_EVENT.PROJECT_EXPORTED)
  doExportProject(workspaceId.value, props.project.id, 200, 3000, format) // keep trying for max 200 times (sleep 3000ms = 3s) = 10m
}
</script>

<template>
  <Menu.Root v-slot="{ getTriggerProps }">
    <IconButton
      size="sm"
      aria-label="Open project menu"
      variant="transparent"
      v-bind="{ ...getTriggerProps(), ...$attrs }"
      class="text-text-subtle"
      icon="more-dots"
      @click="onCrumbClick(!!getTriggerProps()['aria-expanded'])"
    />
    <Menu.Content class="w-[280px] min-w-max">
      <ToolTip
        class="w-full"
        :arrow="true"
        :placement="{ allowedPlacements: ['right'] }"
        :title="project.id"
      >
        <Menu.Item
          label="Copy project ID"
          icon="copy"
          @select="copyToClipboard('Project ID', project.id)"
        />
      </ToolTip>
      <Menu.Item
        v-if="permissionsStore.currentProjectPermissions.update_projects"
        label="Rename"
        icon="edit"
        @select="onRename"
      />
      <template v-if="permissionsStore.currentProjectPermissions.manage_project_exports">
        <Menu.Item
          label="Export as CSV"
          icon="arrow-bottom-circle"
          @select="onExport('csv')"
        />
        <Menu.Item
          label="Export as XLSX"
          icon="arrow-bottom-circle"
          @select="onExport('xlsx')"
        />
      </template>
      <template v-if="permissionsStore.currentProjectPermissions.delete_projects">
        <Menu.Divider />
        <Menu.Item
          critical
          label="Delete project"
          icon="trash"
          @select="$emit('delete')"
        />
      </template>
    </Menu.Content>
  </Menu.Root>
</template>
