<script setup lang="ts">
import { deleteConnection } from '@/backend/deleteConnection'
import { GooglePickerClosedError } from '@/modules/Workspaces/KnowledgeHub/Integrations/GoogleDrive/errors'
import { useGoogleDriveConnection } from '@/modules/Workspaces/KnowledgeHub/Integrations/GoogleDrive/useGoogleDriveConnection'
import IntegrationCard from '@/modules/Workspaces/KnowledgeHub/Integrations/IntegrationCard.vue'
import IntegrationConnectionRow from '@/modules/Workspaces/KnowledgeHub/Integrations/IntegrationConnectionRow.vue'
import { integrations } from '@/modules/Workspaces/KnowledgeHub/Integrations/integrations'
import PageContainer from '@/modules/Workspaces/KnowledgeHub/Layout/PageContainer.vue'
import PageTitle from '@/modules/Workspaces/KnowledgeHub/Layout/PageTitle.vue'
import PageTitleBar from '@/modules/Workspaces/KnowledgeHub/Layout/PageTitleBar.vue'
import { useIntegrationPolling } from '@/modules/Workspaces/KnowledgeHub/useIntegrationPolling'
import { useWorkspaceMembers } from '@/modules/WorkspaceSettings/useWorkspaceMembers'
import { toast } from '@/shared/toast'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import { computed, ref, toValue } from 'vue'
import { useIntegrationStore } from './useIntegrationStore'

const { workspaceId } = useRouteParams()
const integrationStore = useIntegrationStore()
const workspaceMembersStore = useWorkspaceMembers()
const googleDriveConnection = useGoogleDriveConnection()

/**
 * When we configure a connection, we need to reload data to show connections.
 * In the meantime we should prevent users from restarting the configuration flow.
 */
const pendingConfigCompletion = ref<string[]>([])

useIntegrationPolling()

async function removeConnection(c: (typeof integrationStore.connections)[number]) {
  if (!workspaceId.value) return
  await deleteConnection({
    connectionId: c.id,
    integrationId: c.integration_id,
    workspaceId: workspaceId.value,
  })
  integrationStore.load()
}

const connections = computed(() => {
  return integrationStore.connections.map((c) => ({
    ...c,
    user: workspaceMembersStore.getMember(c.user_id),
    name: integrations[c.integration_id].name,
    icon: integrations[c.integration_id].icon,
  }))
})

const startConnection = (connectionId: string) => {
  const pending = toValue(pendingConfigCompletion)
  if (pending.includes(connectionId)) {
    return
  }

  pending.push(connectionId)

  googleDriveConnection
    .pick(connectionId)
    .then(() => integrationStore.load())
    .catch((e) => {
      if (e instanceof GooglePickerClosedError) return

      toast.error(e.message || 'Failed to start connection')
      throw e
    })
    .finally(() => {
      pending.splice(pending.indexOf(connectionId), 1)
    })
}
</script>

<template>
  <PageContainer class="px-16">
    <PageTitleBar>
      <template #title>
        <PageTitle :count="connections.length">Integrations</PageTitle>
      </template>
    </PageTitleBar>

    <template v-if="connections.length">
      <h2 class="mt-10 text-md-13px-bold">Enabled</h2>
      <p class="text-sm-12px-light text-text-subtle">Manage your connected integrations</p>

      <IntegrationConnectionRow
        v-for="connection in connections"
        :key="connection.integration_id"
        :connection="connection"
        class="relative mt-6"
        :starting="pendingConfigCompletion.includes(connection.id)"
        @remove="removeConnection(connection)"
        @start="startConnection(connection.id)"
      />
    </template>

    <template v-if="integrationStore.unconnectedIntegrations.length">
      <h2 class="mt-10 text-md-13px-bold">Explore more</h2>
      <p class="text-sm-12px-light text-text-subtle">
        Find more integrations to connect to your account
      </p>

      <div class="mx-auto mt-10 grid grid-cols-[repeat(auto-fill,minmax(220px,1fr))] gap-1">
        <IntegrationCard
          v-for="integration in integrationStore.unconnectedIntegrations"
          :key="integration.name"
          :name="integration.name"
          :description="integration.description ?? ''"
          :icon="integration.icon"
        />
      </div>
    </template>
  </PageContainer>
</template>
