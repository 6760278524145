export function capitalize(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

/** Returns a pluralized or nonpluralized string based on the count. */
export const pluralize = (count: number, singular: string, plural: string): string =>
  count === 1 ? singular : plural

export const isValidUrl = (string: string) => {
  try {
    new URL(string)
    return true
  } catch {
    return false
  }
}

/**
 * Returns true if a given string has a URL scheme (e.g. "http://, ftp://").
 */
export const hasUrlScheme = (string: string) => string.match(/^\w+:\/\//) !== null

/**
 * Number strings are formatted differently in different locales. i.e.
 * - UK: 123,456.78
 * - France: 123 456,78
 * - Germany: 123.456,78
 * - India: 1,23,456.78
 *
 * This function takes a string that represents a number in the user's locale and
 * normalises it to a format that can be parsed as a number in JavaScript and
 * the backend, with no digit grouping and '.' as the decimal separator,
 * i.e. 123456.78.
 */
export const normaliseLocalisedNumberString = (s: string) => {
  const formatter = new Intl.NumberFormat(navigator.language)
  const parts = formatter.formatToParts(1234.56)

  const groupSeparator = parts.find((p) => p.type === 'group')?.value || ''

  /**
   * Some languages use whitespace (not necessarily a space character) as a
   * group separator, but these whitespace characters aren't valid when passed
   * into a regex here, so replace with \s.
   */
  const groupRegex = groupSeparator.trim() === '' ? /\s/g : new RegExp(`\\${groupSeparator}`, 'g')

  const decimalSeparator = parts.find((p) => p.type === 'decimal')?.value || '.'
  let numberString = s.replace(groupRegex, '').replace(decimalSeparator, '.')

  const parenthesisRegex = /\((.*)\)/
  const [, number] = parenthesisRegex.exec(numberString) || []
  if (number) {
    numberString = `-${number}`
  }

  /**
   * We return the number as a string because:
   * - We don't want to lose precision by converting it to a number
   * - We don't need to do any maths operations on it
   */
  return numberString
}
