import Content from './Content.vue'
import Divider from './Divider.vue'
import GroupTitle from './GroupTitle.vue'
import Item from './Item.vue'
import Root from './Root.vue'
import Search from './Search.vue'

const Menu = {
  Root,
  Content,
  Item,
  Divider,
  GroupTitle,
  Search,
}

export default Menu
