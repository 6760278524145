<script setup lang="ts" generic="T">
import { computed, ref, watch } from 'vue'
import Menu from '.'
/**
 * Renders a search input that should appear at the top of a menu. This
 * component should never be used outside of `<Menu.Root>`.
 */

const props = withDefaults(
  defineProps<{
    /** Fallback text to show when there are no results */
    noResultText?: string
    /** List of all items to filter */
    items: T[]
    /** Either a key of the item to filter by or a custom predicate function */
    keyOrPredicate: keyof T | ((item: T, query: string) => unknown)
  }>(),
  { noResultText: 'No results found' },
)

const emit = defineEmits<{
  (e: 'change', value: T[]): void
}>()

const search = ref('')

const filteredItems = computed(() =>
  props.items.filter((item) => {
    if (typeof props.keyOrPredicate === 'function') {
      return true
    }

    return String(item[props.keyOrPredicate])
      .toLowerCase()
      .includes(search.value.trim().toLowerCase())
  }),
)
watch(
  filteredItems,
  () => {
    emit('change', filteredItems.value)
  },
  {
    immediate: true,
  },
)

const onKeydown = (e: KeyboardEvent) => {
  // Ordinarily, when focus is in a menu descendant then pressing the space
  // key will
  // select the active item. We don't want this to happen when the focus
  // is in the search input, so stop the event from propagating.
  if (e.code === 'Space') {
    e.stopPropagation()
  }
}
</script>

<template>
  <input
    v-model="search"
    placeholder="Search"
    class="max-h-7 min-h-7 w-full bg-background-transparent px-2.5 py-1 text-sm-12px-default text-text transition-colors placeholder:text-text-subtlest aria-readonly:cursor-pointer focus:outline-none disabled:cursor-not-allowed disabled:text-text-disabled disabled:placeholder:text-text-disabled"
    v-bind="$attrs"
    @keydown="onKeydown"
  />
  <Menu.Divider />
  <div
    v-if="filteredItems.length === 0"
    class="w-full p-2 text-text-subtlest"
  >
    {{ noResultText }}
  </div>
</template>
