<script setup lang="ts">
import ModalDialog from '@/uiKit/ModalDialog.vue'
import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

import { createView } from '@/backend/createView'
import AskGo from '@/modules/Project/AskGo.vue'
import IntroStagesTutotrial from '@/modules/Project/IntroStagesTutotrial.vue'
import { serializePropertyLayout, useProject } from '@/modules/Project/useProject'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import InlineTextField from '@/uiKit/InlineTextField.vue'
import { useStorage } from '@vueuse/core'
import { FeatureFlag } from '../App/featureFlags'
import { useFeatureFlags } from '../App/useFeatureFlags'
import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'
import ProjectTableViewsTabstripView from './ProjectTableViewsTabstripView.vue'
import { useAskGo } from './useAskGo'
import { useFilters } from './useFilters'
import { useResolveProjectRoute } from './useResolveProjectRoute'

const viewIdBeingRenamed = ref<null | string>(null)
const localViewName = ref('')

const showIntroModal = ref(false)
const hasSeenStagesTutorial = useStorage<boolean>('has-seen-stages-tutorial', false)

const tempValue = ref('')
const projectStore = useProject()
const router = useRouter()
const { captureAnalyticsEvent } = useAnalytics()
const views = computed(() => projectStore.views)
const resolveProjectRoute = useResolveProjectRoute()

const { parentEntityId, parentProjectId, projectId, workspaceId, parentViewId } = useRouteParams()

const addNewView = async () => {
  if (!tempValue.value.trim()) {
    return
  }
  const result = await createView({
    workspaceId: workspaceId.value,
    projectId: projectId.value,
    name: tempValue.value.trim(),
    propertyIds: projectStore.properties[0]?.id ? [projectStore.properties[0].id] : [],
  })
  tempValue.value = ''

  if (result.ok) {
    projectStore.upsertView({
      id: result.data.id,
      name: result.data.name,
      propertyIds: result.data.property_ids,
      propertyLayouts: result.data.property_layouts.map(serializePropertyLayout),
      filters: [],
      propertyOptions: result.data.property_options,
      assignablePropertyId: result.data.assignable_property_id,
      numPinnedProperties: result.data.num_pinned_properties,
    })
    tempValue.value = ''

    captureAnalyticsEvent(ANALYTICS_EVENT.STAGE_CREATED)

    const route = resolveProjectRoute({
      workspaceId: workspaceId.value,
      projectId: projectId.value,
      parentProjectId: parentProjectId.value,
      parentEntityId: parentEntityId.value,
      viewId: result.data.id,
      parentViewId: parentViewId.value,
    })
    router.push(route)
  }
}

const closeIntroModal = () => {
  showIntroModal.value = false
  hasSeenStagesTutorial.value = true
}

watch(
  () => viewIdBeingRenamed.value,
  (newValue) => {
    if (newValue === null) return
    const view = views.value.find((v) => v.id === newValue)
    if (view) {
      localViewName.value = view.name
    }
    router.push(
      resolveProjectRoute({
        workspaceId: workspaceId.value,
        projectId: projectId.value,
        viewId: newValue,
      }),
    )
  },
)

const isAskGoEnabled = useFeatureFlags(FeatureFlag.ASK_GO_PROJECT_CREATION)

const permissionsStore = usePermissionsStore()

const askGoStore = useAskGo()
const filterStore = useFilters()
</script>

<template>
  <div
    class="flex items-center justify-between border-t border-border-subtle bg-surface-secondary-persist pr-2.5"
  >
    <!-- eslint-disable tailwindcss/no-custom-classname -->
    <div class="no-scrollbar flex items-center gap-2.5 overflow-x-scroll px-2.5 py-2">
      <DarwinButton
        variant="transparent"
        size="sm"
        :active="filterStore.viewFilters"
        @click="filterStore.viewFilters = !filterStore.viewFilters"
      >
        <template #leading-icon>
          <IconSprite icon="filter-sort" />
        </template>
        Sort and Filter
      </DarwinButton>
      <div class="flex items-center gap-0.5">
        <ProjectTableViewsTabstripView
          v-for="view in views"
          :key="view.id"
          :view="view"
        />
        <InlineTextField
          v-if="permissionsStore.currentProjectPermissions.manage_views"
          class="min-w-56 grow"
          icon="plus-fill"
          :value="tempValue"
          size="sm"
          placeholder="New view"
          @focus="!hasSeenStagesTutorial && (showIntroModal = true)"
          @input="tempValue = $event"
          @change="addNewView"
        />
      </div>
    </div>
    <AskGo
      v-if="isAskGoEnabled && permissionsStore.hasAskGoPermission && askGoStore.isAskGoAvailable"
    />

    <ModalDialog
      :open="showIntroModal"
      aria-label="Create a new view tutorial"
      @close="closeIntroModal"
    >
      <IntroStagesTutotrial @close="closeIntroModal" />
    </ModalDialog>
  </div>
</template>
