<script setup lang="ts">
import type {
  MultiSelectProperty,
  SingleSelectProperty,
  UserSelectProperty,
} from '@/modules/Project/Properties/types'
import { toggleArray } from '@/shared/utils'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import CheckBox from '@/uiKit/CheckBox.vue'
import IconButton from '@/uiKit/IconButton.vue'
import Menu from '@/uiKit/Menu'
import RadioButton from '@/uiKit/RadioButton.vue'
import { computed, ref, type UnwrapRef } from 'vue'
import { useWorkspaceMembers } from '../WorkspaceSettings/useWorkspaceMembers'

const props = defineProps<{
  value: string | string[] | null
  property: SingleSelectProperty | MultiSelectProperty | UserSelectProperty
  readonly: boolean
  forceUserValue?: boolean
  isEntityView?: boolean
}>()

const emit = defineEmits<{
  (e: 'submit', val: string[]): void
}>()

const valueToColorMap = computed(() =>
  Object.fromEntries(props.property.config.options.map((o) => [o.value, o.color])),
)

const normalizedValue = computed(() => (props.value ? [props.value].flat() : []))

const workspaceMembersStore = useWorkspaceMembers()

const userValue = (option: { color?: string | null | undefined; value: string }) => {
  const member = workspaceMembersStore.workspaceMembers.find((member) => member.id === option.value)
  return member ? `${member.firstName} ${member.lastName}` : option.value
}

const allOptions = computed(() =>
  props.property.config.options.map((option) => ({
    label: isUser.value || props.forceUserValue ? userValue(option) : option.value,
    id: option.value,
  })),
)

const toggleValueAndSubmit = (val: string) => {
  if (props.readonly) return

  if (props.property.type === 'single_select') {
    const newValue = [val]
    emit('submit', newValue)
  } else if (props.property.type === 'multi_select') {
    if (props.forceUserValue) {
      const option = allOptions.value.find((o) => o.label === val)
      if (!option) {
        const newValue = toggleArray(normalizedValue.value, val)
        emit('submit', newValue)
        return
      }

      const newValue = toggleArray(normalizedValue.value, option.id)
      emit('submit', newValue)
      return
    }
    const newValue = toggleArray(normalizedValue.value, val)
    emit('submit', newValue)
  } else if (isUser.value) {
    const option = allOptions.value.find((o) => o.label === val)
    if (!option) {
      const newValue = [val]
      emit('submit', newValue)
      return
    }
    const newValue = [option.id]
    emit('submit', newValue)
  }
}

const isUser = computed(() => props.property.type === 'user_select')

const isSelected = (item: { label: string; id: string }) =>
  props.forceUserValue
    ? normalizedValue.value?.includes(item.id)
    : normalizedValue.value?.includes(item.label)

const isUserSelected = (item: { label: string; id: string }) =>
  normalizedValue.value?.includes(item.id)

const filteredOptions = ref<UnwrapRef<typeof allOptions>>([])

const selectedOptions = computed(() =>
  filteredOptions.value.filter((option) => isSelected(option) || isUserSelected(option)),
)

const unselectedOptions = computed(() =>
  filteredOptions.value.filter((option) => !isSelected(option) && !isUserSelected(option)),
)
</script>

<template>
  <Menu.Content>
    <Menu.Search
      :items="allOptions"
      key-or-predicate="label"
      @change="filteredOptions = $event"
    />
    <template
      v-for="(item, index) in [...selectedOptions, ...unselectedOptions]"
      :key="item.id"
    >
      <Menu.Divider v-if="index === selectedOptions.length && index !== 0" />
      <Menu.Item
        :label="item.label"
        :class="readonly ? 'cursor-not-allowed' : 'cursor-pointer'"
        @select="toggleValueAndSubmit(item.label)"
      >
        <template #prefix="{ active }">
          <RadioButton
            v-if="props.property.type === 'single_select'"
            :checked="isSelected(item)"
            :class="readonly || isSelected(item) || active ? 'opacity-100' : 'opacity-0'"
            class="pointer-events-none transition"
            :disabled="readonly && !isSelected(item)"
          />
          <CheckBox
            v-if="props.property.type === 'multi_select'"
            :checked="forceUserValue ? isUserSelected(item) : isSelected(item)"
            :class="
              readonly || (forceUserValue ? isUserSelected(item) : isSelected(item)) || active
                ? 'opacity-100'
                : 'opacity-0'
            "
            class="pointer-events-none transition"
            :disabled="readonly && !(forceUserValue ? isUserSelected(item) : isSelected(item))"
          />
          <RadioButton
            v-if="isUser"
            :checked="isUserSelected(item)"
            :class="readonly || isUserSelected(item) || active ? 'opacity-100' : 'opacity-0'"
            class="pointer-events-none transition"
            :disabled="readonly && !isUserSelected(item)"
          />
        </template>
        <BadgeItem
          :leading-icon="isUser || props.forceUserValue ? 'user-fill' : undefined"
          :label="item.label"
          size="sm"
          :variant="isUser || props.forceUserValue ? 'blue' : 'warning'"
          class="whitespace-nowrap"
          :rainbow-color="
            isUser || props.forceUserValue ? valueToColorMap[item.id] : valueToColorMap[item.label]
          "
        />
        <template #suffix>
          <IconButton
            v-if="
              !readonly &&
              ((props.property.type === 'single_select' && isSelected(item)) ||
                (props.property.type === 'user_select' && isUserSelected(item)))
            "
            icon="close"
            size="sm"
            aria-label="Clear values"
            variant="transparent"
            tabindex="-1"
            @click.prevent.stop="emit('submit', [])"
          />
        </template>
      </Menu.Item>
    </template>
  </Menu.Content>
</template>
