<script setup lang="ts">
import { ref } from 'vue'

import { deleteWorkspaceIcon } from '@/backend/deleteWorkspaceIcon'
import { uploadWorkspaceIcon } from '@/backend/uploadWorkspaceIcon'
import { useWorkspaces } from '@/modules/Workspaces/useWorkspaces'
import { copyToClipboard } from '@/shared/clipboard'
import IconButton from '@/uiKit/IconButton.vue'
import Menu from '@/uiKit/Menu'
import ToolTip from '@/uiKit/ToolTip.vue'
import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'

const props = defineProps<{
  workspaceId: string
}>()

const permissionsStore = usePermissionsStore()

const handleRemoveWorkspaceIcon = async () => {
  setIconUploading(true)

  await deleteWorkspaceIcon(props.workspaceId)

  setIconUploading(false)
}

const fileInput = ref<HTMLInputElement | null>(null)

const handleFileUpload = async (event: Event) => {
  const files = (event.target as HTMLInputElement).files
  if (files && files.length > 0) {
    const file = files[0]

    const formData = new FormData()
    formData.append('file', file)

    setIconUploading(true)

    await uploadWorkspaceIcon({
      workspaceId: props.workspaceId,
      formData,
    })

    setIconUploading(false)
  }
}

const workspaceStore = useWorkspaces()

const setIconUploading = (value: boolean) => {
  workspaceStore.setIconDownloadError(props.workspaceId, false)
  workspaceStore.setIconUploading(props.workspaceId, value)
}
</script>

<template>
  <Menu.Root v-slot="{ getTriggerProps }">
    <IconButton
      icon="more-dots"
      size="sm"
      variant="transparent"
      rounded
      data-test="project-menu-trigger"
      v-bind="{ ...getTriggerProps(), ...$attrs }"
      @click="(e) => e.preventDefault()"
      >Click me</IconButton
    >
    <Menu.Content class="min-w-56">
      <ToolTip
        class="w-full"
        :arrow="true"
        :placement="{ allowedPlacements: ['left'] }"
        :title="workspaceId"
      >
        <Menu.Item
          element="button"
          icon="copy"
          @select="copyToClipboard('Workspace ID', workspaceId)"
        >
          Copy workspace ID
        </Menu.Item>
      </ToolTip>

      <Menu.Item
        v-if="permissionsStore.hasWorkspaceUpdatePermission"
        element="button"
        icon="picture"
        @select="fileInput?.click()"
      >
        Upload logo<input
          ref="fileInput"
          class="hidden"
          type="file"
          @change="handleFileUpload"
        />
      </Menu.Item>
      <template v-if="permissionsStore.hasWorkspaceUpdatePermission">
        <Menu.Divider />
        <Menu.Item
          element="button"
          icon="trash"
          critical
          @select="handleRemoveWorkspaceIcon"
          >Remove logo</Menu.Item
        >
      </template>
    </Menu.Content>
  </Menu.Root>
</template>
