import { startConnection } from '@/backend/startConnection'
import { GooglePickerClosedError } from '@/modules/Workspaces/KnowledgeHub/Integrations/GoogleDrive/errors'
import type { GoogleDrivePickedData } from '@/modules/Workspaces/KnowledgeHub/Integrations/GoogleDrive/types'
import { useCurrentWorkspace } from '@/modules/Workspaces/useCurrentWorkspace'
import { invariant } from '@/shared/utils/typeAssertions'

const API_KEY = import.meta.env.VITE_GOOGLE_API_KEY
const APP_ID = import.meta.env.VITE_GOOGLE_APP_ID

export function useGoogleDriveConnection() {
  const googleReady = loadGoogleApis()

  const pick = async (connectionId: string) => {
    await googleReady
    return pickFiles(connectionId)
  }

  return { pick }
}

/**
 * Starts a connection with Google Drive on Go backend.
 *
 * @throws If we don't get a token from the first roundtrip
 * @throws If we fail to pick files from Google Drive picker
 */
async function pickFiles(connectionId: string) {
  const currentWorkspace = useCurrentWorkspace()
  const startParams: Parameters<typeof startConnection>[0] = {
    connectionId: connectionId,
    integrationId: 'google_drive',
    integratorConfig: {},
    workspaceId: currentWorkspace.value.id,
  }

  // First part of the flow is to get a token for Google Drive picker.
  // We get it by trying to start connection on Go backend without providing any files or folders.
  // Expected API response is a failure, but with a token in the response.
  const tokenResponse = await startConnection(startParams)
  invariant(!tokenResponse.ok, 'Failed to start connection, please try again.')

  // Second part of the flow is to pick files from Google Drive picker.
  // We use the token to authenticate with Google Drive picker.
  const data = await createPicker(tokenResponse.error.code)
  const folders = data.docs.filter((d) => d.type === 'folder').map((d) => d.id)
  const files = data.docs.filter((d) => d.type !== 'folder').map((d) => d.id)

  // Third part of the flow is to start connection on Go backend with picked files and folders.
  // This time around we expect a success response.
  const startResponse = await startConnection({
    ...startParams,
    integratorConfig: { files, folders },
  })

  invariant(startResponse.ok, 'Failed to pick files from Google Drive')
}

async function loadGoogleApis() {
  const gapiScriptUrl = 'https://apis.google.com/js/api.js'
  const apiScript = document.querySelector(`script[src="${gapiScriptUrl}"]`)
  if (apiScript) {
    return
  }

  await loadExternalScript(gapiScriptUrl)
  gapi.load('client:picker', async () => {
    await gapi.client.load('https://www.googleapis.com/discovery/v1/apis/drive/v3/rest')
  })
  await loadExternalScript('https://accounts.google.com/gsi/client')
}

function createPicker(accessToken: string): Promise<GoogleDrivePickedData> {
  const view = new google.picker.DocsView().setIncludeFolders(true).setSelectFolderEnabled(true)

  return new Promise((resolve, reject) => {
    new google.picker.PickerBuilder()
      .enableFeature(google.picker.Feature.NAV_HIDDEN)
      .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
      .setDeveloperKey(API_KEY)
      .setAppId(APP_ID)
      .setOAuthToken(accessToken)
      .addView(view)
      .addView(new google.picker.DocsUploadView())
      .setCallback((data) => {
        switch (data.action) {
          case 'picked': {
            const docs = data.docs || []
            resolve({ docs })
            break
          }
          case 'cancel':
            reject(new GooglePickerClosedError())
            break
          case 'error':
            reject(new Error('Failed to pick files', { cause: 'googlePickerError' }))
            break
          case 'loaded':
            break
          default:
            reject(new Error('Unknown error', { cause: 'googlePickerUnknownError' }))
            break
        }
      })
      .build()
      .setVisible(true)
  })
}

function loadExternalScript(src: string) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = src
    script.setAttribute('async', 'true')
    script.setAttribute('defer', 'true')

    script.onload = () => resolve(script)
    script.onerror = (e) => reject(e)

    document.body.appendChild(script)
  })
}
