import { toast } from '@/shared/toast'
import { useSetFieldValue } from '@/shared/useSetFieldValue'
import { normaliseLocalisedNumberString } from '@/shared/utils/string'
import { useCloned } from '@vueuse/core'
import { computed, type Ref } from 'vue'
import type { Field } from './Fields/types'
import type { NumberPropertyFormat, Property } from './Properties/types'
import { useFieldValue } from './useFieldValue'

const DEFAULT_FORMAT: NumberPropertyFormat = {
  decimalPlaces: 'auto',
  thousandsSeparator: false,
  negativeFormat: 'minus',
  rightAlign: true,
}

/**
 * Contains logic and state that is common to any place we want to render a number field, e.g.
 * in the project table or entity view.
 */
export const useNumberField = (
  field: Ref<Field<'number'>>,
  config: Ref<Property<'number'>['config']>,
) => {
  const fieldValue = useFieldValue(field)

  /**
   * The format to apply to number field values, regardless of whether
   * we are using a custom or automatic format.
   */
  const format = computed(() => {
    if (!config.value.isCustomFormat) {
      return DEFAULT_FORMAT
    }

    return config.value.format
  })

  // These are the styles that show the number in parentheses when it's negative.
  const PARENTHESIS_STYLES: Array<NumberPropertyFormat['negativeFormat']> = [
    'colored_parentheses',
    'parentheses',
  ]

  /**
   * The value that is saved in the database, formatted according to the property
   * config and the user's locale.
   */
  const formattedSavedValue = computed(() => {
    if (fieldValue.value === null) return ''

    const formatter = Intl.NumberFormat(navigator.language, getNumberFormatterOptions(format.value))

    // Keep the number as a string to avoid losing precision.
    const formattedNumber = formatter.format(fieldValue.value as Intl.StringNumericLiteral)

    if (PARENTHESIS_STYLES.includes(format.value.negativeFormat) && Number(fieldValue.value) < 0) {
      return `(${formattedNumber})`
    }

    return formattedNumber
  })

  const { cloned: inputFieldValue, sync: syncLocalValue } = useCloned(formattedSavedValue)

  // The styles that show the number in red when it's negative.
  const RED_STYLES: Array<Property<'number'>['config']['format']['negativeFormat']> = [
    'colored',
    'colored_parentheses',
  ]
  const shouldBeRed = computed(
    () => RED_STYLES.includes(format.value.negativeFormat) && Number(fieldValue.value) < 0,
  )

  const setFieldValue = useSetFieldValue()
  const saveFieldValue = async ({
    projectId,
    workspaceId,
  }: {
    projectId: string
    workspaceId: string
  }) => {
    if (formattedSavedValue.value === inputFieldValue.value) return

    const res = await setFieldValue({
      entityId: field.value.entityId,
      projectId,
      workspaceId,
      fields: {
        [field.value.propertyId]: {
          field: field.value,
          newValue: normaliseLocalisedNumberString(inputFieldValue.value),
        },
      },
    })

    if (!res.ok) {
      toast.error(`Failed to update field: ${res.error.message}`)
    }
  }

  return {
    inputFieldValue,
    syncLocalValue,
    shouldBeRed,
    saveFieldValue,
    format,
  }
}

function getNumberFormatterOptions(format: NumberPropertyFormat): Intl.NumberFormatOptions {
  return {
    minimumFractionDigits: format.decimalPlaces === 'auto' ? undefined : format.decimalPlaces,
    maximumFractionDigits: format.decimalPlaces === 'auto' ? undefined : format.decimalPlaces,
    useGrouping: format.thousandsSeparator,
    signDisplay: format.negativeFormat === 'minus' ? 'auto' : 'never',
  }
}
