<script setup lang="ts">
import type { IconName } from '@/uiKit/IconName'
import IconSpriteVue from '@/uiKit/IconSprite.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import { computed } from 'vue'

export type LabelValueMenuItemProps = {
  /** Current selected value for this item */
  value?: string
  /** Visible label for this item */
  label: string
  /** Icon to render before the value */
  icon?: IconName
  /** HTML ID attribute to pass to the root element. Required for a11y. */
  id: string
  /** If there is a submenu, this object gives props required for a11y */
  submenu: {
    /** Whether the dropdown controlled by this item is open. Required for a11y.  */
    isOpen: boolean
    /** Required for a11y. The ID of the submenu element. */
    id: string
  } | null
  /** If there is a submenu, icon to render  */
  iconSubmenu?: IconName
}

/**
 * A menu item with a label and a value. Is only used in the PropertyEditMenu, and
 * according to design it will only ever be used in that context.
 *
 */
const props = defineProps<LabelValueMenuItemProps>()

defineEmits<{
  (e: 'select'): void
}>()

const labelId = computed(() => `${props.id}-label`)
</script>

<template>
  <ListMenuItem
    :id="id"
    class="go-scrollbar max-h-96 overflow-auto px-2"
    :role="submenu ? 'combobox' : 'listitem'"
    :aria-labelledby="labelId"
    :aria-expanded="submenu ? submenu.isOpen : undefined"
    :aria-controls="submenu ? submenu.id : undefined"
    :default-hover-disabled="submenu === null"
    :active="submenu?.isOpen"
    :class="submenu === null && 'cursor-default'"
    @select="$emit('select')"
  >
    <slot name="prefix"> </slot>
    <slot
      name="label"
      :label-id="labelId"
    >
      <div
        :id="labelId"
        class="grow translate-y-0.5 self-start text-text"
      >
        {{ label }}
      </div>
    </slot>
    <slot :label-id="labelId">
      <div class="flex grow items-center justify-end gap-2">
        <IconSpriteVue
          v-if="icon"
          data-test="menu-item-icon"
          class="fill-icon-subtle text-icon-subtle"
          :icon="icon"
        />
        <div class="text-text">{{ value }}</div>
      </div>
    </slot>

    <IconSpriteVue
      v-if="submenu"
      class="translate-y-0.5 self-start text-icon-subtlest"
      :icon="iconSubmenu ?? 'chevron-right'"
    />
  </ListMenuItem>
</template>
