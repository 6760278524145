import type { EntityResponse, LibraryItemResponse } from '@/backend/types'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { captureException } from '@sentry/vue'
import type { LibraryDocument, LibraryFile, LibraryItem } from './libraryStore'

/** Gets the number of properties and projects in which a library item has been used. */
const getInputReference = (
  backendReference: LibraryItemResponse['library_attributes']['input_references'],
): LibraryItem['inputReference'] =>
  backendReference.map((r) => ({
    projectId: r.project_id,
    propertyId: r.property_id,
  }))

const serializeTextItem = (
  field: Exclude<EntityResponse['fields'][string], undefined>,
  libraryAttributes: LibraryItemResponse['library_attributes'],
): Omit<LibraryDocument, 'id'> => {
  if (field.property_type !== 'text') {
    throw new Error('Property is not a text type')
  }

  if (Array.isArray(field.manual_value)) {
    captureException(new Error('Unexpected array value for text field'))
  }

  return {
    content: String(field.manual_value.value ?? ''),
    name: libraryAttributes?.name || String(field.manual_value.value ?? '').slice(0, 10),
    updatedAt: field.updated_at,
    createdAt: field.created_at,
    updatedBy: field.manual_value?.updated_by ?? '',
    status: field.status,
    type: 'text',
    inputReference: getInputReference(libraryAttributes.input_references),
  }
}

const serializeFileItem = (
  field: Exclude<EntityResponse['fields'][string], undefined>,
  libraryAttributes: LibraryItemResponse['library_attributes'],
): Omit<LibraryFile, 'id'> => {
  if (!('original_filename' in field.manual_value)) {
    throw new Error('Property is not a file type')
  }

  const filename = field.manual_value.original_filename
  if (!filename) {
    throw new Error('Filename is empty')
  }

  return {
    filename: filename,
    name: libraryAttributes?.name || filename,
    updatedAt: field.updated_at,
    createdAt: field.created_at,
    updatedBy: field.manual_value?.updated_by ?? '',
    fileUrl: field.manual_value.value,
    status: field.status,
    type: 'file',
    inputReference: getInputReference(libraryAttributes.input_references),
  }
}

/**
 * Serializes an entity into a library item. This function will receive
 * a LibraryItemResponse in response to an HTTP request, and an EntityResponse
 * in response to a websocket update.
 */
export const serializeLibraryItem = (
  item: LibraryItemResponse,
  propertyId: string,
): LibraryItem => {
  const field = item.fields[propertyId]
  assertIsNotNullOrUndefined(field, 'Field is not defined')
  if (field.property_type === 'file') {
    return {
      id: item.id,
      ...serializeFileItem(field, item.library_attributes),
    }
  }

  if (field.property_type === 'text') {
    return {
      id: item.id,
      ...serializeTextItem(field, item.library_attributes),
    }
  }

  throw new Error('Property type not supported')
}
