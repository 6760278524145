<script setup lang="ts">
import { ref } from 'vue'
import { TYPE_ICON } from '../icons'
import FilterItemContainer from './FilterItemContainer.vue'
import FilterBarItemMatcher, { type ID } from './FilterItemMatcher.vue'
import FilterItemTextValue from './FilterItemTextValue.vue'
import { assertIsValidTextMatcher, type FilterableProperty, type TextLikeFilter } from './types'
import { getFilterDisplayValue } from './utils'

const props = defineProps<{
  filter: TextLikeFilter
  property: FilterableProperty
}>()

const emit = defineEmits<{
  (e: 'delete'): void
  (e: 'update', filter: TextLikeFilter): void
}>()

const onNewMatcher = (matchId: ID) => {
  assertIsValidTextMatcher(matchId)

  const newFilter: TextLikeFilter = {
    ...props.filter,
    matcher: {
      ...props.filter.matcher,
      name: matchId,
    },
  }
  emit('update', newFilter)
}

const isTextModalOpen = ref(false)
</script>

<template>
  <FilterItemContainer
    :icon="TYPE_ICON[property.type]"
    :applied-to="property.name"
    @delete="$emit('delete')"
  >
    <FilterBarItemMatcher
      :matcher-name="filter.matcher.name"
      :values="filter.matcher.values"
      :type="property.type"
      @match="onNewMatcher"
    />
    <button
      class="flex cursor-pointer items-center gap-0.5 px-1.5 py-1 text-sm-12px-default text-text-subtle hover:bg-background-transparent-hovered active:bg-background-transparent-pressed"
      :class="{ 'bg-background-transparent-hovered': isTextModalOpen }"
      @click="isTextModalOpen = true"
    >
      {{ getFilterDisplayValue(filter) }}
    </button>
    <FilterItemTextValue
      :filter="filter"
      :is-open="isTextModalOpen"
      :property-name="property.name"
      @close="isTextModalOpen = false"
      @update="$emit('update', $event)"
    />
  </FilterItemContainer>
</template>
