import type { ExtensionOrDocument } from '@/modules/Workspaces/KnowledgeHub/Files/library-item'
import { invariant } from '@/shared/utils/typeAssertions'
import { inject, provide, type InjectionKey, type Ref } from 'vue'

export type FileTableContext = {
  isSelected: (id: string) => boolean
  setSelected: (id: string, state: boolean) => void
  filterOwners: Ref<string[]>
  filterFileType: Ref<ExtensionOrDocument[]>
}

const fileTableContextToken = Symbol('selection') as InjectionKey<FileTableContext>

export function useFileTableContext() {
  const injection = inject(fileTableContextToken)
  invariant(injection, 'Selection context not provided')
  return injection
}

export function provideFileTableContext(value: FileTableContext) {
  provide(fileTableContextToken, value)
}
